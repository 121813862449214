import React from "react";
import PropTypes from "prop-types";

import {Dialog} from 'primereact/dialog';
import {Button} from 'primereact/button';

class AufnahmeShortcutDialog extends React.Component {

    render() {
        const footer = (
            <div>
                <Button label="Schliessen" icon="pi pi-times" onClick={() => this.props.callBackOnClose()} className="p-button-secondary" />
            </div>
        );

        let werte = [];
        werte.push({shortcut: "F1", beschreibung:"0"});
        werte.push({shortcut: "F2", beschreibung:"26"});
        werte.push({shortcut: "F3", beschreibung:"41"});
        werte.push({shortcut: "F4", beschreibung:"45"});
        werte.push({shortcut: "F5", beschreibung:"60"});
        werte.push({shortcut: "F6", beschreibung:"81"});
        werte.push({shortcut: "F7", beschreibung:"85"});
        werte.push({shortcut: "F8", beschreibung:"100"});
        werte.push({shortcut: "F9", beschreibung:"Der Wert der eingegeben wurde ist der Rest welcher noch zu werfen ist."});
        werte.push({shortcut: "F10", beschreibung:"finish mit dem ersten Dart"});
        werte.push({shortcut: "F11", beschreibung:"finish mit dem zweiten Dart"});
        werte.push({shortcut: "F12", beschreibung:"finish mit dem dritten Dart"});
        let rows = [];
        for (const werteElement of werte) {
            rows.push(<div className="grid"><div className="col-fixed" style={{width:50}}>{werteElement.shortcut}</div><div className="col">{werteElement.beschreibung}</div></div>);
        }

        return <Dialog baseZIndex={2000} header="Shortcuts bei der Aufnahme" visible={this.props.visible}
                       style={{width: 300}} footer={footer} onHide={() => this.props.callBackOnClose()}>
            <div className="grid" style={{marginBottom:10}}><div className="col">Beim Betätigen der Shortcuts wird der beschriebene Wert automatisch als geworfener Wert abgeschickt.</div></div>
            {rows}
        </Dialog>
    }
}


AufnahmeShortcutDialog.propTypes = {
    visible: PropTypes.bool.isRequired,
    callBackOnClose: PropTypes.func.isRequired,
};

export default AufnahmeShortcutDialog;
