import React, {useEffect, useState} from "react";
import {Dropdown} from "primereact/dropdown";
import FetchUtil from "../../util/FetchUtil";
import ConfigUtil from "../../util/ConfigUtil";
import DHSelectionComponent from "./DHSeclectionCompontent";

export function SelectTeamComponent({teamid, onChange, onError}) {

    const [teams, setTeams] = useState([]);
    const [teamsLoaded, setTeamsLoaded] = useState(false);

    useEffect(() => {
        if( !teamsLoaded ) {
            loadTeams();
            setTeamsLoaded(true);
        }
    });

    const loadTeams = () => {
        FetchUtil.fetchGet(ConfigUtil.getConfig().resourceUrls.settings + "/team",
            json => {
                setTeams(json);
            },
            responseNotOk => {
                onError("Fehler beim Laden der Mannschaften: " + responseNotOk.message);
            },
            error => {
                propsonError("Fehler beim Laden der Mannschaften: " + error.message);
            })
    }

    const getTeams = () => {
        let returnValue = [];
        returnValue.push({name: 'Auswahl löschen', code: undefined});
        for (const team of teams) {
            returnValue.push({name: team.bezeichnung, code: team.id });
        }
        return returnValue;
    }

    return <DHSelectionComponent value={teamid} onChange={onChange} values={getTeams()} title="first to x legs"/>
    return (<Dropdown value={teamid} options={teams} optionLabel="bezeichnung"
                      onChange={(e) => {
                          onChange(e.value.id)
                      }}
                      placeholder="Eingabe..." style={{width:300}}/>

    );
}