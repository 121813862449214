import React, {useEffect, useState} from 'react'
import {Dialog} from "primereact/dialog";
import {Button} from "primereact/button";
import {Fieldset} from "primereact/fieldset";
import ErrorInfoComponent from "../../general/ErrorInfoComponent";
import {InputNumber} from "primereact/inputnumber";
import {Dropdown} from "primereact/dropdown";
import {Panel} from "primereact/panel";
import {InputText} from "primereact/inputtext";
import FetchUtil from "../../../util/FetchUtil";
import ConfigUtil from "../../../util/ConfigUtil";

export default function TurnierSetzlisteGruppeDialog({tournament, onRefresh, onClose}) {

    const [infoMessage, setInfoMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);

    let [setzliste, setSetzliste] = useState([]);

    let [filter, setFilter] = useState("");

    let [selectedGroup, setSelectedGroup] = useState(undefined);

    let [anzahlGruppen, setAnzahlGruppen] = useState(1);

    useEffect(() => {
        let tmpSetzliste = [];
        if( tournament.tournamentSetzlisteEntities !== undefined && tournament.tournamentSetzlisteEntities !== null ) {
            for (const setzlisteeintrag of tournament.tournamentSetzlisteEntities) {
                tmpSetzliste.push({gruppe: setzlisteeintrag.gruppe, playerId: setzlisteeintrag.playerid});
            }
        }
        setSetzliste(tmpSetzliste);
    }, []);

    const getGroupAuswahl = () => {
        let groups = [];
        if( anzahlGruppen === null || anzahlGruppen === undefined || anzahlGruppen === "") {
            return groups;
        }
        for( let i = 1 ; i <= anzahlGruppen; i++ ) {
            groups.push({gruppe:i});
        }
        return groups;
    }

    const getPlayers = () => {
        if( filter === "") {
            return tournament.tournamentPlayerEntities;
        }
        let players = [];
        for (const player of tournament.tournamentPlayerEntities) {
            if( player.name.includes(filter)) {
                players.push(player);
            }
        }
        return players;
    }

    const getPlayerRow = (player) => {
        let breitsGesetzt = setzliste.filter(tmpGroup => tmpGroup.playerId === player.id).length > 0;
        return <div key={"key_player_" + player.id} style={{marginBottom:10}}>
            <Button icon="pi pi-plus"
                                 disabled={breitsGesetzt}
                                 onClick={() => hinzufuegen(player.id)}
                                 style={{height: 30, width: 30, marginLeft: 10, marginRight: 10}}/>
            <span>{player.name}</span>
        </div>
    }

    const getSetzlisteRows = () => {
        let rows = [];
        rows.push(<div key={"key_setzliste_header"} style={{paddingBottom:10, display: "flex", margin: "auto", verticalAlign: "middle", alignItems: "center"}}>
            <div style={{width:70}}>
            </div>
            <div style={{width:80, textAlign:"center"}}>
                Gruppe
            </div>
            <div>Spieler</div>
        </div>);

        for (const tmp of setzliste) {
            let players = tournament.tournamentPlayerEntities.filter(player => player.id === tmp.playerId);
            if( players.length === 0 || players.length > 1) {
                rows.push(<div style={{marginBottom:10}}>
                    Fehler, Spieler nicht gefunden! Speichere erneut, dann wird dieser Eintrag entfernt.
                </div>);
            } else {
                let player = players[0];
                rows.push(<div key={"key_player_setzliste_" + player.id} style={{paddingBottom:10, display: "flex", margin: "auto", verticalAlign: "middle", alignItems: "center"}}>
                    <div style={{width:70}}>
                        <Button icon="pi pi-minus"
                                onClick={() => entfernen(player.id)}
                                style={{height: 30, width: 30, marginLeft: 10, marginRight: 10}}/>
                    </div>
                    <div style={{width:80, textAlign:"center"}}>
                        {tmp.gruppe}
                    </div>
                    <div>{player.name}</div>
                </div>);
            }
        }
        return rows;
    }

    const entfernen = (playerId) => {
        let neueEinteilung = setzliste.filter(tmpGroup => tmpGroup.playerId !== playerId);
        setSetzliste(neueEinteilung);
    }

    const hinzufuegen = (playerId) => {
        setErrorMessage(undefined);
        if( selectedGroup === undefined) {
            setErrorMessage("Gruppe muss gewählt sein.");
            return;
        }
        let neueEinteilung = setzliste.slice();
        neueEinteilung.push({gruppe: selectedGroup.gruppe, playerId: playerId});
        setSetzliste(neueEinteilung);
    }

    const save = () => {
        let tmpSetzliste = [];
        for (const tmp of setzliste) {
            tmpSetzliste.push({gruppe: tmp.gruppe, playerid: tmp.playerId});
        }
        let data = {tournamentId: tournament.id, tournametSetzlisteEinzelnDtoList: tmpSetzliste};
        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.tournament + "/savesetzliste",
            data,
            json => {
                setInfoMessage("Speichern war erfolgreich!")
                onRefresh();
            },
            responseNotOk => {
                setErrorMessage("Fehler beim Speichern: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler beim Speichern: " + error.message);
            }
        );
    }

    const getDialogFooter = () => {
        return <div>
            <Button label="Speichern" icon="pi pi-save" onClick={() => save()}/>
            <Button label="Schliessen" icon="pi pi-times" onClick={() => onClose()}
                    className="p-button-secondary"/>
        </div>
    };

    return <div>
        <Dialog baseZIndex={2000} header="Setzliste bearbeiten" visible={true}
                style={{width: 1000, textAlign: "left"}} footer={getDialogFooter()}
                onHide={() => onRefresh()}>
            <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage} onClearInfoMessage={() => setInfoMessage(undefined)} onClearErrorMessage={() => setErrorMessage(undefined)} />
            <Fieldset legend="Hinweise..." style={{marginBottom: 20}} toggleable={true}>
                <div style={{color:"red", marginBottom:10}}>- Sobald die Gruppeneinteilung von Hand ausgewählt hast, macht die Setzliste keinen Sinn.</div>
                <div style={{color:"red", marginBottom:10}}>- Bitte achte selbst darauf, dass du nicht zuviele Spieler in einer Gruppe setzt und damit evtl. die Gruppengrößen überschreitest.</div>
                <div>- Bei Gruppenturnieren werden die gewünschten Teilnehmer direkt einer Gruppe zugewiesen.</div>
                <div>- Bitte wähle die Anzahl der Gruppen die du maximal im Turnier hast.</div>
                <div>- Danach wählst du die Gruppe in der der Spieler soll und fügst diese mit + der Gruppe hinzu.</div>
            </Fieldset>
            <div style={{marginBottom: 20}}>
                <span style={{marginRight: 20}}>Anzahl Gruppen:</span>
                <InputNumber min={1} style={{width: "80px", marginRight: "5px"}} value={anzahlGruppen}
                             onChange={(e) => setAnzahlGruppen(e.value)}/>
            </div>
            <div style={{marginBottom: 20}}>
                <span style={{marginRight: 20}}>Gruppe:</span>
                <Dropdown value={selectedGroup} options={getGroupAuswahl()} optionLabel="gruppe"
                          onChange={(e) => {
                              setSelectedGroup(e.value);
                          }}
                          placeholder="Eingabe..." style={{width: 150}}/>
            </div>
            <div className="grid">
                <div className="col">
                    <Panel header="Spielerübersicht">
                        <div style={{marginLeft:10, marginBottom:10}}>
                            <InputText name="filter" style={{width: "100%"}}
                                       value={filter} onChange={(e) => setFilter(e.target.value)}/>
                        </div>
                        <hr style={{marginBottom:10}}/>
                        {getPlayers().map(player => getPlayerRow(player))}
                    </Panel>
                </div>
                <div className="col">
                    <Panel header="Gruppeneinteilung">
                        {getSetzlisteRows()}
                    </Panel>
                </div>
            </div>
        </Dialog>
    </div>
}

