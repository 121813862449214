import React, {useEffect, useState} from 'react'
import {NAVIGATION_ITEM} from "../constants/navigationItems";
import {NavLink} from "react-router-dom";
import {getFromLocalStorageWithDefault, KEY_LAST_EVENTS, saveToLocalStorage} from "../util/localStorageZugriff";
import {PrimeIcons} from "primereact/api";

export default function LastEventComp({currentId, onLoad}) {

    const [showDetails, setShowDetails] = useState(false);
    const [lastEvents, setLastEvents] = useState([]);

    useEffect(() => {
        loadFromLocalStorage();
    }, [currentId]);

    const loadFromLocalStorage = () => {
        setLastEvents(getFromLocalStorageWithDefault(KEY_LAST_EVENTS, []));
    }

    const getDetailsArea  = () => {
        if( !showDetails || lastEvents.length === 0) {
            return "";
        }
        let rows = [];

        for (const lastEvent of lastEvents) {
            if( onLoad === undefined) {
                rows.push(<div key={"key_last_event" + lastEvent.id + "_type_" + lastEvent.type } style={{marginTop: 10, cursor: "pointer"}}>
                    <NavLink to={NAVIGATION_ITEM.PUBLIC_TOURNAMENT.route + "/" + lastEvent.id} style={{textDecoration: "none"}}>
                        <span style={{color: "white"}}>{lastEvent.name}</span>
                    </NavLink>
                </div>);
            } else {
                rows.push(<div key={"key_last_event" + lastEvent.id + "_type_" + lastEvent.type } style={{marginTop: 10, cursor: "pointer"}} onClick={() => onLoad(lastEvent.id)}>
                    <span style={{color: "white"}}>{lastEvent.name}</span>
                </div>);
            }
        }
        return <div style={{marginTop: 20}}>
            {rows}
            <div style={{marginTop: 30, marginBottom: 30, cursor: "pointer"}} onClick={() => deleteHistory()}>
                <hr/>
                <i className={PrimeIcons.TRASH} style={{marginRight: 5}}/>
                Verlauf löschen
            </div>
        </div>;
    }

    const deleteHistory = () => {
        saveToLocalStorage(KEY_LAST_EVENTS, []);
        loadFromLocalStorage();
    }

    const ermittleErstesEvent = () => {
        for (const lastEvent of lastEvents) {
            if( lastEvent.id !== currentId) {
                return lastEvent;
            }
        }
        return undefined;
    }

    return <div style={{paddingLeft: 10, paddingBottom:10, width: "100%", color:"white", minHeight:40}}>
        {ermittleErstesEvent() !== undefined && <div style={{cursor: "pointer"}}>
            {onLoad === undefined ?
                <NavLink to={NAVIGATION_ITEM.PUBLIC_TOURNAMENT.route + "/" + ermittleErstesEvent().id} style={{textDecoration: "none"}}>
                    <span style={{color: "white"}}>Zuletzt besucht: {ermittleErstesEvent().name}</span>
                </NavLink>
                :
                <span style={{color: "white"}} onClick={() => onLoad(ermittleErstesEvent().id)}>
                    Zuletzt besucht: {ermittleErstesEvent().name}
                </span>
            }
            <i className={showDetails ? PrimeIcons.ANGLE_UP : PrimeIcons.ANGLE_DOWN} style={{marginLeft: 10}} onClick={() => setShowDetails(!showDetails)}/>
        </div>
        }
        {getDetailsArea()}
    </div>
}
