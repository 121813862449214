import React, {useState} from 'react'
import {Dialog} from "primereact/dialog";
import PropTypes from "prop-types";
import {InputText} from "primereact/inputtext";
import {RadioButton} from 'primereact/radiobutton';
import {Button} from "primereact/button";
import FetchUtil from "../../../util/FetchUtil";
import ConfigUtil from "../../../util/ConfigUtil";
import ErrorInfoComponent from "../../general/ErrorInfoComponent";

function VerwarnungDialog({name, playerId, onClose, onSave}) {

    const [infoMessage, setInfoMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);

    const [farbe, setFarbe] = useState("gelb");
    const [text, setText] = useState("");

    const save = () => {
        setErrorMessage(undefined);
        let url = ConfigUtil.getConfig().resourceUrls.tournament + "/verwarnungerteilen/" + playerId + "/" + farbe;
        FetchUtil.fetchPost(url,
            {text: text},
            () => {
                setInfoMessage("Es wurde erfolgreich gespeichert.");
                onSave();
            },
            responseNotOk => {
                setErrorMessage("Fehler beim Speichern: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler beim Speichern: " + error.message);
            })
    }

    return <Dialog baseZIndex={2000} header="Verwarnung erteilen" visible={true}
                                       style={{height: 500, width: 400}}
                                       onHide={() => onClose()}>
        <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage} onClearInfoMessage={() => setInfoMessage(undefined)} onClearErrorMessage={() => setErrorMessage(undefined)} />
        <div style={{textAlign: "left"}}>
            <div style={{marginBottom: 20, fontWeight: "bold", color: "red"}}>ACHTUNG!!!<br/>Eine Verwarnung soll normal nicht zurück genommen werden!!!<br/>Sobald du den Text leer speicherst, würde
                eine eventuell vorhandene Verwarnung entfernt.
            </div>
            <div>Folgender Teilnehmer soll verwarnt werden:</div>
            <div style={{marginBottom: 20, fontWeight: "bold"}}>{name}</div>
            <div>Kurze und aussagefähigen Text erfassen!</div>
            <InputText value={text} onChange={(e) => setText(e.target.value)} style={{width: "100%"}} maxLength="200"/>
            ({text.length}/200)
            <div style={{marginTop: 20}}>
                <RadioButton inputId="cardtypeneutral" value="neutral" name="cardtype" onChange={(e) => setFarbe(e.value)} checked={farbe === 'neutral'}/>
                <label htmlFor="cardtypeneutral" style={{marginLeft: 5}}>Neutral, nur Kommentar.</label>
            </div>
            <div style={{marginTop: 10}}>
                <RadioButton inputId="cardtypegelb" value="gelb" name="cardtype" onChange={(e) => setFarbe(e.value)} checked={farbe === 'gelb'}/>
                <label htmlFor="cardtypegelb" style={{marginLeft: 5}}>Gelb</label>
            </div>
            <div style={{marginTop: 10}}>
                <RadioButton inputId="cardtyperot" value="rot" name="cardtype" onChange={(e) => setFarbe(e.value)} checked={farbe === 'rot'}/>
                <label htmlFor="cardtyperot" style={{marginLeft: 5}}>Rot</label>
            </div>
            <div style={{marginTop: 20}}>
                <Button label="Speichern"
                        type="button" onClick={() => save()} style={{marginRight: 5}}/>
                <Button label="Schliessen"
                        type="button" onClick={() => onClose()}/>

            </div>
        </div>
    </Dialog>
}

VerwarnungDialog.propTypes = {
    name: PropTypes.string.isRequired,
    playerId: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
}

export default VerwarnungDialog;
