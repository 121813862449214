import React from "react";
import PropTypes from "prop-types";
import {Button} from "primereact/button";
import {OverlayPanel} from "primereact/overlaypanel";

class ScoreWertComponent extends React.Component {

    constructor(props) {
        super(props);

        this.state = {currentValue: ""}

        this.addValue = this.addValue.bind(this);
        this.removeLastValue = this.removeLastValue.bind(this);
        this.uebernehmeValue = this.uebernehmeValue.bind(this);
        this.abbrechen = this.abbrechen.bind(this);
    }

    addValue(value) {
        this.setState({...this.state, currentValue: this.state.currentValue + value})
    }

    removeLastValue() {
        let currentValue = this.state.currentValue;
        currentValue = currentValue.substring(0, currentValue.length - 1);
        this.setState({...this.state, currentValue: currentValue})
    }

    uebernehmeValue() {
        this.props.callBackOk(this.state.currentValue);
        this.setState({...this.state, currentValue: ""});
        this.op2.hide();

    }

    removeValue(value) {
        this.props.removeValue(value);
    }

    abbrechen() {
        this.setState({currentValue: ""});
        this.op2.hide();
    }

    render() {
        let buttonStyle = {width: "80px", height: "80px"};
        let disableButtons = this.state.currentValue.length > 2;
        let disableBackButton = this.state.currentValue.length === 0;
        let currentIntValue = parseInt(this.state.currentValue, 10);
        let disableUebernehmenButton = this.state.currentValue === "" || currentIntValue < 100 || currentIntValue > 180;
        let buttons = [];
        for (const value of this.props.values) {
            buttons.push(<Button label={value} disabled={this.props.readonly} icon="pi pi-trash" iconPos="right" style={{marginRight:1}} onClick={() => this.removeValue(value)}/>)
        }
        return <div style={{float:"left", marginLeft:20}}>
                {this.props.beschreibung &&
                    <span style={{marginRight:10}}>{this.props.beschreibung}</span>
                }

                <Button icon="pi pi-plus" disabled={this.props.readonly} style={{marginRight: "10px"}} onClick={(e) => this.op2.show(e)}/>
                {buttons}
                <OverlayPanel ref={(el) => this.op2 = el}
                              showCloseIcon={false} dismissable={true}
                              style={{height: "600px", width: "280px"}}>
                    <div className="grid" style={{
                        textAlign: "center",
                        align: "center",
                        margin: "20px",
                        marginLeft: "70px",
                        fontWeight: "bold",
                        fontSize: "30px",
                        height: "50px"
                    }}>
                        {this.state.currentValue}
                    </div>
                    <div className="grid" style={{height: "80px", padding: "0px", margin: "0px"}}>
                        <Button label="1" style={buttonStyle} onClick={(e) => this.addValue(1)}
                                disabled={disableButtons}/>
                        <Button label="2" style={buttonStyle} onClick={(e) => this.addValue(2)}
                                disabled={disableButtons}/>
                        <Button label="3" style={buttonStyle} onClick={(e) => this.addValue(3)}
                                disabled={disableButtons}/>
                    </div>
                    <div className="grid" style={{height: "80px", padding: "0px", margin: "0px"}}>
                        <Button label="4" style={buttonStyle} onClick={(e) => this.addValue(4)}
                                disabled={disableButtons}/>
                        <Button label="5" style={buttonStyle} onClick={(e) => this.addValue(5)}
                                disabled={disableButtons}/>
                        <Button label="6" style={buttonStyle} onClick={(e) => this.addValue(6)}
                                disabled={disableButtons}/>
                    </div>
                    <div className="grid" style={{height: "80px", padding: "0px", margin: "0px"}}>
                        <Button label="7" style={buttonStyle} onClick={(e) => this.addValue(7)}
                                disabled={disableButtons}/>
                        <Button label="8" style={buttonStyle} onClick={(e) => this.addValue(8)}
                                disabled={disableButtons}/>
                        <Button label="9" style={buttonStyle} onClick={(e) => this.addValue(9)}
                                disabled={disableButtons}/>
                    </div>
                    <div className="grid" style={{height: "80px", padding: "0px", margin: "0px"}}>
                        <Button icon="pi pi-times" style={buttonStyle}
                                onClick={(e) => this.setState({...this.state, currentValue: ""})}/>
                        <Button label="0" style={buttonStyle} onClick={(e) => this.addValue(0)}
                                disabled={disableButtons}/>
                        <Button icon="pi pi-angle-left" style={buttonStyle} onClick={(e) => this.removeLastValue()}
                                disabled={disableBackButton}/>
                    </div>
                    <div className="grid" style={{padding: "0px", marginTop: "30px"}}>
                        <Button label="Übernehmen" className="p-button-success"
                                style={{marginLeft: "5px", height: "50px", width: "240px"}}
                                onClick={() => this.uebernehmeValue()} disabled={disableUebernehmenButton}/>
                    </div>
                    <div className="grid" style={{padding: "0px", marginTop: "30px"}}>
                        <Button label="Abbrechen / Schliessen" className="p-button-danger"
                                style={{marginLeft: "5px", height: "50px", width: "240px"}}
                                onClick={() => this.abbrechen()}/>
                    </div>
                </OverlayPanel>
        </div>
    }
}


ScoreWertComponent.propTypes = {
    beschreibung: PropTypes.string,
    values: PropTypes.array.isRequired,
    callBackOk: PropTypes.func.isRequired,
    removeValue: PropTypes.func.isRequired,
    readonly: PropTypes.bool.isRequired,
};

export default ScoreWertComponent;
