import React from 'react'
import PropTypes from "prop-types";
import {Toast} from "primereact/toast";
import {Messages} from "primereact/messages";
import {Card} from "primereact/card";
import {Button} from "primereact/button";
import {RadioButton} from "primereact/radiobutton";
import ConfigUtil from "../../../util/ConfigUtil";
import FetchUtil from "../../../util/FetchUtil";
import {NavLink} from "react-router-dom";
import {NAVIGATION_ITEM} from "../../../constants/navigationItems";
import PaypalComponent from "../../paypal/PaypalComponent";

class Bezahlen extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            proTimeReceived: false,
        };
        this.ladeDaten = this.ladeDaten.bind(this);
        this.testphaseNutzen = this.testphaseNutzen.bind(this);
    }

    componentDidMount() {
        this.ladeDaten();
    }

    ladeDaten() {
        let url = ConfigUtil.getConfig().resourceUrls.userproaccount;
        FetchUtil.fetchGet(url,
            json => {
                this.setState({...json});
            },
            responseNotOk => {
                this.showError("Fehler beim Laden der Daten: " + responseNotOk.message)
            },
            error => {
                this.showError("Fehler beim Laden der Daten: " + error.message)
        });

        url = ConfigUtil.getConfig().resourceUrls.tournament + "/bezahlungen";
        FetchUtil.fetchGet(url,
            json => {
                this.setState({tournamentPayments: json});
            },
            responseNotOk => {
                this.showError("Fehler beim Laden der Turnierbezahlungen der Daten: " + responseNotOk.message)
            },
            error => {
                this.showError("Fehler beim Laden der Turnierbezahlungen: " + error.message)
            });

        url = ConfigUtil.getConfig().resourceUrls.gutschein;
        FetchUtil.fetchGet(url,
            json => {
                this.setState({gutscheine: json});
            },
            responseNotOk => {
                this.showError("Fehler beim Laden der Gutscheine zum Benutzer: " + responseNotOk.message)
            },
            error => {
                this.showError("Fehler beim Laden der Gutscheine zum Benutzer: " + error.message)
            });

    }

    testphaseNutzen() {
        let url = ConfigUtil.getConfig().resourceUrls.userproaccount + "/testphasenutzen";
        FetchUtil.fetchPost(url,
            {},
            () => {
                this.ladeDaten()
            },
            responseNotOk => {
                console.log(responseNotOk.message);
                this.showError("Fehler beim Nutzen der Testphase: " + responseNotOk.message)
            },
            error => {
                console.log(error.message);
                this.showError("Fehler beim Nutzen der Testphase: " + error.message)
            })
    }


    showInfo(message) {
        this.toast.show({
            severity: 'success',
            summary: 'Erfolgreich...',
            detail: message
        })
    }

    showError(message) {
        this.messages.show({
            severity: 'error',
            sticky: 'true',
            summary: 'Fehler...',
            detail: message
        });
    }

    render() {
        return (<div
                style={{textAlign: "center", align: "center", backgroundColor: "white", color: "black"}}>
                <Toast ref={(el) => this.toast = el}/>
                <Messages ref={(el) => this.messages = el}/>
                <div className="grid pageHeader" style={{padding: 0, margin: 0, backgroundColor: "#4dffa6"}}>
                    <div className="col" style={{marginTop: 10, padding: 0}}>
                        Bezahlen
                    </div>
                </div>
                <Toast ref={(el) => this.toast = el}/>
                <Messages ref={(el) => this.messages = el}/>
                <div style={{padding:10}}>
                    {this.getCard()}
                </div>
                {!this.state.testphaseGenutzt && <div style={{padding: 20}}>
                        <div className="grid">
                            <Button label="Jetzt 10 Tage Pro-User Gutschrift einlösen...!!!"
                                    onClick={() => this.testphaseNutzen()}/>
                        </div>
                    </div>
                }
                <div className="grid" style={{margin:0}}>
                    {this.getProAccountZeitraeume()}
                    {this.getPayArea()}
                </div>
                <div className="grid" style={{margin:0}}>
                    {this.getTournamentPayments()}
                    {this.getGutscheine()}
                </div>
            </div>
        )
    }

    getPrice() {
        if (this.state.zeitraum === '30') {
            return "4.00";
        } else if(this.state.zeitraum === '90') {
            return "10.00";
        } else if(this.state.zeitraum === '180') {
            return "18.00";
        } else if(this.state.zeitraum === '366') {
            return "29.00";
        } else {
            return undefined;
        }
    }

    getPayArea() {
        return <div className="col-12 md:col-12 lg:col-6">
            <div className="ligashortoverview" style={{height: "100%"}}>
                <div className="grid" style={{textAlign:"left"}}>
                    <div className="col" style={{fontWeight:"bold"}}>
                        Weitere Pro-Zeit buchen
                    </div>
                </div>
                {!this.state.kannWiederProBuchen && <>
                    <div className="grid" style={{textAlign:"left"}}>
                        <div className="col">
                            Deine aktuelle Pro-Zeit läuft noch. Du kannst erst 14 Tage vor Ablauf der Frist (ab dem {this.state.abWiederProBuchen}) wieder buchen.
                        </div>
                    </div>
                </>}
                {this.state.kannWiederProBuchen && <>
                    {!this.state.adresseVollstaendig && <>
                        <div className="grid" style={{textAlign:"left"}}>
                            <div className="col">
                                Um Pro-Zeit buchen zu können musst du in deinem Profile eine Rechnungsadresse in Deutschland hinterlegen.
                                <NavLink to={NAVIGATION_ITEM.PROFIL.route} style={{textDecoration: "none"}}>
                                    <span style={{whiteSpace: "nowrap"}}>Hier geht es zu deinem Profil</span>
                                </NavLink>
                            </div>
                        </div>
                    </>}
                    {this.state.adresseVollstaendig && <>
                        {!this.state.proTimeReceived
                            ? this.getPayAreaProTimeSelection()
                            : <span style={{color:"red"}}>Pro-Zeitraum wurde bereits gebucht...</span>
                        }
                    </>}
                </>}
            </div>
        </div>
    }

    getPayAreaProTimeSelection() {
        return <div>
            <div className="grid" style={{textAlign:"left"}}>
                <div className="col">
                    <RadioButton inputId="zeitraum1" name="zeitraum" value="30" onChange={(e) => this.setState({zeitraum: e.value})} checked={this.state.zeitraum === '30'} />
                    <label htmlFor="chb-systementscheidetwerbeginnt" className="p-checkbox-label" style={{marginLeft:5}}>30 Tage - 4.00 Euro</label>
                </div>
            </div>
            <div className="grid" style={{textAlign:"left", marginTop:20}}>
                <div className="col">
                    <RadioButton inputId="zeitraum2" name="zeitraum" value="90" onChange={(e) => this.setState({zeitraum: e.value})} checked={this.state.zeitraum === '90'} />
                    <label htmlFor="chb-ausbullen" className="p-checkbox-label" style={{marginLeft:5}}>90 Tage - 10.00 Euro</label>
                </div>
            </div>
            <div className="grid" style={{textAlign:"left", marginTop:20}}>
                <div className="col">
                    <RadioButton inputId="zeitraum3" name="zeitraum" value="180" onChange={(e) => this.setState({zeitraum: e.value})} checked={this.state.zeitraum === '180'} />
                    <label htmlFor="chb-ich-beginne" className="p-checkbox-label" style={{marginLeft:5}}>180 Tage - 18.00 Euro</label>
                </div>
            </div>
            <div className="grid" style={{textAlign:"left", marginTop:20}}>
                <div className="col">
                    <RadioButton inputId="zeitraum14" name="zeitraum" value="366" onChange={(e) => this.setState({zeitraum: e.value})} checked={this.state.zeitraum === '366'} />
                    <label htmlFor="chb-gegner-beginnt" className="p-checkbox-label" style={{marginLeft:5}}>1 Jahr - 29.00 Euro</label>
                </div>
            </div>
            <div className="grid" style={{textAlign: "left", marginTop: 20}}>
                <div className="col">
                    {this.getPaypalButton()}
                </div>
            </div>
        </div>;
    }

    getPaypalButton() {
        return !this.state.zeitraum || !this.getPrice()
            ? <span style={{color:"red"}}>Bitte Zeitraum auswählen...</span>
            : <PaypalComponent option={ "pro-user" } price={ this.getPrice() } returnPath={ "bezahlen" }
                               data={{ tage: this.state.zeitraum }}
                               onSuccess={
                                   () => {
                                       this.showInfo("Zeitraum wurde gebucht!");
                                       this.setState({proTimeReceived: true });
                                       this.ladeDaten();
                                   }
                               }
                               onResponseNotOK={
                                   (message) => {
                                       this.showError("Fehler: " + message);
                                   }
                               }
                               onError={
                                   (message) => {
                                       this.showError("Fehler: " + message);
                                   }
                               }
            ></PaypalComponent>
    }

    getCard() {
        return <Card style={{textAlign:"left", marginTop:20, marginBottom:20, width:"100%"}}>
            {this.state.aktuellProUser && <>
            <span style={{fontWeight:"bold", color:"green", fontSize:"large"}}>Du bist aktuell Pro-User. Vielen Dank für dein Vertrauen!</span>
                <br/><br/>
            </>}
            Die <span style={{fontWeight:"bold"}}>gute Nachricht</span>, du musst für darthelfer.de nichts bezahlen.
            <br/>
            Mit dem kostenlosen Account kannst du jeden Tag ein Onlinegame mit einem beliebigen Benutzer, oder sogar mit einem Gast, spielen.
            <br/>
            <br/>
            Teilnahmen an Turnieren und Ligen sind nur mit einem Pro-Account möglich.
            <br/>
            <br/>
            Wir haben teilweise mit Ligenbetreibern eine Vereinbarung, dass diese eine Nutzungsgebühr je Liga an uns entrichten. In diesem Fall erhälst du für den Zeitraum der Ligateilnahme den Pro-User Status.
            <br/>
            <br/>
            Bei Fragen findest du in unserem Support-Forum Antworten: <a href="https://support.darthelfer.de" target="_blank" rel="noopener noreferrer">support.darthelfer.de</a>.
            <br/><br/>
            Um darthelfer.de als Pro-User zu testen, kannst du hier eine kostenlose <span style={{fontWeight:"bold"}}>Pro-User-Testphase für 10 Tage</span> starten. Vorsicht, dies ist nur einmal möglich.
            <br/>
            <span style={{fontWeight:"bold"}}>TIPP!</span> Du solltest diese Funktion auf jeden Fall nutzen, da du damit auf jeden Fall 10 Tage 'Pro-User' geschenkt bekommst, auch wenn du bereits Pro-User bist.
        </Card>

    }

    getGutscheine() {
        let rows =[];
        if( this.state.gutscheine !== null && this.state.gutscheine !== undefined && this.state.gutscheine.length > 0) {
            for (const gutschein of this.state.gutscheine) {
                let ligabetreiber = "";
                let eingeloest = "";
                if( gutschein.eingeloest) {
                    eingeloest += "Der Gutschein wurde bereits eingelöst. (Eingelöst von: " + gutschein.nameEingeloest + " am " + gutschein.timestampEingeloestString + ")";
                } else {
                    eingeloest += "Der Gutschein ist noch gültig und kann eingelöst werden.";
                }
                if( gutschein.leagueOperatorName !== null ) {
                    ligabetreiber += "Gültig für Ligabetreiber: " + gutschein.leagueOperatorName;
                }
                if( gutschein.turniersystem !== null) {
                    ligabetreiber += " für den Turnier-/Ligatyp: " +  gutschein.turniersystem;
                }
                rows.push(<div key={gutschein.id}>
                    Gutscheincode: {gutschein.code}
                    <br/>
                    Ausgestellt am: {gutschein.timestampString}
                    <br/>
                    {eingeloest}
                    <br/>
                    {ligabetreiber}
                    <hr/></div>);
            }
        } else {
            rows.push(<div key="gutschein_id">Keine Gutscheine vorhanden!</div>);
        }

        return <div className="col-12 md:col-12 lg:col-6">
            <div className="ligashortoverview" style={{height: "100%", textAlign:"left"}}>
                <h4>Deine Gutscheine</h4>
                {rows}
                <div>Gutscheine sind maximal ein Jahr gültig.</div>
            </div>
        </div>
    }

    getTournamentPayments() {
        let rows =[];
        if( this.state.tournamentPayments !== null && this.state.tournamentPayments !== undefined && this.state.tournamentPayments.length > 0) {
            for (const payment of this.state.tournamentPayments) {
                rows.push(<div key={payment.id}>
                    <div>Rechnungsnummer: {payment.rechnungsnummer}
                        <br/>
                        Zeitpunkt: {payment.zeitpunkt}
                        <br/>
                        Rechnung: <a href={ConfigUtil.getConfig().resourceUrls.tournamentpayment + "/" + payment.id}>herunterladen</a>
                        <br/>
                        Notiz: {payment.notiz}</div>
                    <hr/></div>);
            }
        } else {
            rows.push(<div key="gutschein_id">Keine Bezahlungen zu Turnieren/Ligen vorhanden!</div>);
        }

        return <div className="col-6 md:col-12 lg:col-6">
            <div className="ligashortoverview" style={{height: "100%", textAlign:"left"}}>
                <h4>Informationen über Bezahlungen von Ligen und Turnieren</h4>
                {rows}
            </div>
        </div>
    }

    getProAccountZeitraeume() {
        if(this.state.zeitraeume === null || this.state.zeitraeume === undefined || this.state.zeitraeume.length === 0) {
            return <div className="col-12 md:col-12 lg:col-6">
                <div className="ligashortoverview" style={{height: "100%"}}>
                    <Card style={{textAlign:"left", marginTop:20, marginBottom:20, width:"100%"}}>
                        Keine Informationen über Pro-Account Zeiträume vorhanden
                    </Card>
                </div>
            </div>
        }

        let rows =[];
        const proEnde = this.state.zeitraeume[0];
        for (const zeitraum of this.state.zeitraeume) {
            rows.push(<div key={zeitraum.id}>
                <div>Zeitraum: {zeitraum.zeitraumvonString} - {zeitraum.zeitraumbisString}<br/>
                    Kaufzeitpunkt: {zeitraum.timestampString},
                    {zeitraum.hatRechnung && <>
                        Rechnung: <a href={ConfigUtil.getConfig().resourceUrls.receipt + "/user/" + zeitraum.id}>herunterladen</a>
                    </>}
                    <br/>
                Notiz: {zeitraum.notiz}</div>
                <hr/></div>);
        }

        return <div className="col-12 md:col-12 lg:col-6">
            <div className="ligashortoverview" style={{height: "100%"}}>
                <div className="grid" style={{textAlign:"left"}}>
                    <div className="col" style={{fontWeight:"bold", color:"green"}}>
                        Deine aktuelle Pro-Zeit endet am <span>{proEnde.zeitraumbisString}</span>
                    </div>
                </div>
                <Card style={{textAlign:"left", marginTop:20, marginBottom:20, width:"100%"}}>
                    <h4>Informationen über deine Pro-Account Zeiträume.</h4>
                {rows}
                </Card>
            </div>
        </div>

    }

}


Bezahlen.propTypes = {
    userId: PropTypes.string.isRequired,
};



export default Bezahlen
