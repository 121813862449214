import React, {useEffect, useState} from 'react'
import ChatComponent from "../../general/ChatComponent";
import {Button} from "primereact/button";
import {Dialog} from "primereact/dialog";
import {Fieldset} from "primereact/fieldset";

export default function ScoringChatComponent({showChat, onShowChat, chatMessages, gameid, userid, isMobileDevice, onError}) {

    let [messageCount, setMessageCount] = useState(0);

    useEffect(() => {
        if( chatMessages !== undefined && messageCount < chatMessages.length) {
            onShowChat(true);
            setMessageCount(chatMessages.length);
        }
    }, [chatMessages]);

    if( isMobileDevice ) {
        return <Dialog baseZIndex={2000}  header="Chat" visible={showChat} modal={false} position="bottom" style={{ width: "100%", height: "50%" }} onHide={() => onShowChat(false)}>
            <ChatComponent messages={chatMessages} type="game" id={gameid} userId={userid}
                           callBackOnError={(message) => onError(message)}/>
        </Dialog>
    }
    return <div className="grid align-content-end vertical-container" style={{
        position: "absolute",
        bottom: 0,
        left: 0,
        height: "100%",
        width: 20,
        zIndex: 550,
        margin: 0,
        padding: 0
    }}>
        <div className="col" style={{padding: 0, margin: 0, textAlign: "right"}}>
            <Button className={showChat ? "" : "p-button-secondary"} icon="pi pi-book"
                    style={{height: 35, width: 35, margin: 0, padding: 0, marginTop: 10}} onClick={() => {
                onShowChat(!showChat);
            }} tooltip=""/>
        </div>
        <Dialog baseZIndex={2000} header="Chat" visible={showChat} modal={false}  resizable={true} style={{ width: "30%", height: "50%" }} onHide={() => onShowChat(false)}>
            <Fieldset legend="Hilfe zum Chatbereich" toggleable={true} collapsed={true}>
                <div>
                    Du kannst den Chatbereich frei auf dem Fenster plazieren. Dafür im Kopfbereich des Chatbereichs einfach klicken und den Bereich an die gewünschte Position schieben.
                </div>
                <div style={{marginBottom:20}}>
                    Die Größe des Bereichs kann verändert werden, hierzu bitte die Ecke des Bereichs unten rechts nehmen und größer oder kleiner ziehen!!!
                </div>
            </Fieldset>
            <ChatComponent messages={chatMessages} type="game" id={gameid} userId={userid}
                           callBackOnError={(message) => onError(message)}/>
        </Dialog>
    </div>

}


