import React from 'react'

import {Panel} from 'primereact/panel';
import {Button} from 'primereact/button';
import {Toast} from "primereact/toast";
import {Fieldset} from "primereact/fieldset";
import {Messages} from "primereact/messages";
import FetchUtil from "../../../util/FetchUtil";
import ConfigUtil from "../../../util/ConfigUtil";

import DateUtil from "../../../util/DateUtil";
import {InputText} from 'primereact/inputtext';
import {SelectTeamComponent} from "../../general/SelectTeamComponent";
import DHDatePicker from "../../general/DHDatePicker";


const INITIAL_STATE = {
    vondatumTrainingsession: "",
    bisdatumTrainingsession: "",
    saisonenTrainingsession:[],
};

class SettingsSaisonen extends React.Component {

    constructor(props) {
        super(props);

        this.state = {...INITIAL_STATE, id: props.id};

        this.saveTrainingsession = this.saveTrainingsession.bind(this);
        this.saveSpieltage = this.saveSpieltage.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.showError = this.showError.bind(this);
        this.showInfo = this.showInfo.bind(this);
        this.loadData = this.loadData.bind(this);
        this.getTrainingsessionRows = this.getTrainingsessionRows.bind(this);
        this.setSaisonActive = this.setSaisonActive.bind(this);
        this.setGamedaySaisonActive = this.setGamedaySaisonActive.bind(this);
        this.setTrainingsessionSaisonLoeschen = this.setTrainingsessionSaisonLoeschen.bind(this);
        this.setGamedaySaisonLoeschen = this.setGamedaySaisonLoeschen.bind(this);
    }

    componentDidMount() {
        this.loadData();
    }

    loadData() {
        FetchUtil.fetchGet(ConfigUtil.getConfig().resourceUrls.settings + "/getsaisonen",
            json => {
                this.setState({...this.state, saisonenTrainingsession: json});
            },
            responseNotOk => {
                this.showError("Fehler beim Laden der Saisonen: " + responseNotOk.message);
            },
            error => {
                this.showError("Fehler beim Laden der Saisonen: " + error.message);
            })

        FetchUtil.fetchGet(ConfigUtil.getConfig().resourceUrls.settings + "/getgamedaysaisonen",
            json => {
                this.setState({...this.state, saisonenGameday: json});
            },
            responseNotOk => {
                this.showError("Fehler beim Laden der Saisonen: " + responseNotOk.message);
            },
            error => {
                this.showError("Fehler beim Laden der Saisonen: " + error.message);
            })
    }

    showInfo(message) {
        this.toast.show({
            severity: 'success',
            summary: 'Erfolgreich...',
            detail: message
        })
    }

    showError(message) {
        this.messages.show({
            severity: 'error',
            sticky: 'true',
            summary: 'Fehler...',
            detail: message
        });
    }

    handleChange(e) {
        this.setState({[e.target.name]: e.target.value});
    }

    setSaisonActive(id) {
        this.messages.clear();
        let data = {id: id};
        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.settings + "/setsaisonactive",
            data,
            json => {
                this.showInfo("Saison wurde auf aktiv gesetzt.");
                this.loadData();
            },
            responseNotOk => {
                this.showError("Fehler: " + responseNotOk.message);
            },
            error => {
                this.showError("Fehler: " + error.message);
            }
        );
    }

    setGamedaySaisonActive(id) {
        this.messages.clear();
        let data = {id: id};
        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.settings + "/setgamedaysaisonactive",
            data,
            json => {
                this.showInfo("Saison wurde auf aktiv gesetzt.");
                this.loadData();
            },
            responseNotOk => {
                this.showError("Fehler: " + responseNotOk.message);
            },
            error => {
                this.showError("Fehler: " + error.message);
            }
        );
    }

    setGamedaySaisonLoeschen(id) {
        this.messages.clear();
        let data = {id: id};
        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.settings + "/deletegamedaysaison",
            data,
            json => {
                this.showInfo("Saison wurde gelöscht.");
                this.loadData();
            },
            responseNotOk => {
                this.showError("Fehler: " + responseNotOk.message);
            },
            error => {
                this.showError("Fehler: " + error.message);
            }
        );
    }

    setTrainingsessionSaisonLoeschen(id) {
        this.messages.clear();
        let data = {id: id};
        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.settings + "/deletesaison",
            data,
            json => {
                this.showInfo("Saison wurde gelöscht.");
                this.loadData();
            },
            responseNotOk => {
                this.showError("Fehler: " + responseNotOk.message);
            },
            error => {
                this.showError("Fehler: " + error.message);
            }
        );
    }

    saveSpieltage() {
        this.messages.clear();
        let data = {vondatum: this.state.vondatumSpieltage, bisdatum: this.state.bisdatumSpieltage, bezeichnung: this.state.bezeichnungSpieltage, teamid: this.state.teamId};
        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.settings + "/savegamedaysaison",
            data,
            json => {
                this.showInfo("Speichern der Saison war erfolgreich.");
                this.loadData();
            },
            responseNotOk => {
                this.showError("Fehler: " + responseNotOk.message);
            },
            error => {
                this.showError("Fehler: " + error.message);
            }
        );
    }

    saveTrainingsession() {
        this.messages.clear();
        let data = {vondatum: this.state.vondatumTrainingsession, bisdatum: this.state.bisdatumTrainingsession};
        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.settings + "/savesaison",
            data,
            json => {
                this.showInfo("Speichern der Saison war erfolgreich.");
                this.loadData();
            },
            responseNotOk => {
                this.showError("Fehler: " + responseNotOk.message);
            },
            error => {
                this.showError("Fehler: " + error.message);
            }
        );
    }

    getSpieltagSessionRows() {
        let rows = [];

        if( this.state.saisonenGameday === undefined || this.state.saisonenGameday.length === 0 ) {
            return rows;
        }
        for (let i = 0; i < this.state.saisonenGameday.length; i++) {
            let saison = this.state.saisonenGameday[i];
            let von = DateUtil.localDateTimeToString(saison.vondatum);
            let bis = DateUtil.localDateTimeToString(saison.bisdatum);

            rows.push(<div key={"row_" + saison.id} className="grid" style={{marginLeft:"5px"}}>
                <div className="col-fixed" style={{width:'150px', textAlign:"left", borderRight:"1px solid", borderBottom:"1px solid"}}>
                    {von}
                </div>
                <div className="col-fixed" style={{width:'150px', textAlign:"left", borderRight:"1px solid", borderBottom:"1px solid"}}>
                    {bis}
                </div>
                <div className="col-fixed" style={{width:'150px', textAlign:"left", borderRight:"1px solid", borderBottom:"1px solid"}}>
                    {saison.bezeichnung}
                </div>
                <div className="col-fixed" style={{width:'150px', textAlign:"left", borderRight:"1px solid", borderBottom:"1px solid"}}>
                    {saison.teambezeichnung}
                </div>
                <div className="col-fixed" style={{width:'35px', textAlign:"center", borderRight:"1px solid", borderBottom:"1px solid"}}>
                    {saison.active ? <i className="pi pi-star"/> : ""}
                </div>
                <div className="col-fixed" style={{width:'95px', textAlign:"left", borderRight:"1px solid", borderBottom:"1px solid"}}>
                    <Button icon="pi pi-star"
                            onClick={() => this.setGamedaySaisonActive(saison.id)}
                            style={{marginRight:"5px"}}
                            rendered={saison.active}
                            tooltip={"Trainingsession Aktive Saison setzen"}
                            className="p-button-success"/>
                    <Button icon="pi pi-times"
                            tooltip="Trainingsession Saison löschen"
                            onClick={() => this.setGamedaySaisonLoeschen(saison.id)}
                            style={{marginRight:"5px"}} />
                </div>
            </div>);
        }
        return rows;
    }

    getTrainingsessionRows() {
        let rows = [];

        for (let i = 0; i < this.state.saisonenTrainingsession.length; i++) {
            let saison = this.state.saisonenTrainingsession[i];
            let von = DateUtil.localDateTimeToString(saison.vondatum);
            let bis = DateUtil.localDateTimeToString(saison.bisdatum);

            rows.push(<div key={"row_" + saison.id} className="grid" style={{marginLeft:"5px"}}>
                <div className="col-fixed" style={{width:'150px', textAlign:"left", borderRight:"1px solid", borderBottom:"1px solid"}}>
                    {von}
                </div>
                <div className="col-fixed" style={{width:'150px', textAlign:"left", borderRight:"1px solid", borderBottom:"1px solid"}}>
                    {bis}
                </div>
                <div className="col-fixed" style={{width:'35px', textAlign:"center", borderRight:"1px solid", borderBottom:"1px solid"}}>
                    {saison.active ? <i className="pi pi-star"/> : ""}
                </div>
                <div className="col-fixed" style={{width:'95px', textAlign:"left", borderRight:"1px solid", borderBottom:"1px solid"}}>
                    <Button icon="pi pi-star"
                            onClick={() => this.setSaisonActive(saison.id)}
                            style={{marginRight:"5px"}}
                            rendered={saison.active}
                            tooltip={"Trainingsession Aktive Saison setzen"}
                            className="p-button-success"/>
                    <Button icon="pi pi-times"
                            tooltip="Trainingsession Saison löschen"
                            onClick={() => this.setTrainingsessionSaisonLoeschen(saison.id)}
                            style={{marginRight:"5px"}} />
                </div>
            </div>);
        }
        return rows;
    }

    getSpieltageSaisonen() {
        let rows = this.getSpieltagSessionRows();
        return <Panel header="Spieltage Saisonen bestimmen" style={{textAlign: "left"}}>
            <div className="grid" style={{marginTop: "20px"}}>
                <div className="col-fixed" style={{width: '180px', textAlign: "right"}}>Von Datum:</div>
                <div className="col">
                    <DHDatePicker disabled={false} value={this.state.vondatumSpieltage} changeValue={(date) => this.setState({vondatumSpieltage: date})}/>
                </div>
            </div>
            <div className="grid">
                <div className="col-fixed" style={{width: '180px', textAlign: "right"}}>Bis Datum:</div>
                <div className="col">
                    <DHDatePicker disabled={false} value={this.state.bisdatumSpieltage} changeValue={(date) => this.setState({bisdatumSpieltage: date})}/>
                </div>
            </div>
            <div className="grid">
                <div className="col-fixed" style={{width: '180px', textAlign: "right"}}>Bezeichnung:</div>
                <div className="col">
                    <InputText name="bezeichnungSpieltage" className={!this.state.bezeichnungSpieltage ? "p-error" : ""} style={{width: 300}}
                               value={this.state.bezeichnungSpieltage} onChange={(e) => this.handleChange(e)}/>
                </div>
            </div>
            <div className="grid">
                <div className="col-fixed" style={{width: '180px', textAlign: "right"}}>Mannschaft:</div>
                <div className="col">
                    <SelectTeamComponent teamid={this.state.teamId} callbackChange={(teamdId) => this.setState({teamId: teamdId})}/>
                </div>
            </div>

            <div className="grid">
                <div className="col-fixed" style={{width: '180px', textAlign: "right"}}></div>
                <div className="col"><Button label="Speichern" onClick={() => this.saveSpieltage()}/></div>
            </div>
            <div style={{marginTop:"20px"}}>
                {rows}
            </div>
        </Panel>
    }

    getTraininsessionSaisonen() {
        let rows = this.getTrainingsessionRows();
        return <Panel header="Trainingsession Saisonen bestimmen" style={{textAlign: "left"}}>
            <div className="grid" style={{marginTop: "20px"}}>
                <div className="col-fixed" style={{width: '180px', textAlign: "right"}}>Von Datum:</div>
                <div className="col">
                    <DHDatePicker disabled={false} value={this.state.vondatumTrainingsession} changeValue={(date) => this.setState({vondatumTrainingsession: date})}/>
                </div>
            </div>
            <div className="grid">
                <div className="col-fixed" style={{width: '180px', textAlign: "right"}}>Bis Datum:</div>
                <div className="col">
                    <DHDatePicker disabled={false} value={this.state.bisdatumTrainingsession} changeValue={(date) => this.setState({bisdatumTrainingsession: date})}/>
                </div>
            </div>
            <div className="grid">
                <div className="col-fixed" style={{width: '180px', textAlign: "right"}}></div>
                <div className="col"><Button label="Speichern" onClick={() => this.saveTrainingsession()}/></div>
            </div>
            <div style={{marginTop:"20px"}}>
                {rows}
            </div>
        </Panel>
    }

    render() {
        return <div style={{textAlign:"left"}}>
            <Toast ref={(el) => this.toast = el}/>
            <Messages ref={(el) => this.messages = el}/>
            <Fieldset legend="Hinweise..." style={{marginBottom:20}}>
                <div>- Hier können Saisonen erfasst werden, damit ist die Filterung der Ranglisten möglich.</div>
                <div>* Beispiel: Die Saison geht von 01.01.2018 - 31.12.2018</div>
                <div>- Über den 'Sternbutton' kann die aktuell aktive Saison gewählt werden, damit wird der Filter vorbelegt.</div>
            </Fieldset>
            <div className="grid">
                <div className="col">
                    {this.getTraininsessionSaisonen()}
                </div>
                <div className="col">
                    {this.getSpieltageSaisonen()}
                </div>
            </div>
        </div>
    }
}

SettingsSaisonen.propTypes = {
};

export default SettingsSaisonen;