import React, {useState} from "react";
import PropTypes from "prop-types";

import {Dialog} from 'primereact/dialog';
import {Button} from 'primereact/button';
import {InputNumber} from "primereact/inputnumber";

function TournamentKratzerAuslosungDialog({callBackOnAuslosen, callBackOnNein, anzahlSpieler}) {

    let [anzahlGames, setAnzahlGames] = useState("");
    let [ausloesungLaeuft, setAusloesungLaeuft] = useState("");

    const footer = (
        <div>
            <Button label="Auslosung starten" icon="pi pi-check" disabled={ausloesungLaeuft || anzahlGames === ""} onClick={() => {
                setAusloesungLaeuft(true);
                callBackOnAuslosen(anzahlGames);
            }} />
        </div>
    );

    return <Dialog baseZIndex={2000} header="Auslosung starten" visible={true}
                   style={{width: '40vw'}} footer={footer} onHide={() => callBackOnNein()} maximizable>

        <div style={{textAlign:"left", height:400, padding:10}}>
            <div style={{marginTop:"20px"}}>
                Bitte gebe die Anzahl der Spiele ein. Beispiel: du hast 40 Spieler und gibst Anzahl Games 10 ein, dann sind in jedem Game 4 Spieler.<br/>
                Sofern dir eine Auslosung bzgl. Aufteilung nicht gefällt, kannst du einfach eine neue Spielrunde auslosen.
            </div>
            <div style={{marginTop:10, marginBottom:10}}>
                Es gibt {anzahlSpieler} Spieler die in die nächste Runde kommen.
            </div>
            <div style={{marginTop:"20px"}}>
                <div style={{float:"left", width:350}}>
                    Anzahl Games:
                </div>
                <div style={{float:"left"}}>
                    <InputNumber min={1} style={{width: "80px", marginRight: "5px"}} value={anzahlGames}
                               onChange={(e) => setAnzahlGames(e.value)}/>

                </div>
            </div>
        </div>
        {ausloesungLaeuft && <span style={{color:"red"}}>Die Auslosung wird durchgeführt, dies kann etwas dauern!!!</span>}
    </Dialog>

}


TournamentKratzerAuslosungDialog.propTypes = {
    callBackOnNein: PropTypes.func.isRequired,
    callBackOnAuslosen: PropTypes.func.isRequired,
};

export default TournamentKratzerAuslosungDialog;
