import React, {useState} from 'react'
import {Sidebar} from "primereact/sidebar";
import {Button} from "primereact/button";
import FetchUtil from "../../../util/FetchUtil";
import ConfigUtil from "../../../util/ConfigUtil";
import ErrorInfoComponent from "../../general/ErrorInfoComponent";
import {InputNumber} from "primereact/inputnumber";
import {PrimeIcons} from "primereact/api";

export default function LigaspieltagGameErgebnisVonHandSidebar({gameId, onClose}) {

    const [infoMessage, setInfoMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);

    const [heim, setHeim] = useState(0);
    const [gast, setGast] = useState(0);

    const save = () => {
        setErrorMessage(undefined);
        if( heim === null || gast === null) {
            setErrorMessage("Heim und Gast muss gefüllt sein.");
            return;
        }
        let url = ConfigUtil.getConfig().resourceUrls.tournament + "/changeleaguegame/" + gameId;
        let data = {
            anzahlPlayer1: heim,
            anzahlPlayer2: gast,
        };
        FetchUtil.fetchPost(url,
            data,
            () => {
                setInfoMessage("Ergebnis wurde erfolgreich gespeichert.");
                onClose(true);
            },
            responseNotOk => {
                setErrorMessage("Fehler beim Löschen des Ergebnisses: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler beim Löschen des Ergebnisses: " + error.message);
            });

    }

    return <Sidebar visible={true} style={{width: 380}}
                    onHide={(e) => onClose(false)}
                    position="left" showCloseIcon={true}>
        <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage}
                            onClearInfoMessage={() => setInfoMessage(undefined)}
                            onClearErrorMessage={() => setErrorMessage(undefined)}/>
        <div style={{padding: 10, marginBottom: 10}}>
            <Button label="Bereich schliessen" onClick={() => onClose(false)}/>
        </div>
        <div style={{fontSize: 20, fontWeight: "bold"}}>Ergebnis von Hand eintragen</div>
        <div style={{marginTop: 20, marginBottom: 10}}>
            Heim:<br/>
            <InputNumber value={heim} onValueChange={(e) => setHeim(e.value)} min={0}/>
        </div>
        <div>
            Gast:<br/>
            <InputNumber value={gast} onValueChange={(e) => setGast(e.value)} min={0}/>
        </div>
        <div style={{marginTop:20}}>
            <Button icon={PrimeIcons.SAVE} label="Speichern"
                    onClick={() => save()}/>
        </div>
    </Sidebar>
}
