import React, {useEffect, useState} from "react";
import {Dropdown} from "primereact/dropdown";
import FetchUtil from "../../util/FetchUtil";
import ConfigUtil from "../../util/ConfigUtil";

export function SelectGamedaySaisonComponent(props) {

    const [teamid, setTeamid] = useState([]);
    const [saisons, setSaisons] = useState([]);
    const [saisonLoaded, setSaisonLoaded] = useState(false);

    const callBackLoaded = saisons => {
        setSaisons(saisons);
        if( saisons.length === 1) {
            props.callbackChange(saisons[0].id);
        }
    };

    if( props.teamid !== teamid) {
        setTeamid(props.teamid);
        if( saisonLoaded === true) {
            setSaisonLoaded(false);
        }
    }

    useEffect(() => {
        if( !saisonLoaded && props.teamid !== undefined && props.teamid !== "") {
            loadGamedaySaisons(props, callBackLoaded);
            setSaisonLoaded(true);
        } else {

        }
    });

    let saison = getSaison(props.saisonid, saisons);
    return (<Dropdown value={saison} options={saisons} optionLabel="bezeichnung"
                      onChange={(e) => {
                          props.callbackChange(e.value.id)
                      }}
                      placeholder="Eingabe..." style={{width:300}}/>

    );
}

function getSaison(saisonid, saisons) {
    if (saisons === undefined || saisons === null) {
        return {id: undefined, bezeichnung:""};
    }
    for (const tmp of saisons) {
        if (tmp.id === saisonid) {
            return tmp;
        }
    }
    return {id: undefined, bezeichnung: ''};
}

function loadGamedaySaisons(props, callbackOnLoaded) {
    FetchUtil.fetchGet(ConfigUtil.getConfig().resourceUrls.settings + "/getgamedaysaisonen?teamid=" + props.teamid,
        json => {
            callbackOnLoaded(json);
        },
        responseNotOk => {
            props.callbackOnError("Fehler beim Laden der Mannschaften: " + responseNotOk.message);
        },
        error => {
            props.callbackOnError("Fehler beim Laden der Mannschaften: " + error.message);
        })
}
