import React from 'react'
import {Dialog} from "primereact/dialog";
import {Button} from "primereact/button";
import PropTypes from "prop-types";
import FetchUtil from "../../../util/FetchUtil";
import ConfigUtil from "../../../util/ConfigUtil";
import {Toast} from "primereact/toast";
import {Messages} from "primereact/messages";
import {Fieldset} from "primereact/fieldset";
import {PickList} from 'primereact/picklist';

class TurnierSetzlisteKoDialog extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            source: [],
            target: []
        };

        this.save = this.save.bind(this);
        this.showInfo = this.showInfo.bind(this);
        this.showError = this.showError.bind(this);
        this.itemTemplate = this.itemTemplate.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {
        if (this.props.tournament !== undefined) {
            let source = [];
            let target = [];
            if( this.props.tournament.tournamentPlayerEntities !== undefined && this.props.tournament.tournamentPlayerEntities !== null ) {
                for (const player of this.props.tournament.tournamentPlayerEntities) {
                    let isInSetzliste = false;
                    if( this.props.tournament.tournamentSetzlisteEntities !== undefined && this.props.tournament.tournamentSetzlisteEntities !== null ) {
                        for (const setzlisteeintrag of this.props.tournament.tournamentSetzlisteEntities) {
                            if( setzlisteeintrag.playerid === player.id ) {
                                isInSetzliste = true;
                            }
                        }
                    }
                    if( !isInSetzliste) {
                        source.push(player);
                    }
                }
            }
            if( this.props.tournament.tournamentSetzlisteEntities !== undefined && this.props.tournament.tournamentSetzlisteEntities !== null ) {
                for (const setzlisteeintrag of this.props.tournament.tournamentSetzlisteEntities) {
                    let tmpPlayer;
                    for (const player of this.props.tournament.tournamentPlayerEntities) {
                        if( player.id === setzlisteeintrag.playerid) {
                            tmpPlayer = player;
                        }
                    }
                    target.push(tmpPlayer);
                }
            }

            this.setState({
                tournament: this.props.tournament,
                source: source,
                target: target,
            });
        }
    }

    showInfo(message) {
        this.toast.clear();
        this.toast.show({
            severity: 'success',
            summary: 'Erfolgreich...',
            detail: message
        })
    }

    showError(message) {
        this.messages.clear();
        this.messages.show({
            severity: 'error',
            sticky: 'true',
            summary: 'Fehler...',
            detail: message
        });
    }

    save() {
        let setzliste = [];
        for (const spieler of this.state.target) {
            setzliste.push({id: undefined, playerid: spieler.id});
        }
        let id = this.state.tournament.id;
        let data = {tournamentId: id, tournametSetzlisteEinzelnDtoList: setzliste};
        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.tournament + "/savesetzliste",
            data,
            json => {
                this.showInfo("Speichern war erfolgreich!")
                this.props.callBackRefresh();
            },
            responseNotOk => {
                this.showError("Fehler beim Speichern: " + responseNotOk.message);
            },
            error => {
                this.showError("Fehler beim Speichern: " + error.message);
            }
        );
    }

    itemTemplate(item) {
        if( item === null ) {
            return "";
        }
        return (
            <div className="product-item">
                <div className="product-list-detail">
                    <span className="product-category">{item.name}</span>
                </div>
            </div>
        );
    }

    onChange(event) {
        this.setState({
            source: event.source,
            target: event.target
        });
    }

    getSpieler() {
        return this.state.source;
    }

    getSetzliste() {
        return this.state.target;
    }

    render() {
        const dialogFooter = (
            <div>
                <Button label="Speichern" icon="pi pi-save" onClick={() => this.save()}/>
                <Button label="Schliessen" icon="pi pi-times" onClick={() => this.props.callBackDialogClose()}
                        className="p-button-secondary"/>
            </div>
        );

        return <div>
            <Dialog baseZIndex={2000} header="Setzliste bearbeiten" visible={this.props.visible}
                    style={{width: '500px', textAlign: "left"}} footer={dialogFooter}
                    onHide={() => this.props.callBackDialogClose()}>
                <Toast ref={(el) => this.toast = el}/>
                <Messages ref={(el) => this.messages = el}/>
                <Fieldset legend="Hinweise..." style={{marginBottom:20}} toggleable={true}>
                    <div>- Hier kannst du die Setzliste der Turnierteilnehmer festlegen.</div>
                    <div>- Sofern keine Spieler gesetzt werden sollen, nimmst du natürlich keine Einstellung vor.</div>
                </Fieldset>
                <PickList source={this.getSpieler()} target={this.getSetzliste()} itemTemplate={this.itemTemplate}
                          sourceHeader="Alle Spieler" targetHeader="Setzliste"
                          sourceStyle={{ height: '342px' }} targetStyle={{ height: '342px' }}
                          onChange={this.onChange}
                          showSourceControls={false}></PickList>
            </Dialog>
        </div>
    }
}

TurnierSetzlisteKoDialog.propTypes = {
    callBackRefresh: PropTypes.func.isRequired,
    visible: PropTypes.bool.isRequired,
    tournament: PropTypes.object.isRequired,
};

export default TurnierSetzlisteKoDialog
