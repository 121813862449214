import React from "react";
import PropTypes from "prop-types";

import {Dialog} from 'primereact/dialog';
import {Button} from 'primereact/button';

class ConfirmDialog extends React.Component {

    render() {
        const footer = (
            <div>
                <Button label="Ja" icon="pi pi-check" onClick={() => this.props.callBackOnJa()} />
                <Button label="Nein" icon="pi pi-times" onClick={() => this.props.callBackOnNein()} className="p-button-secondary" />
            </div>
        );

        return <Dialog baseZIndex={2000} header={this.props.header} visible={this.props.visible}
                       style={{width: '50vw'}} footer={footer} onHide={() => this.props.callBackOnNein()} maximizable>
            <div>
                {this.props.textOben}
            </div>
            <div style={{marginTop:"20px"}}>
                {this.props.textUnten}
            </div>
        </Dialog>
    }
}


ConfirmDialog.propTypes = {
    header: PropTypes.string.isRequired,
    textOben: PropTypes.string,
    textUnten: PropTypes.string.isRequired,
    visible: PropTypes.bool.isRequired,
    callBackOnNein: PropTypes.func.isRequired,
    callBackOnJa: PropTypes.func.isRequired,
};

export default ConfirmDialog;
