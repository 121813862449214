import React, {useEffect, useRef, useState} from 'react'
import PropTypes from "prop-types";
import {NavLink} from "react-router-dom";
import {NAVIGATION_ITEM} from "../../../../constants/navigationItems";
import {Menu} from "primereact/menu";
import {PrimeIcons} from "primereact/api";
import ConfigUtil from "../../../../util/ConfigUtil";
import FetchUtil from "../../../../util/FetchUtil";
import {Messages} from "primereact/messages";
import TournamentTable from "../tournaments/TournamentTable";
import {openTournament} from "../../../../containers/views/public/PublicTournamentHelper";
import LoginDialog from "../tournaments/LoginDialog";
import PublicEventOperatorRanglisteTab from "./PublicEventOperatorRanglisteTab";
import PublicTournamentBreadCrumb from "../tournaments/PublicTournamentBreadCrumb";
import PublicEventOperatorLiveTab from "./PublicEventOperatorLiveTab";
import DHMobileDiv from "../../../general/DHMobileDiv";
import DHDesktopDiv from "../../../general/DHDesktopDiv";
import LastEventComp from "../../../LastEventComp";
import MobileMenuArea from "../../../general/MobileMenuArea";

function PublicEventoperator({eventOperatorId, isLoggedIn, tab, ranking}) {

    const [tabValue, setTabValue] = useState(tab === undefined || tab === null ? "tournament_new" : tab);
    const [eventoperatorBezeichnung, setEventoperatorBezeichnung] = useState("");
    const [tournamentsNew, setTournamentsNew] = useState([]);
    const [tournamentsOpen, setTournamentsOpen] = useState([]);
    const [tournamentsClosed, setTournamentsClosed] = useState([]);

    const [showLoginDialog, setShowLoginDialog] = React.useState(false);

    const menu = useRef(null);
    const messages = useRef(null);

    const showError = (message) => {
        messages.current.show({severity: 'error', sticky: true, summary: 'Fehler...', detail: message});
    }

    useEffect(() => {
        ladeDaten();
    }, []);

    const ladeDaten = () => {
        if (eventOperatorId === null || eventOperatorId === undefined) {
            return;
        }
        let url = ConfigUtil.getConfig().resourceUrls.public + "/eventoperator/" + eventOperatorId;
        FetchUtil.fetchGet(url,
            json => {
                setTournamentsNew(json.allTournamentsNew);
                setTournamentsOpen(json.allTournamentsStarted);
                setTournamentsClosed(json.allTournamentsClosed.reverse());
                setEventoperatorBezeichnung(json.eventoperatorBezeichnung);
            },
            responseNotOk => {
                showError("Fehler beim Laden der Daten: " + responseNotOk.message);
            },
            error => {
                showError("Fehler beim Laden der Daten: " + error.message);
            });
    }

    const getMenuItems = () => {
        let returnValue = [];
        returnValue.push({label: 'Anmeldung', icon: PrimeIcons.PLUS, command: () => setTabValue("tournament_new"), style:{borderLeft: tabValue === "anmeldung" ? "10px solid #ac1c27" : "none"}});
        returnValue.push({label: 'Laufende Turniere', icon: PrimeIcons.TH_LARGE, command: () => setTabValue("tournament_open"), style:{borderLeft: tabValue === "anmeldung" ? "10px solid #ac1c27" : "none"}});
        returnValue.push({label: 'Abgeschlossene Turniere', icon: PrimeIcons.TH_LARGE, command: () => setTabValue("tournament_closed"), style:{borderLeft: tabValue === "anmeldung" ? "10px solid #ac1c27" : "none"}});
        returnValue.push({label: 'Ranglisten', icon: PrimeIcons.CHART_BAR, command: () => setTabValue("ranking"), style:{borderLeft: tabValue === "ranking" ? "10px solid #ac1c27" : "none"}});
        returnValue.push({label: 'Live', icon: PrimeIcons.EYE, command: () => setTabValue("live"), style:{borderLeft: tabValue === "live" ? "10px solid #ac1c27" : "none"}});
        return returnValue;
    }

    const erzeugeTab = (value, key, icon, width) => {
        return <div style={{color: "white", fontSize: 20, width:width, marginLeft:5, cursor:"pointer", borderBottom: tabValue === key ? "2px solid red" : "none", textAlign:"center"}}
                    onClick={() => setTabValue(key)}>

            <i className={icon}
               style={{fontSize: 20, marginLeft: 5, marginRight: 5, color: "white", cursor: "pointer"}}/>
            {value.toUpperCase()}
        </div>
    }

    const getTabs = () => {
        let returnValue = [];
        returnValue.push(erzeugeTab("Anmeldung" ,"tournament_new", PrimeIcons.PLUS, 180));
        returnValue.push(erzeugeTab("Laufende Turniere", "tournament_open", PrimeIcons.TH_LARGE, 250));
        returnValue.push(erzeugeTab("Abgeschlossene Turniere", "tournament_closed", PrimeIcons.TH_LARGE, 350));
        returnValue.push(erzeugeTab("Ranglisten", "ranking", PrimeIcons.CHART_BAR, 200));
        returnValue.push(erzeugeTab("Live", "live", PrimeIcons.EYE, 120));
        return returnValue;
    }

    const getTabpanel = () => {
        if( tabValue === "tournament_new" ) {
            return <div style={{color:"white"}}>
                <div style={{padding:20}}>
                    <div style={{marginBottom:10}}>Hier wird recht kurzfristig die Anmeldung zu mehreren Turnieren gleichzeitig möglich sein.</div>
                    <div>Aktuell gehe bitte ins Turnier, durch Klick auf den Turniernamen, und melde dich einzeln für das Turnier an.</div>
                </div>
                {getTournamentTableArea(tournamentsNew)}
            </div>
        } else if( tabValue === "tournament_open" ) {
            return getTournamentTableArea(tournamentsOpen);
        } else if( tabValue === "tournament_closed" ) {
            return getTournamentTableArea(tournamentsClosed);
        } else if( tabValue === "ranking" ) {
            return getRanglistenArea();
        } else if( tabValue === "live" ) {
            return getLiveViewArea();
        }
    }

    const getRanglistenArea = () => {
        return <PublicEventOperatorRanglisteTab eventOperatorId={eventOperatorId} ranking={ranking}/>
    }

    const getLiveViewArea = () => {
        return <PublicEventOperatorLiveTab eventOperatorId={eventOperatorId} />
    }


    const getTournamentTableArea = (tournaments) => {
        return <>
            <div style={{padding: 5, backgroundColor: "#022836"}}>
                <TournamentTable tournaments={tournaments} showEventOperator={false}
                                 showOnline={true} showSteeldarts={true} showEDart={true}
                                 onOpenEventOperator={() => ""}
                                 onOpen={(tournament) => openTournament(tournament, isLoggedIn, () => setShowLoginDialog(true))}/>
            </div>
        </>
    }

    return <div style={{padding: 0, backgroundColor: "#022836", minHeight: "100vh", height: "100%"}}>
        <DHMobileDiv comp={
            <>
                <div>
                    <PublicTournamentBreadCrumb isMobil={true} isLiveView={false} eventOperatorId={eventOperatorId} eventOperatorBezeichnung={eventoperatorBezeichnung}/>
                </div>
                <div style={{paddingLeft: 10, backgroundColor: "white", color: "black", fontSize: "2em"}}>
                    {eventoperatorBezeichnung}
                </div>
                <div style={{display: "flex"}}>
                    <MobileMenuArea onClick={(e) => menu.current.toggle(e)}/>
                    <NavLink to={NAVIGATION_ITEM.PUBLIC_TOURNAMENTS.route} style={{textDecoration: "none"}}>
                        <img alt="Logo" src={"images/darthelfer_quer_weiss.png"} style={{height: 50, margin: 10}}/>
                    </NavLink>
                    <Menu model={getMenuItems()} popup ref={menu} style={{fontSize: 30, width: 300}}/>
                </div>
                <LastEventComp currentId={""}/>
            </>
        }/>
        <DHDesktopDiv comp={
            <>
                <div style={{width: "100%"}}>
                    <PublicTournamentBreadCrumb isMobil={false} isLiveView={false} eventOperatorId={eventOperatorId} eventOperatorBezeichnung={eventoperatorBezeichnung}/>
                </div>
                <div style={{paddingLeft: 20, marginBottom:5, backgroundColor: "white", color: "black", fontSize: "2em"}}>
                    {eventoperatorBezeichnung}
                </div>
                <LastEventComp currentId={""}/>
                <div style={{display: "flex", marginBottom:20}}>
                    {getTabs()}
                </div>
            </>
        }/>
        <Messages ref={messages}></Messages>
        {getTabpanel()}
        {showLoginDialog &&
            <LoginDialog onClose={() => setShowLoginDialog(false)}/>
        }
    </div>
}

PublicEventoperator.propTypes = {
    userId: PropTypes.string,
    isLoggedIn: PropTypes.bool.isRequired,
    eventOperatorId: PropTypes.string.isRequired,
};

export default PublicEventoperator;
