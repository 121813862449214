import React, {useEffect, useState} from "react";
import {Button} from "primereact/button";
import {Dialog} from "primereact/dialog";
import {Dropdown} from "primereact/dropdown";
import {Panel} from "primereact/panel";
import {InputNumber} from "primereact/inputnumber";
import FetchUtil from "../../../util/FetchUtil";
import ConfigUtil from "../../../util/ConfigUtil";
import {Checkbox} from "primereact/checkbox";
import ErrorInfoComponent from "../../general/ErrorInfoComponent";

export default function TournamentGroupManuellDialog({tournamentId, players, callBackOnClose}) {

    const [infoMessage, setInfoMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);

    let [spielerNurOhneZuordnung, setSpielerNurOhneZuordnung] = useState(false);
    let [groupEinteilung, setGroupEinteilung] = useState([]);

    let [anzahlGruppen, setAnzahlGruppen] = useState(1);

    const [selectedGroup, setSelectedGroup] = useState(undefined);

    useEffect(() => {
        ladeDaten();
    }, []);

    const ladeDaten = () => {
        FetchUtil.fetchGet(ConfigUtil.getConfig().resourceUrls.tournament + "/gruppenmanuell/" + tournamentId,
            json => {
                setAnzahlGruppen(json.anzahlGruppen);
                setGroupEinteilung(json.einteilungDtos);
            },
            responseNotOk => {
                setErrorMessage("Fehler beim Laden: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler beim Laden: " + error.message);
            })
    }

    const save = () => {
        setErrorMessage(undefined);
        let data = {anzahlGruppen: anzahlGruppen, einteilungDtos: groupEinteilung};
        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.tournament + "/gruppenmanuell/" + tournamentId,
            data,
            json => {
                setInfoMessage("Speichern war erfolgreich!")
                ladeDaten();
            },
            responseNotOk => {
                setErrorMessage("Fehler beim Speichern: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler beim Speichern: " + error.message);
            }
        );
    }

    const entfernen = (playerId) => {
        let neueEinteilung = groupEinteilung.filter(tmpGroup => tmpGroup.playerId !== playerId);
        setGroupEinteilung(neueEinteilung);
    }

    const hinzufuegen = (playerId) => {
        setErrorMessage(undefined);
        if( selectedGroup === undefined) {
            setErrorMessage("Gruppe muss gewählt sein.");
            return;
        }
        let neueEinteilung = groupEinteilung.slice();
        neueEinteilung.push({gruppe: selectedGroup.gruppe, playerId: playerId});
        setGroupEinteilung(neueEinteilung);
    }

    const getPlayerRow = (player) => {
        let inKeinerGruppeEnthalten = groupEinteilung.filter(tmpGroup => tmpGroup.playerId === player.id).length === 0;
        let gruppe = undefined;
        let problem = false;
        if( !inKeinerGruppeEnthalten) {
            gruppe = groupEinteilung.filter(tmpGroup => tmpGroup.playerId === player.id)[0].gruppe;
            problem = gruppe > anzahlGruppen;
        }
        if( spielerNurOhneZuordnung && gruppe !== undefined) {
            return "";
        }
        return <div key={"key_player_" + player.id} style={{marginBottom:10}}>
            {!problem && <Button icon="pi pi-plus"
                                 disabled={!inKeinerGruppeEnthalten}
                                 onClick={() => hinzufuegen(player.id)}
                                 style={{height: 30, width: 30, marginLeft: 10, marginRight: 10}}/>
            }
            {problem && <Button icon="pi pi-minus"
                                 onClick={() => entfernen(player.id)}
                                 style={{height: 30, marginLeft: 10, marginRight: 10}}/>
            }
            <span style={ {color: problem ? "red" : "black"}}>{player.name} {gruppe !== undefined && " (Gruppe: " + gruppe + ")"}</span>
            {problem && <>
                <br/> <span style={{color: "red"}}>Entfernen, Gruppe ist nicht vorhanden.</span>
            </>
            }
        </div>
    }

    const getGroupAuswahl = () => {
        let groups = [];
        if( anzahlGruppen === null || anzahlGruppen === undefined || anzahlGruppen === "") {
            return groups;
        }
        for( let i = 1 ; i <= anzahlGruppen; i++ ) {
            groups.push({gruppe:i});
        }
        return groups;
    }

    const getPlayerGruppenRows = (gruppe) => {
        let tmpPlayers = [];
        for (const player of players) {
            let enthalten = groupEinteilung.filter(tmpGroup => tmpGroup.gruppe === gruppe && tmpGroup.playerId === player.id).length !== 0;
            if (enthalten) {
                tmpPlayers.push(player);
            }
        }
        let rows = [];
        for (const player of tmpPlayers) {
            rows.push(<div key={"key_group_" + gruppe + "_player_" + player.id} style={{marginBottom:10}}>
                <Button icon="pi pi-minus"
                        onClick={() => entfernen(player.id)}
                        style={{height: 30, width: 30, marginLeft: 10, marginRight: 10}}/>
                {player.name}
            </div>)
        }
        return rows;
    }

    const getGruppen = () => {
        let gruppen = [];
        for( let i = 1 ; i <= anzahlGruppen; i++) {
            gruppen.push(<Panel key={"key_gruppe_" + i} header={"Gruppe: " + i} style={{width:200, marginLeft:10, marginBottom:10}}>
                {getPlayerGruppenRows(i)}
            </Panel>)
        }
        return gruppen;
    }

    const canSave = () => {
        for (const player of players) {
            let gruppeVorhanden = groupEinteilung.filter(tmpGroup => tmpGroup.playerId === player.id).length > 0;
            if (gruppeVorhanden) {
                let gruppe = groupEinteilung.filter(tmpGroup => tmpGroup.playerId === player.id)[0].gruppe;
                let problem = gruppe > anzahlGruppen;
                if (problem) {
                    return false
                }
            }
        }
        return true;
    }

    const dialogFooter = (
        <div>
            <Button label="Speichern" icon="pi pi-check" disabled={!canSave()} onClick={() => save()}/>
            <Button label="Abbrechen" icon="pi pi-times" onClick={() => callBackOnClose()}
                    className="p-button-secondary"/>
        </div>
    );

    return <Dialog baseZIndex={2000} header="Gruppen von Hand" visible={true}
                   style={{width: "100%", height: "100%", textAlign: "left"}} footer={dialogFooter}
                   onHide={() => callBackOnClose()}>
        <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage} onClearInfoMessage={() => setInfoMessage(undefined)} onClearErrorMessage={() => setErrorMessage(undefined)} />
        <div style={{marginBottom: 20}}>
            <span style={{marginRight: 20}}>Anzahl Gruppen:</span>
            <InputNumber min={1} style={{width: "80px", marginRight: "5px"}} value={anzahlGruppen}
                         onChange={(e) => setAnzahlGruppen(e.value)}/>
        </div>
        <div style={{marginBottom: 20}}>
            <span style={{marginRight: 20}}>Gruppe:</span>
            <Dropdown value={selectedGroup} options={getGroupAuswahl()} optionLabel="gruppe"
                      onChange={(e) => {
                          setSelectedGroup(e.value);
                      }}
                      placeholder="Eingabe..." style={{width: 150}}/>
        </div>
        <div className="grid">
            <div className="col-12 md:col-6 lg:col-3">
                <Panel header="Spielerübersicht">
                    <div style={{marginLeft:10, marginBottom:10}}>
                        <Checkbox onChange={() => setSpielerNurOhneZuordnung(!spielerNurOhneZuordnung)}
                                  checked={spielerNurOhneZuordnung}></Checkbox>
                        <span style={{marginLeft: 10}} onClick={() => setSpielerNurOhneZuordnung(!spielerNurOhneZuordnung)}>Nur Spieler ohne Zuordnung anzeigen</span>
                    </div>
                    <hr style={{marginBottom:10}}/>
                    {players.map(player => getPlayerRow(player))}
                </Panel>
            </div>
            <div className="col-12 md:col-6 lg:col-9">
                <div style={{display: "flex", flexWrap:"wrap"}}>
                    {getGruppen()}
                </div>
            </div>
        </div>
    </Dialog>
}
