import React from 'react'
import {Dialog} from "primereact/dialog";
import {Button} from "primereact/button";
import PropTypes from "prop-types";
import {Toast} from "primereact/toast";
import {Messages} from "primereact/messages";
import FetchUtil from "../../../util/FetchUtil";
import ConfigUtil from "../../../util/ConfigUtil";
import PlayerUtil from "../../../util/PlayerUtil";
import ConfirmDialog from "../../general/ConfirmDialog";
import UserSearch from "./UserSearch";

const INITAL_STATE = {
    showConfirmDelete: false,
};

class AdminsVerwaltenDialog extends React.Component {

    constructor(props) {
        super(props);

        this.state = {...INITAL_STATE};
        this.showInfo = this.showInfo.bind(this);
        this.showError = this.showError.bind(this);
        this.showWarn = this.showWarn.bind(this);
        this.removeAdmin = this.removeAdmin.bind(this);
        this.saveAsAdmin = this.saveAsAdmin.bind(this);
    }

    showInfo(message) {
        this.toast.show({
            severity: 'success',
            summary: 'Erfolgreich...',
            detail: message
        })
    }

    showWarn(message) {
        this.messages.show({
            severity: 'info',
            summary: 'Hinweis...',
            detail: message
        });
    }

    showError(message) {
        this.messages.show({
            severity: 'error',
            sticky: 'true',
            summary: 'Fehler...',
            detail: message
        });
    }

    saveAsAdmin() {
        this.messages.clear();
        let tournamentid = this.props.tournament.id;
        let userid = this.state.user.id;
        let url = ConfigUtil.getConfig().resourceUrls.tournament + "/saveadmin?tournamentid=" + tournamentid + "&userid=" + userid;
        FetchUtil.fetchPost(url,
            {},
            json => {
                this.showInfo("Admin wurde hinzugefügt");
                this.setState({user: undefined});
                this.props.callBackRefresh();
            },
            responseNotOk => {
                this.showError("Fehler beim Hinzufügen eines Admins: " + responseNotOk.message);
            },
            error => {
                this.showError("Fehler beim Hinzufügen eines Admins: " + error.message);
            }
        );

        return undefined;
    }

    removeAdmin() {
        this.messages.clear();
        let tournamentid = this.props.tournament.id;
        let userid = this.state.selectedAdmin;
        let url = ConfigUtil.getConfig().resourceUrls.tournament + "/removeadmin?tournamentid=" + tournamentid + "&userid=" + userid;
        FetchUtil.fetchPost(url,
            {},
            json => {
                this.showInfo("Admin wurde entfernt");
                this.setState({selectedAdmin: undefined, showConfirmDelete: false});
                this.props.callBackRefresh();
            },
            responseNotOk => {
                this.showError("Fehler beim Entfernen eines Admins: " + responseNotOk.message);
                this.setState({selectedAdmin: undefined, showConfirmDelete: false});
            },
            error => {
                this.showError("Fehler beim Entfernen eines Admins: " + error.message);
                this.setState({selectedAdmin: undefined, showConfirmDelete: false});
            }
        );

        return undefined;
    }

    getAdminListe() {
        let rows = [];
        for (const admin of this.props.adminis) {
            rows.push(<div key={"adminliste_key_" + admin.userId}>
                <img src="/images/delete_color.png"
                     style={{width:30, marginRight:10, marginTop:5}}
                     alt="Admin löschen"
                    onClick={() => this.setState({"selectedAdmin": admin.userId, "showConfirmDelete": true})}/>
                {admin.name}
            </div>)
        }
        return <div>
            {rows}
        </div>
    }

    render() {
        const dialogFooter = (
            <div>
                <Button label="Abbrechen" icon="pi pi-times" onClick={() => this.props.callBackCloseDialog()} className="p-button-secondary" />
            </div>
        );

        return <Dialog baseZIndex={2000} header="Turnierleiter verwalten" visible={this.props.visible}
                                   style={{width: 700, height:"100%", textAlign:"left"}} footer={dialogFooter} onHide={() => this.props.callBackCloseDialog()}>
            <Toast ref={(el) => this.toast = el}/>
            <Messages ref={(el) => this.messages = el}/>
            <div className="grid">
                <div className="col-fixed" style={{width:"120px"}}>Username:</div>
                <div className="col">
                    <UserSearch
                        callBackError={(message) => {
                                this.showError(message);
                                this.setState({user:undefined})
                            }}
                        callBackClearMessages={() => this.messages.clear()}
                                                   callBackInfo={(message) => this.showWarn(message)}
                                                    callBackUserFound={(user) => this.setState({user:user})}/></div>
            </div>
            {this.state.user !== undefined &&
            <div className="grid" style={{marginTop:5}}>
                <Button label={"Den Benutzer " + PlayerUtil.anzeigeNameZumSpieler(this.state.user) + " als Turnierleiter speichern"} icon="pi pi-save" onClick={() => this.saveAsAdmin()} />
            </div>
            }
            <h3>Turnierleiterliste</h3>
            {this.getAdminListe()}
            <ConfirmDialog visible={this.state.showConfirmDelete}
                           callBackOnNein={() => this.setState({
                               showConfirmDelete: false, selectedAdmin: undefined,
                           })}
                           callBackOnJa={this.removeAdmin}
                           header="Turnierleiter entfernen"
                           textOben=""
                           textUnten="Möchtest du den Turnierleiter wirklich entfernen?"/>

        </Dialog>
    }
}

AdminsVerwaltenDialog.propTypes = {
    tournament: PropTypes.object.isRequired,
    callBackRefresh: PropTypes.func.isRequired,
    callBackCloseDialog: PropTypes.func.isRequired,
    visible : PropTypes.bool.isRequired,
    adminis: PropTypes.array.isRequired,
};

export default AdminsVerwaltenDialog