import {connect} from 'react-redux'
import VeranstalterOverview from "../../../components/views/veranstalter/VeranstalterOverview";
import {setAsEventOperator} from "../../../actions/user";

const mapStateToProps = function (state, ownProps) {
    return {userId: state.user.id};
};

const mapDispatchToProps = dispatch => ({
    setAsEventOperator: () => dispatch(setAsEventOperator()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(VeranstalterOverview)
