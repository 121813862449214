import React, {useState} from 'react'
import {Dialog} from "primereact/dialog";
import {Button} from "primereact/button";
import {Checkbox} from "primereact/checkbox";

export default function KalenderNewEventDialog({tournamentId, onSave, onClose}) {

    let [nurAktuellesTurnier, setNurAktuellesTurnier] = useState(false);

    return <Dialog baseZIndex={2000} header="Neuer Kalendereintrag" visible={true}
                                       style={{height: 500, width: 350}}
                                       onHide={() => onClose()}>
        <div style={{textAlign:"left"}}>
            {tournamentId === undefined &&
                <div style={{marginTop:10, marginBottom: 10}}>
                    Dein Termin wird in allen Turnieren/Ligen sichtbar sein und kann überall angenommen werden.
                    Sofern du einen Termin ausschliesslich in einem Turnier/ einer Liga anbieten möchtest, setze diesen Kalendereintrag direkt im Turnier.
                </div>
            }
            {tournamentId !== undefined && <>
                <div style={{marginBottom:10, fontWeight:"bold", color:"red"}}>ACHTUNG!!!</div>
                <div style={{marginBottom:5 }}>Sobald du den Termin zum Turnier/Liga speicherst, wird dieser anderen nur in diesem Turnier/dieser Liga angezeigt.</div>
                <div style={{marginBottom:5 }}>Du bist selbst dafür verantwortlich, dass du in unterschiedlichen Turnieren/Ligen keine Terminüberschneidungen einstellst.</div>
                <div style={{marginBottom:20 }}>Eine Gesamtübersicht deiner Termine findest du auf dem Dashboard unter dem Punkt Kalender.</div>
                <div style={{marginTop:20}}>
                    <span style={{marginRight:10}}>nur am aktuellen Turnier/<br/>der aktuellen Liga anzeigen:</span><Checkbox checked={nurAktuellesTurnier}
                              onChange={() => setNurAktuellesTurnier(!nurAktuellesTurnier)}/>
                </div>
            </>
            }
            <div style={{marginTop:20}}>
                <Button label="Speichern"
                        type="button" onClick={() => onSave(nurAktuellesTurnier)} style={{marginRight:5}}/>
                <Button label="Schliessen"
                        type="button" onClick={() => onClose()}/>

            </div>
        </div>
    </Dialog>
}
