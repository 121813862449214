import React from 'react'
import {Dialog} from "primereact/dialog";
import VeranstalterScoringArea from "./VeranstalterScoringArea";
import DHDialog from "../../general/DHDialog";

export default function BoardsVerwaltenDialog({eventOperatorId, onClose}) {

    return  <DHDialog onClose={onClose} buttons={[]} title="Boards verwalten"
                      comp={<VeranstalterScoringArea eventOperatorId={eventOperatorId}/>}/>
}
