import React from "react";
import PropTypes from "prop-types";

import {Dialog} from 'primereact/dialog';
import {Button} from 'primereact/button';
import {InputText} from "primereact/inputtext";

class ConfirmKennwortDialog extends React.Component {

    render() {
        const footer = (
            <div>
                <Button label="Absenden" icon="pi pi-check" onClick={() => this.props.callBackOnAbsenden()} />
                <Button label="Abbrechen" icon="pi pi-times" onClick={() => this.props.callBackOnAbbrechen()} className="p-button-secondary" />
            </div>
        );

        return <Dialog baseZIndex={2000} header={this.props.header} visible={this.props.visible}
                       style={{width: '50vw'}} footer={footer} onHide={() => this.props.callBackOnAbbrechen()} maximizable>
            <div>
                {this.props.textOben}
            </div>
            <div style={{marginTop:20}}>
                Bitte Kennwort eingeben:
            </div>
            <div style={{marginTop:"20px"}}>
                <InputText value={this.props.kennwort} style={{width: "100%"}}
                           onChange={(e) => this.props.changeKennwort(e.target.value)}/>
            </div>
        </Dialog>
    }
}


ConfirmKennwortDialog.propTypes = {
    header: PropTypes.string.isRequired,
    textOben: PropTypes.string.isRequired,
    visible: PropTypes.bool.isRequired,
    callBackOnAbbrechen: PropTypes.func.isRequired,
    callBackOnAbsenden: PropTypes.func.isRequired,
};

export default ConfirmKennwortDialog;
