import React from 'react'
import VeranstalterVerwalten from "./VeranstalterVerwalten";
import PropTypes from "prop-types";

function VeranstalterOverview({setAsEventOperator}) {

    return <div style={{padding:20}}>
        <VeranstalterVerwalten onSave={() => setAsEventOperator()}/>
    </div>
}

VeranstalterOverview.propTypes = {
    setAsEventOperator: PropTypes.func.isRequired,
};

export default VeranstalterOverview;
