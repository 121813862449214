import React from "react";
import PropTypes from "prop-types";

import {Dialog} from 'primereact/dialog';
import {Button} from 'primereact/button';

class InfoDialog extends React.Component {

    render() {
        let rows = [];
        let counter = 0;
        for (const text of this.props.textArray) {
            counter++;
            rows.push(<div key={"info_row_" + counter} style={{marginBottom:5}}>{text}</div>);
        }

        const footer = (
            <div>
                {this.props.showExtendedButton &&
                    <Button label={this.props.extendeButtonLabel} icon={this.props.extendedButtonIcon} onClick={() => this.props.extendedButtonCallBack()} className="p-button-secondary" />
                }
                <Button label="Schliessen" icon="pi pi-times" onClick={() => this.props.callBackOnClose()} className="p-button-secondary" />
            </div>
        );

        return <Dialog baseZIndex={2000} header={this.props.header} visible={this.props.visible}
                       style={{width: '50vw'}} footer={footer} onHide={() => this.props.callBackOnClose()} maximizable>
            {rows}
        </Dialog>
    }
}


InfoDialog.propTypes = {
    header: PropTypes.string.isRequired,
    textArray: PropTypes.array.isRequired,
    visible: PropTypes.bool.isRequired,
    callBackOnClose: PropTypes.func.isRequired,
    showExtendedButton: PropTypes.bool,
    extendeButtonLabel: PropTypes.string,
    extendedButtonIcon: PropTypes.string,
    extendedButtonCallBack: PropTypes.func,
};

export default InfoDialog;
