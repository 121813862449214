import React, {useState} from 'react'
import AktionenContainer from "../../../containers/views/liga/AktionenContainer";
import EventOperatorSelection from "./dashboard/EventOperatorSelection";
import PropTypes from "prop-types";
import VeranstalterTournamentOverview from "./VeranstalterTournamentOverview";
import AutodartsConnection from "../../autodarts/AutodartsConnection";
import {removeFromLocalStorage, saveToLocalStorage} from "../../../util/localStorageZugriff";

function VeranstalterDashboard({currentEventOperator, userId, setCurrentEventOperator}) {

    let [counter, setCounter] = useState(1);

    const saveEventOperatorToLocalStorage = (eventOperator) => {
        if( eventOperator !== null && eventOperator !== undefined) {
            saveToLocalStorage("dh_eventoperator_dashboard_selected", eventOperator.id);
        } else {
            removeFromLocalStorage("dh_eventoperator_dashboard_selected");
        }
    }

    return <div>
        <div style={{padding: 10}}>
            <div className="grid">
                <EventOperatorSelection selectedEventOperator={currentEventOperator}
                                        onEventOperatorChanged={(eventOperator) => {
                    setCurrentEventOperator(eventOperator);
                    saveEventOperatorToLocalStorage(eventOperator)

                }} onRefresh={() => setCounter(counter+1)}/>
                <VeranstalterTournamentOverview userId={userId} eventOperatorId={currentEventOperator !== undefined ? currentEventOperator.id : undefined} counter={counter}/>
                <AktionenContainer isOnlineDashboard={false}/>
            </div>
        </div>
    </div>
}

VeranstalterDashboard.propTypes = {
    setCurrentEventOperator: PropTypes.func.isRequired,
    currentEventOperator: PropTypes.object
};

export default VeranstalterDashboard
