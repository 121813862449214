import React, {useState} from 'react'
import FetchUtil from "../../../../util/FetchUtil";
import ConfigUtil from "../../../../util/ConfigUtil";
import {Dialog} from "primereact/dialog";
import {Button} from "primereact/button";
import {ProgressSpinner} from "primereact/progressspinner";
import {InputText} from "primereact/inputtext";
import {PrimeIcons} from "primereact/api";

export default function ErgebnisDialog({boardGame, onClose, onSuccess, onError}) {
    const [showProgressSpinner, setShowProgressSpinner] = useState(false);
    const [anzahlPlayer1, setAnzahlPlayer1] = useState("");
    const [anzahlPlayer2, setAnzahlPlayer2] = useState("");

    const ergebnisEintragen = (tmpAnzahlPlayer1, tmpAnzahlPlayer2) => {
        setShowProgressSpinner(true);
        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.eventoperator + "/gameergebnis",
            {tournamentId: boardGame.tournamentId, tournamentGameId: boardGame.tournamentGameId, anzahlSpieler1: tmpAnzahlPlayer1, anzahlSpieler2: tmpAnzahlPlayer2},
            json => {
                onSuccess();
                setShowProgressSpinner(false);
            },
            responseNotOk => {
                onError("Fehler: " + responseNotOk.message);
                setShowProgressSpinner(false);
            },
            error => {
                onError("Fehler: " + error.message);
                setShowProgressSpinner(false);
            }
        );
    }

    return <Dialog baseZIndex={2000} header="Ergebnis eintragen" visible={true}
                   style={{minWidth:500, width: 500, height: 300, textAlign: "left"}}
                   footer={<div>
                       <Button label="Schliessen" icon="pi pi-times" onClick={() => {
                           onClose();
                       }} className="p-button-secondary"/>
                   </div>}
                   onHide={() => {
                       onClose();
                   }}>
        <div style={{fontSize:20, marginBottom:10}}>{boardGame.gameDescription}</div>

        {showProgressSpinner ?
            <ProgressSpinner style={{width: 100, height: 100}} />
            :
            <>
                <div style={{display:"flex", marginBottom:10}}>
                    <Button label={"Spieler 1 gewonnen"} onClick={() => ergebnisEintragen("1", "0")}/>
                    <Button label={"Spieler 2 gewonnen"} style={{marginLeft:10}} onClick={() => ergebnisEintragen("0", "1")}/>
                </div>

                <div style={{display:"flex"}}>
                    <InputText style={{width: 50}} value={anzahlPlayer1}
                               onChange={(e) => {
                                   if( !isNaN(e.target.value) ) {
                                       setAnzahlPlayer1(e.target.value)
                                   }
                               }}/>
                    <span style={{marginLeft:5, marginRight:5, fontSize:25}}>:</span>
                    <InputText style={{width: 50}} value={anzahlPlayer2}
                               onChange={(e) => {
                                   if( !isNaN(e.target.value) ) {
                                       setAnzahlPlayer2(e.target.value)
                                   }
                               }}/>
                    <Button icon={PrimeIcons.SAVE} label={"Speichern"} style={{marginLeft:10}} disabled={anzahlPlayer1 === "" || anzahlPlayer2 === ""} onClick={() => ergebnisEintragen(anzahlPlayer1, anzahlPlayer2)}/>
                </div>
            </>
        }
    </Dialog>
}
