import React, {useRef, useState} from 'react'
import PropTypes from "prop-types";
import {Dialog} from "primereact/dialog";
import {Button} from "primereact/button";
import FetchUtil from "../../../util/FetchUtil";
import ConfigUtil from "../../../util/ConfigUtil";
import {Messages} from "primereact/messages";
import {InputSwitch} from "primereact/inputswitch";
import {InputText} from "primereact/inputtext";
import PaypalComponent from "../../paypal/PaypalComponent";

function TournamentPayPalDialog({tournamentId, userId, playerId, price, onClose, onError, onConfirm}) {

    const [bedingungen, setBedinungen] = useState(false);
    const [gutscheinCode, setGutscheinCode] = useState("");
    const messages = useRef(null);

    const gutscheindCodeEinloesen = () => {
        clearMessages();
        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.gutschein + "/turniergutscheineinloesen/" + tournamentId + "/" + gutscheinCode,
            {},
            json => {
                onConfirm();
            },
            responseNotOk => {
                showError(responseNotOk.message);
            },
            error => {
                showError(error.message);
            })
    }

    const clearMessages = () => {
        messages.current.clear();
    }

    const showError = (message) => {
        messages.current.show({ severity: 'error', sticky: true, summary: 'Fehler...', detail: message });
    }

    return <Dialog baseZIndex={2000} header="Liga-/Turnierbezahlung" visible={true}
                   style={{width: '50vw'}} onHide={() => onClose()} maximizable>
        <Messages ref={messages}></Messages>
        <div>
            <Button label="Abbrechen" icon="pi pi-times" onClick={() => onClose()} className="p-button-secondary" />
        </div>
        <div style={{marginTop:20}}>
            <div style={{marginBottom:20, fontWeight:"bold"}}>!!! WICHTIG !!!</div>
            <div>Möchtest du die Liga-/ Turnierteilnahme wirklich bezahlen?</div>
            <div>Es handelt sich um einen Onlinekauf, eine Rückgabe deines bezahlten Betrages ist nicht möglich!!!</div>
            <div>Sobald du die Teilnahme an der Liga/ am Turnier nach einer Zahlung beendest, wird dir der Betrag nicht zurück erstattet!!!</div>
            <div>Bitte bezahle nur wenn du dir über die Teilnahme an der Liga/ am Turnier wirklich sicher bist und die die Bedingungen dafür akzeptierst!!!</div>
            <div style={{marginTop:20, marginBottom:20}}>Nach dem erfolgreichem Bezahlvorgang findest du die Rechnung unter dem Menüpunkt bezahlen!</div>
            <div style={{marginTop:20, marginBottom:20, fontWeight:"bold"}}>Du erklärst dich mit den Bedingungen des Liga-/Turnierbetreibers einverstanden. z.B. kann der Liga-/Turnierteilnehmer dich entfernen wenn du dich nicht an die Regeln gehalten hast. Auch in diesem Fall ist eine Rückerstattung des Betrags nicht möglich.</div>
            <div style={{marginTop:20, marginBottom:20, fontWeight:"bold"}}>Folgender Betrag ist für die Liga-/Turnierteilnahme fällig: {price} Euro</div>

            <div style={{marginBottom:20}}>Bist du mit diesen Bedinungen einverstanden? <InputSwitch checked={bedingungen}
                                                                           onChange={(e) => setBedinungen(!bedingungen)}/></div>
            {bedingungen && <>
                <div style={{marginBottom:20}}>Gutschein einlösen:
                    <InputText value={gutscheinCode} style={{marginLeft:10,marginRight:5, width: "200"}} onChange={(e) => setGutscheinCode(e.target.value)}/>
                    <Button label="Gutschein einlösen" style={{width:150}} type="button" onClick={() => gutscheindCodeEinloesen()} disabled={gutscheinCode === ""}/>
                </div>
                <PaypalComponent option={ "tournament" } price={ price }
                                   data={{
                                       userid: userId,
                                       playerid: playerId,
                                       tournamentid: tournamentId,
                                       betrag: price,
                                   }}
                                   onSuccess={
                                       () => onConfirm()
                                   }
                                   onResponseNotOK={
                                       () => onError()
                                   }
                                   onError={
                                       () => onError()
                                   }
                ></PaypalComponent>
            </>
            }
        </div>
    </Dialog>
}

TournamentPayPalDialog.propTypes = {
    tournamentId: PropTypes.string.isRequired,
    userId: PropTypes.string.isRequired,
    playerId: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    onClose: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
};

export default TournamentPayPalDialog;
