import React, {useEffect, useState} from 'react'
import {Button} from "primereact/button";
import {TextField} from "@mui/material/";
import FetchUtil from "../../../util/FetchUtil";
import ConfigUtil from "../../../util/ConfigUtil";
import {Dropdown} from "primereact/dropdown";
import ErrorInfoComponent from "../../general/ErrorInfoComponent";
import {TOURNAMENTSYSTEMS} from "../../../constants/tournamentSystems";

const SCHREIBER_DETAIL_TYPE = [
    {name: 'System entscheidet', code: 'systementscheidet'},
    {name: 'Gewinner', code: 'gewinner'},
    {name: 'Verlierer', code: 'verlierer'},
];

const SCHREIBERPROBLEM_TYPE = [
    {name: 'Ohne Schreiber aufrufen', code: 'ohneschreiberaufrufen'},
    {name: 'Beliebigen freien Spieler nutzen', code: 'beliebigenfreienspielernutzen'},
    {name: 'Kein Board aufrufen', code: 'keinboardaufrufen'},
];

export default function TurnierSchreiberArea({eventOperatorId}) {

    const [infoMessage, setInfoMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);

    const [tournaments, setTournaments] = useState([]);
    const [selectedTournament, setSelectedTournament] = useState(undefined);

    const [schreiberName, setSchreiberName] = useState("");
    const [schreiberListe, setSchreiberListe] = useState([]);
    const [selectedType, setSelectedType] = useState(undefined);
    const [schreiberDetailType, setSchreiberDetailTyp] = useState("systementscheidet");
    const [schreiberProblem, setSchreiberProblem] = useState("ohneschreiberaufrufen");

    useEffect(() => {
        if (eventOperatorId === null || eventOperatorId === undefined) {
            return;
        }
        ladeTournaments();

    }, [eventOperatorId]);

    useEffect(() => {
        if (selectedTournament === null || selectedTournament === undefined) {
            return;
        }
        setSelectedType(selectedTournament.schreiberType !== null ? selectedTournament.schreiberType : "keinschreiber");
        setSchreiberDetailTyp(selectedTournament.schreiberDetailType !== null ? selectedTournament.schreiberDetailType : "systementscheidet");
        setSchreiberProblem(selectedTournament.schreiberProblemType !== null ? selectedTournament.schreiberProblemType : "ohneschreiberaufrufen");
    }, [selectedTournament]);


    useEffect(() => {
        if (selectedTournament === null || selectedTournament === undefined) {
            return;
        }
        ladeSchreiber();
    }, [selectedTournament]);

    const nichtUnterstuetztesTurniersystem = () => {
        if (selectedTournament === undefined) {
            return false;
        }

        if (selectedTournament.turniersystem === TOURNAMENTSYSTEMS.ANY_GAMES.id ||
            selectedTournament.turniersystem === TOURNAMENTSYSTEMS.LIGASPIELTAG.id ||
            selectedTournament.turniersystem === TOURNAMENTSYSTEMS.WEEKENDLEAGUE.id ||
            selectedTournament.turniersystem === TOURNAMENTSYSTEMS.KRATZER.id) {
            return true;
        }
        return false;
    }

    const save = () => {
        setErrorMessage(undefined);
        const url = ConfigUtil.getConfig().resourceUrls.tournament + "/schreibersetting/" + selectedTournament.id;
        FetchUtil.fetchPost(url,
            {type:selectedType, detailType: schreiberDetailType, problemType: schreiberProblem},
            () => {
                setInfoMessage("Schreibereinstellung wurde gespeichert.");
                ladeTournaments();
            },
            responseNotOk => {
                setErrorMessage("Fehler: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler: " + error.message);
            }
        );
    }

    const deleteSchreiber = (id) => {
        setErrorMessage(undefined);
        const url = ConfigUtil.getConfig().resourceUrls.tournament + "/schreiber/" + id;
        FetchUtil.fetchDelete(url,
            {},
            () => {
                setInfoMessage("Schreiber wurde gelöscht.");
                ladeSchreiber();
            },
            responseNotOk => {
                setErrorMessage("Fehler: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler: " + error.message);
            }
        );
    }

    const changeSchreiberActive = (id) => {
        setErrorMessage(undefined);
        const url = ConfigUtil.getConfig().resourceUrls.tournament + "/schreiber/changeactive/" + id;
        FetchUtil.fetchPost(url,
            {},
            () => {
                setInfoMessage("Status Schreiber wurde geändert!");
                setSchreiberName("");
                ladeSchreiber();
            },
            responseNotOk => {
                setErrorMessage("Fehler: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler: " + error.message);
            }
        );
    }

    const saveSchreiber = () => {
        setErrorMessage(undefined);
        let data = {name: schreiberName};
        const url = ConfigUtil.getConfig().resourceUrls.tournament + "/schreiber/" + selectedTournament.id;
        FetchUtil.fetchPut(url,
            data,
            () => {
                setInfoMessage("Speichern des Schreibers war erfolgreich!");
                setSchreiberName("");
                ladeSchreiber();
            },
            responseNotOk => {
                setErrorMessage("Fehler: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler: " + error.message);
            }
        );
    }

    const ladeSchreiber = () => {
        setErrorMessage(undefined);
        FetchUtil.fetchGet(ConfigUtil.getConfig().resourceUrls.tournament + "/schreiber/" + selectedTournament.id,
            json => {
                setSchreiberListe(json);
            },
            responseNotOk => {
                setErrorMessage("Fehler beim Laden der Schreiber: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler beim Laden der Schreiber: " + error.message);
            }
        );
    }

    const ladeTournaments = () => {
        FetchUtil.fetchGet(ConfigUtil.getConfig().resourceUrls.tournament + "/tournaments/started/" + eventOperatorId,
            json => {
                setTournaments(json);
                let newSelectedTurnier;
                if (json.length === 1) {
                    newSelectedTurnier = json[0];
                } else if (json.length > 1 && selectedTournament !== undefined) {
                    newSelectedTurnier = json.filter(tournament => tournament.id === selectedTournament.id)[0];
                } else {
                    newSelectedTurnier = undefined;
                }
                setSelectedTournament(newSelectedTurnier);
                if( newSelectedTurnier !== null && newSelectedTurnier !== undefined) {
                    setSelectedType(newSelectedTurnier.schreiberType !== null ? newSelectedTurnier.schreiberType : "keinschreiber");
                }
            },
            responseNotOk => {
                setErrorMessage("Fehler beim Laden der Turniere: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler beim Laden der Turniere: " + error.message);
            }
        );
    }

    const getButton = (type) => {
        return <Button icon={selectedType === type && "pi pi-check"}
                       style={{marginRight: 10, backgroundColor: selectedType === type ? "#009f5a" : ""}}
                       onClick={() => setSelectedType(type)}/>
    }

    const getZusaetzlicherSchreiber = (schreiber) => {
        return <div style={{marginBottom: 10}}>
            <Button icon="pi pi-trash" style={{marginRight: 5}} onClick={() => deleteSchreiber(schreiber.id)}/>
            <Button icon="pi pi-check" style={{marginRight: 5}}
                    className={schreiber.active ? "p-button-primary" : "p-button-secondary"} onClick={() => changeSchreiberActive(schreiber.id)}/>
            {schreiber.name}</div>
    }

    const zusaetzlicheSchreiberArea = () => {
        return <>
            <h3 style={{marginTop: 20}}>Zusätzliche Schreiber</h3>
            <div style={{marginBottom: 10}}>
                <TextField id="standard-schreiber" label="Schreiber" variant="standard"
                           value={schreiberName}
                           onChange={(e) => setSchreiberName(e.target.value)} style={{width: 200, marginRight: 5}}/>
                <Button label="Speichern" icon="pi pi-save" disabled={schreiberName === ""} onClick={() => saveSchreiber()}/>
            </div>
            <div style={{marginTop:20, marginBottom:10, fontSize:14}}>
                Hinweis: Über den Button mit dem Haken kannst du einen Schreiber deaktivieren, sofern dieser aktuell im Turnier nicht zur Verfügung steht. (wir brauchen doch alle mal eine Pause ;-))
            </div>
            <div>
                {schreiberListe.map(schreiber => <div key={"key_schreiber_" + schreiber.id}>
                    {getZusaetzlicherSchreiber(schreiber)}
                </div>)}

            </div>
        </>
    }

    const area = () => {
        return <>
            <div style={{marginBottom: 20, fontWeight: "bold"}}>
                Hier kannst du einstellen ob du bei der automatischen Boardvergabe auch automatisch einen Schreiber
                zuweisen willst.
            </div>
            <div style={{marginBottom: 20}}>
                {getButton("keinschreiber")}Einstellung 1: Keine automatische Schreibervergabe.<br/><br/>
                Du bennenst die Schreiber selbst oder die Spieler schreiben das Spiel selbst.
            </div>
            <div style={{marginBottom: 20}}>
                {getButton("zusaetzlicheschreiber")}Einstellung 2: Ausschließlich zusätzliche Schreiber
                benutzen.<br/><br/>
                Wird nur empfohlen, wenn du genug zusätzliche Schreiber hast und mit zuwenigen Schreibern der 'schnelle'
                Turnierverlauf nicht gefährdet ist. Allgemein sollten es mit dieser Einstellung mindestens soviele
                zusätzliche Schreiber sein wie du Boards hast.
                {selectedType === "zusaetzlicheschreiber" && <>
                    {zusaetzlicheSchreiberArea()}
                    <hr/>
                </>
                }
            </div>
            <div style={{marginBottom: 20}}>
                {getButton("spieler")}Einstellung 3: Ausschließlich Schreiber aus den Spielern benutzen.
                {selectedType === "spieler" && <>
                    <div style={{marginTop:10, marginBottom:20}}>
                        <Dropdown value={getSelectedSchreiberDetailAusEnum()} options={SCHREIBER_DETAIL_TYPE} optionLabel="name"
                        onChange={(e) => setSchreiberDetailTyp(e.value.code)}
                        placeholder="Eingabe..."
                        style={{width:200}} disabled={selectedType !== "spieler"}/>
                        </div>
                        <div style={{marginBottom:5}}>
                            <span style={{fontWeight:"bold"}}>System entscheidet</span> (nur während Gruppenphase): Ein Spiel wurde gefunden, welches den Schreiber stellen kann, das System entscheidet welcher Spieler dies sein wird. Bei dieser Option werden in der Gruppenphase die Schreiber schon vor dem Boardaufruf festgelegt und angezeigt.
                        </div>
                        <div style={{marginBottom:5}}>
                            <span style={{fontWeight:"bold"}}>Gewinner:</span> Der Gewinner aus einem vergangenen Spiel wird zum Schreiber.
                        </div>
                        <div style={{marginBottom:10}}>
                            <span style={{fontWeight:"bold"}}>Verlierer:</span> Der Verlierer aus einem vergangenen Spiel wird zum Schreiber.
                        </div>
                        <div style={{marginBottom:10}}>
                            Regeln:<br/>
                            - In der Gruppenphase wird immer ein Spieler aus der eigenen Gruppe zugewiesen. Sofern kein Schreiber frei ist, wird evtl. kein Spiel vergeben.<br/>
                            - In der KO/DKO Phase schreibt immer der Verlierer aus dem Spiel davor. Sofern es kein Spiel zuvor gab,
                            z.B. Runde 1, wird aus der gleichen Runde ein Schreiber bestimmt.<br/>
                        </div>
                        <div style={{marginBottom:10, color:"red"}}>
                            Wichtig: Es kann zu Situationen kommen, bei denen kein Schreiber für ein mögliches Board ermittelt werden kann. z.B. muss der verlorene Spieler im DKO direkt wieder spielen und viele andere Situationen. In diesem Fall kann Darthelfer das Board nicht vergeben oder ohne Schreiber aufrufen. Bitte wähle deine gewünschte Einstellung dazu.
                        </div>
                        <div style={{marginBottom:10}}>
                            Diese Einstellmöglichkeit ist nur für KO/DKO Phasen vorzunehmen!
                        </div>
                        <div style={{marginBottom:10}}>
                            Wie soll bei einem Schreiberproblem vorgegangen werden?
                        </div>
                        <Dropdown value={getSelectedSchreiberProblemAusEnum()} options={SCHREIBERPROBLEM_TYPE} optionLabel="name"
                                  onChange={(e) => setSchreiberProblem(e.value.code)}
                                  placeholder="Eingabe..."
                                  style={{width:330}} disabled={selectedType !== "spieler"}/>
                    </>
                }
            </div>
            <div style={{marginTop: 20}}>
                <Button label="Speichern" icon="pi pi-save" onClick={() => save()}/>
            </div>
        </>
    }

    function getSelectedSchreiberDetailAusEnum() {
        if (schreiberDetailType === undefined || schreiberDetailType === null) {
            return {name: '', code: ''};
        }
        for (const tmp of SCHREIBER_DETAIL_TYPE) {
            if (tmp.code === schreiberDetailType) {
                return tmp;
            }
        }
        return {name: '', code: ''};
    }

    function getSelectedSchreiberProblemAusEnum() {
        if (schreiberProblem === undefined || schreiberProblem === null) {
            return {name: '', code: ''};
        }
        for (const tmp of SCHREIBERPROBLEM_TYPE) {
            if (tmp.code === schreiberProblem) {
                return tmp;
            }
        }
        return {name: '', code: ''};
    }

    return <div style={{padding: 20}}>
        <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage} onClearInfoMessage={() => setInfoMessage(undefined)} onClearErrorMessage={() => setErrorMessage(undefined)} />
        <div style={{marginBottom: 20}}>
            <span style={{marginRight: 10}}>Turnierauswahl:</span>
            <Dropdown value={selectedTournament} options={tournaments}
                      optionLabel="bezeichnung" style={{width: 300}}
                      onChange={(e) => {
                          setSelectedTournament(e.value);
                      }}
                      placeholder="Eingabe..."/>
        </div>
        {nichtUnterstuetztesTurniersystem() && <div>Das Turniersystem wird bei der Schreibervergabe nicht unterstützt.</div>}
        {!nichtUnterstuetztesTurniersystem() && <>
            {selectedTournament === undefined && <div style={{marginTop:20, fontWeight:"bold"}}>Bitte Turnier auswählen.</div>}
            {selectedTournament !== undefined && area()}
        </>
        }
    </div>
}
