import React, {useEffect, useState} from "react";

import {Dialog} from 'primereact/dialog';
import {Button} from "primereact/button";
import {PrimeIcons} from "primereact/api";
import FetchUtil from "../../../util/FetchUtil";
import ConfigUtil from "../../../util/ConfigUtil";
import {InputNumber} from "primereact/inputnumber";
import {Dropdown} from "primereact/dropdown";
import {ProgressSpinner} from "primereact/progressspinner";
import ErrorInfoComponent from "../../general/ErrorInfoComponent";

export default function RanglistenPunkteDialog({profileId, profileDetail, details, onRefresh, onClose}) {

    const REGELN = [{id: "punkte", bezeichnung:"Punkte"}];

    const [infoMessage, setInfoMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);

    const [showNewMessage, setShowNewMessage] = useState(false);
    const [inProgress, setInProgress] = useState(false);

    const [id, setId] = useState(profileDetail.id );
    const [teilnehmerBis, setTeilnehmerBis] = useState(profileDetail.teilnehmerBis !== undefined && profileDetail.teilnehmerBis !== null ? profileDetail.teilnehmerBis : 1 );
    const [platz, setPlatz] = useState(profileDetail.platz !== undefined && profileDetail.platz !== null ? profileDetail.platz : 1 );
    const [detailtype, setDetailtype] = useState(profileDetail.detailtype !== undefined && profileDetail.detailtype !== null ? REGELN.filter(regel => regel.id === profileDetail.detailtype)[0] : REGELN[0] );
    const [faktor, setFaktor] = useState(profileDetail.faktor !== undefined && profileDetail.faktor !== null ? profileDetail.faktor : 1 );

    useEffect(() => {
        if( !showNewMessage ) {
            return;
        }
        const interval = setInterval(() => {
            setShowNewMessage(false);
        }, 3000);

        return () => {
            clearInterval(interval);
        };
    }, [showNewMessage]);

    useEffect(() => {
        if( !inProgress ) {
            return;
        }
        const interval = setInterval(() => {
            setInProgress(false);
        }, 2000);

        return () => {
            clearInterval(interval);
        };
    }, [inProgress]);

    const saveProfileDetail = () => {
        setInProgress(true);
        let data = {
            id: id,
            profileId: profileId,
            teilnehmerBis: teilnehmerBis,
            platz: platz,
            detailtype: detailtype.id,
            faktor: faktor,
        }
        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.ranglisteNG + "/ranglisteprofiledetail/save",
            data,
            id => {
                onRefresh();
                setInfoMessage("Es wurde erfolgreich gespeichert.");
                if( profileDetail.id !== undefined && profileDetail.id !== null) {
                    // es handelt sich um eine Änderung, deshalb Dialog schließen
                    onClose();
                } else {
                    setPlatz(platz+1);
                    setFaktor(null);
                    setShowNewMessage(true);
                }
            },
            responseNotOk => {
                setErrorMessage("Fehler: " + responseNotOk.message);
                setInProgress(false);
            },
            error => {
                setErrorMessage("Fehler: " + error.message);
                setInProgress(false);
            })
    }

    const werteErlaubt = () => {
        let filter = details.filter(detail => detail.teilnehmerBis === teilnehmerBis && detail.platz === platz && detail.id !== id);
        return filter.length === 0;
    }

    const isSaveActive = () => {
        return teilnehmerBis !==  null && platz !== null && faktor !== null;
    }

    const footer = (
        <div>
            {inProgress && <ProgressSpinner style={{width: 30, height: 30, marginRight:20}} />}
            {!inProgress && <Button label="Hinzufügen" icon={PrimeIcons.CHECK} disabled={!isSaveActive() || !werteErlaubt()} onClick={() => saveProfileDetail()} />}
            <Button label="Schließen" icon="pi pi-times" onClick={() => onClose()} />
        </div>
    );

    return <Dialog baseZIndex={2000} header="Punkte zum Profil" visible={true}
                   style={{width: 370, textAlign: "left"}}
                   footer={footer}
                   onHide={() => onClose()}>
        <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage} onClearInfoMessage={() => setInfoMessage(undefined)} onClearErrorMessage={() => setErrorMessage(undefined)} />
        {!werteErlaubt() && <div style={{marginTop:10, marginBottom:10, fontSize:12, color:"red"}}>Für die Kombination 'Teilnehmer bis' ist der 'Platz' bereits in der Liste vorhanden.</div>}
        <div style={{marginTop: 10}}>
            Teilnehmer bis
        </div>
        <div>
            <InputNumber value={teilnehmerBis} style={{width: "100%"}}
                         onChange={(e) => setTeilnehmerBis(e.value)}/>
        </div>
        <div style={{marginTop: 20}}>
            Platz
        </div>
        <div>
            <InputNumber value={platz} style={{width: "100%"}}
                         onChange={(e) => setPlatz(e.value)}/>
        </div>
        <div style={{marginTop: 20}}>
            Regel
        </div>
        <div>
            <Dropdown value={detailtype} options={REGELN} optionLabel="bezeichnung"
                      onChange={(e) => {
                          setDetailtype(e.value);
                      }}
                      placeholder="Eingabe..." style={{width: "100%"}}/>
        </div>
        <div style={{marginTop: 20}}>
            Punkte
        </div>
        <div>
            <InputNumber value={faktor} style={{width: "100%"}}
                         onChange={(e) => setFaktor(e.value)}/>
        </div>
        {showNewMessage && <div style={{marginTop:10, marginBottom:10, fontSize:18, color:"green"}}>Du kannst direkt mit deinem nächsten Eintrag fortfahren!!!</div>}
    </Dialog>
}
