import React, {useEffect, useState} from "react";
import {ColorPicker} from "primereact/colorpicker";
import {PrimeIcons} from "primereact/api";
import {Slider} from 'primereact/slider';
import LiveTVGame from "../public/liveview/LiveTVGame";
import {Stomp} from "@stomp/stompjs";
import SockJS from "sockjs-client";
import ConfigUtil from "../../../util/ConfigUtil";
import BoardAuswahlDialog from "../veranstalter/BoardAuswahlDialog";
import {Button} from "primereact/button";
import {saveToLocalStorage,getFromLocalStorageWithDefault} from "../../../util/localStorageZugriff";

let stompClient = undefined;

export default function LigaspieltagStreamerArea({tournament}) {

    const DEFAULT_FONT_SIZE = 20;
    const DEFAULT_TEXT_ALIGN = "center";
    const DEFAULT_FONT_COLOR = "FFFFFF";
    const DEFAULT_BACKGROUND_COLOR = "022836";
    const DEFAULT_FAVORITS = [];
    const DEFAULT_SHOWAVERAGE = false;
    const DEFAULT_SHOWLASTTHROW = false;

    const LOCAL_STORAGE_FONT_SIZE = "dh_ligaspieltag_streamer_fontsize";
    const LOCAL_STORAGE_TEXT_ALIGN = "dh_ligaspieltag_streamer_textalign";
    const LOCAL_STORAGE_FONT_COLOR = "dh_ligaspieltag_streamer_fontcolor";
    const LOCAL_STORAGE_BACKGROUND_COLOR = "dh_ligaspieltag_streamer_backgroundcolor";
    const LOCAL_STORAGE_FAVORITS = "dh_ligaspieltag_streamer_favorits";
    const LOCAL_STORAGE_SHOWAVERAGE = "dh_ligaspieltag_streamer_showaverage";
    const LOCAL_STORAGE_SHOWLASTTHROW = "dh_ligaspieltag_streamer_showlastthrow";

    const [fontSize,setFontSize] = useState(getFromLocalStorageWithDefault(LOCAL_STORAGE_FONT_SIZE, DEFAULT_FONT_SIZE));
    const [textAlign,setTextALign] = useState(getFromLocalStorageWithDefault(LOCAL_STORAGE_TEXT_ALIGN, DEFAULT_TEXT_ALIGN));
    const [fontColor,setFontColor] = useState(getFromLocalStorageWithDefault(LOCAL_STORAGE_FONT_COLOR, DEFAULT_FONT_COLOR));
    const [backgroundColor,setBackgroundColor] = useState(getFromLocalStorageWithDefault(LOCAL_STORAGE_BACKGROUND_COLOR, DEFAULT_BACKGROUND_COLOR));
    const [favorits, setFavorits] = useState(getFromLocalStorageWithDefault(LOCAL_STORAGE_FAVORITS, DEFAULT_FAVORITS));
    const [liveTVShowAverage, setLiveTVShowAverage] = useState(getFromLocalStorageWithDefault(LOCAL_STORAGE_SHOWAVERAGE, DEFAULT_SHOWAVERAGE));
    const [liveTVShowLastThrow, setLiveTVShowLastThrow] = useState(getFromLocalStorageWithDefault(LOCAL_STORAGE_SHOWLASTTHROW, DEFAULT_SHOWLASTTHROW));

    const [games, setGames] = useState([]);
    const [showBoardAuswahlDialog, setShowBoardAuswahlDialog] = useState(false);

    const [refreshCounter, setRefreshCounter] = useState(21600);

    useEffect(() => {
        saveToLocalStorage(LOCAL_STORAGE_FONT_SIZE, fontSize);
    },[fontSize]);

    useEffect(() => {
        saveToLocalStorage(LOCAL_STORAGE_TEXT_ALIGN, textAlign);
    },[textAlign]);

    useEffect(() => {
        saveToLocalStorage(LOCAL_STORAGE_FONT_COLOR, fontColor);
    },[fontColor]);

    useEffect(() => {
        saveToLocalStorage(LOCAL_STORAGE_BACKGROUND_COLOR, backgroundColor);
    },[backgroundColor]);

    useEffect(() => {
        saveToLocalStorage(LOCAL_STORAGE_SHOWAVERAGE, liveTVShowAverage);
    },[liveTVShowAverage]);

    useEffect(() => {
        saveToLocalStorage(LOCAL_STORAGE_SHOWLASTTHROW, liveTVShowLastThrow);
    },[liveTVShowLastThrow]);

    useEffect(() => {
        const intervalId = setInterval(() => {
            if( refreshCounter <= 0 ) {
                setGames([])
                disconnect();
            } else {
                setRefreshCounter(refreshCounter - 1);
            }
        }, 1000);

        return () => {
            clearInterval(intervalId);
        };
    }, [refreshCounter]);

    useEffect(() => {
        stompClient = Stomp.over(function () {
            return new SockJS(ConfigUtil.getConfig().websocketUrl);
        });
        stompClient.reconnect_delay = 10000;
        // stompClient.debug = function (str) {}; // Disable debug logging
        stompClient.connect({},
            (x) => {
                console.log("Websocket - connect success");
                let destination = "/topic/public/tournament/game/infos/" + tournament.id;
                console.log("subscribe to " + destination);
                stompClient.subscribe(destination, onMessageReceived);
            },
            (x) => {
                console.log("Websocket - connect error:");
            },
            (x) => {
                console.log("Websocket - disconnect:");
            });

        return () => {
            disconnect()
        };
    }, []);


    const disconnect = () => {
        if( stompClient !== null && stompClient !== undefined) {
            console.log("Websocket - disconnect:");
            stompClient.disconnect(() => {});
            stompClient = undefined;
        }
    }

    const onMessageReceived = (payload) => {
        let payloadBody = payload.body;
        const json = JSON.parse(payloadBody);
        setGames(json.gameShortInfos);
    }

    const setDefaults = () => {
        setFontSize(DEFAULT_FONT_SIZE);
        setTextALign(DEFAULT_TEXT_ALIGN);
        setFontColor(DEFAULT_FONT_COLOR);
        setBackgroundColor(DEFAULT_BACKGROUND_COLOR);
        setLiveTVShowAverage(DEFAULT_SHOWAVERAGE);
        setLiveTVShowLastThrow(DEFAULT_SHOWLASTTHROW);
        setFavorits([]);
        saveToLocalStorage(LOCAL_STORAGE_FAVORITS, []);
    }
    const getGesamtErgebnis = () => {
        let heim = 0;
        let gast = 0;
        for (const tmpGame of tournament.tournamentLeagueGameEntities) {
            if( tmpGame.anzahlPlayer1 !== null && tmpGame.anzahlPlayer1 !== undefined ) {
                if( tmpGame.anzahlPlayer1 > tmpGame.anzahlPlayer2) {
                    heim = heim + 1;
                } else {
                    gast = gast + 1;
                }
            }
        }
        return heim + ":" + gast;
    }

    const getGamesArea = () => {
        let cols = [];
        for (const favorit of favorits) {
            let myGame = undefined;
            let myGames = games.filter(game => game.boardId === favorit.boardId);
            if (myGames.length > 0) {
                myGame = myGames[0];
            }
            let boardId = favorit.boardId;
            let boardName = favorit.boardName;
            cols.push(<div key={"key_live_view_game_fav_" + boardId}>
                <LiveTVGame game={myGame}
                            proBoardName={boardName}
                            playerIdsChecked={[]}
                            boardId={boardId}
                            favorits={favorits}
                            showAverage={liveTVShowAverage}
                            showLastThrow={liveTVShowLastThrow}
                            onLastThrow={(value) => setLiveTVShowLastThrow(value)}
                            onShowAverage={(value) => setLiveTVShowAverage(value)}
                            onFavorit={(boardId, boardName) => addFavorit(boardId, boardName)}/>
            </div>);
        }


        if( cols.length === 0 ) {
            return "";
        }

        return <div style={{padding:0, width:"100%"}}>
            <div style={{display: "flex", flexDirection: "row", flexWrap: "wrap", justifyContent: textAlign}}>
                {cols}
            </div>
        </div>
    }

    const addFavorit = (boardId, boardName) => {
        if( favorits.filter(fav => fav.boardId === boardId).length > 0) {
            let newFavorits = favorits.filter(fav => fav.boardId !== boardId);
            setFavorits(newFavorits);
            saveToLocalStorage(LOCAL_STORAGE_FAVORITS, newFavorits);
        } else {
            let newFavorits = [...favorits];
            newFavorits.push({boardId: boardId, boardName: boardName});
            setFavorits(newFavorits);
            saveToLocalStorage(LOCAL_STORAGE_FAVORITS, newFavorits);
        }
    }

    return <div>
        <div style={{padding: 20, paddingBottom:50, fontSize: fontSize, textAlign: textAlign, backgroundColor: "#" + backgroundColor, color: "#" + fontColor}}>
            <img alt="Logo" src={"images/darthelfer_quer_weiss.png"} style={{height: 50, margin: 10}}/> <br/>
            {tournament.bezeichnung} <br/>
            {getGesamtErgebnis()}
            {getGamesArea()}
        </div>
        <div style={{marginTop: 20, display: "flex"}}>
            Schriftgröße: <Slider min={10} max={100} style={{marginLeft: 10, width: 200}} value={fontSize} onChange={(e) => setFontSize(e.value)}/>
        </div>
        <div style={{marginTop: 10}}>
            Ausrichtung:
            <i className={PrimeIcons.ALIGN_LEFT} style={{fontSize: 30, cursor: "pointer", color: textAlign === "left" ? "red" : "black", marginLeft: 20, marginRight: 20}}
               onClick={() => setTextALign("left")}/>
            <i className={PrimeIcons.ALIGN_CENTER} style={{fontSize: 30, cursor: "pointer", color: textAlign === "center" ? "red" : "black", marginRight: 20}} onClick={() => setTextALign("center")}/>
            <i className={PrimeIcons.ALIGN_RIGHT} style={{fontSize: 30, cursor: "pointer", color: textAlign === "right" ? "red" : "black", marginRight: 20}} onClick={() => setTextALign("right")}/>
        </div>
        <div style={{marginTop: 10}}>
            Hintergrundfarbe: <ColorPicker format="hex" value={backgroundColor} onChange={(e) => setBackgroundColor(e.value)}/>
        </div>
        <div style={{marginTop: 10}}>
            Schriftfarbe: <ColorPicker format="hex" value={fontColor} onChange={(e) => setFontColor(e.value)}/>
        </div>
        <div style={{marginTop: 10}}>
            <Button label="Boardauswahl" onClick={() => setShowBoardAuswahlDialog(true)}/>
        </div>
        <div style={{marginTop: 30}}>
            <Button label="Defaultwerte setzen" onClick={() => setDefaults()}/>
        </div>
        <div style={{marginTop: 30}}>
            Boardbeobachtung disconnect in {refreshCounter} Sekunden.
        </div>
        {showBoardAuswahlDialog &&
            <BoardAuswahlDialog eventOperatorId={tournament.eventOperatorId}
                                onSelection={(boardId, boardName) => {
                                    addFavorit(boardId, boardName);
                                }}
                                favorits={favorits}
                                onDelete={(boardId) => addFavorit(boardId, "")}
                                onClose={() => setShowBoardAuswahlDialog(false)}/>}

    </div>
}