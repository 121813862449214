import React, {useEffect, useRef, useState} from 'react'
import {Dialog} from "primereact/dialog";
import {TextField} from "@mui/material/";
import {Button} from "primereact/button";
import UserSearch from "../liga/UserSearch";
import Description from "./Description";
import ConfigUtil from "../../../util/ConfigUtil";
import FetchUtil from "../../../util/FetchUtil";
import VeranstalterBezahlung from "./VeranstalterBezahlung";
import {PrimeIcons} from "primereact/api";
import {Menu} from "primereact/menu";
import ErrorInfoComponent from "../../general/ErrorInfoComponent";
import DHMobileDiv from "../../general/DHMobileDiv";
import DHDesktopDiv from "../../general/DHDesktopDiv";
import ConfirmWithQuestionDialog from "../../general/ConfirmWithQuestionDialog";

function AdminRow({admin, onDelete}) {
    const [showDeleteMessage, setShowDeleteMessage] = useState(false);
    return <div className="grid" key={"key_admin_" + admin.id} style={{alignItems: "center", display: "flex"}}>
        <div className="col-fixed" style={{width: 50}}>
            <Button icon="pi pi-trash" tooltip="Löschen"
                    style={{marginRight: "5px", marginTop: "5px"}}
                    onClick={() => setShowDeleteMessage(true)}/>
        </div>
        <div className="col">
            {admin.username}
        </div>
        <div className="col">
            {admin.vorname}, {admin.name}
        </div>
        <div className="col">
            {admin.telefon}
        </div>
        <div className="col">
            {admin.email}
        </div>
        {showDeleteMessage &&
            <ConfirmWithQuestionDialog  header="Löschen" textOben="Möchtest du den Admin wirklich löschen?" visible={true}
                                        callBackOnNein={() => setShowDeleteMessage(false)} callBackOnJa={() => {
                                            onDelete(admin.userId);
                                            setShowDeleteMessage(false);
            }}/>
        }
    </div>
}

export default function VeranstalterVerwaltenDialog({eventOperatorID, onClose, onSave}) {

    const [tabValue, setTabValue] = React.useState("veranstalterdaten");

    const menu = useRef(null);

    let [id, setId] = useState(eventOperatorID);
    let [proVeranstalter, setProVeranstalter] = useState(false);
    let [name, setName] = useState("");
    let [homepage, setHomepage] = useState("");
    let [socialmedia, setSocialmedia] = useState("");
    let [austragungsortBezeichnung, setAustragungsortBezeichnung] = useState("");
    let [austragungsortStrasse, setAustragungsortStrasse] = useState("");
    let [austragungsortPLZ, setAustragungsortPLZ] = useState("");
    let [austragungsortOrt, setAustragungsortOrt] = useState("");
    let [email, setEmail] = useState("");
    let [telefon, setTelefon] = useState("");
    let [admins, setAdmins] = useState([]);
    let [eventOperatorToken, setEventOperatorToken] = useState(undefined);
    let [newAdminUser, setNewAdminUser] = useState(undefined);

    const [infoMessage, setInfoMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);

    useEffect(() => {
        if (eventOperatorID === undefined) {
            return;
        }
        ladeVeranstalter();
    }, [eventOperatorID]);

    const getTabs = () => {
        let returnValue = [];
        returnValue.push(erzeugeTab("Veranstalterdaten" ,"veranstalterdaten", false));
        returnValue.push(erzeugeTab("Austragungsort" ,"austragungsort", false));
        returnValue.push(erzeugeTab("Admins" ,"admins", id === null || id === undefined));
        returnValue.push(erzeugeTab("Bezahlung" ,"bezahlung", id === null || id === undefined));
        return returnValue;
    }

    const erzeugeTab = (value, key, icon) => {
        return <div style={{fontSize: 16, paddingLeft:10, paddingRight:10, cursor:"pointer", borderBottom: tabValue === key ? "2px solid red" : "none", textAlign:"center"}}
                    onClick={() => setTabValue(key)}>
            {value.toUpperCase()}
        </div>
    }

    const getMenuItems = () => {
        let returnValue = [];
        returnValue.push({label: 'Veranstalterdaten', icon: PrimeIcons.PLUS, command: () => setTabValue("veranstalterdaten"), style:{borderLeft: tabValue === "veranstalterdaten" ? "10px solid #ac1c27" : "none"}});
        returnValue.push({label: 'Austragungsort', icon: PrimeIcons.PLUS, command: () => setTabValue("austragungsort"), style:{borderLeft: tabValue === "austragungsort" ? "10px solid #ac1c27" : "none"}});
        returnValue.push({label: 'Admins', icon: PrimeIcons.PLUS, command: () => setTabValue("admins"), style:{borderLeft: tabValue === "admins" ? "10px solid #ac1c27" : "none"}});
        returnValue.push({label: 'Bezahlung', icon: PrimeIcons.PLUS, command: () => setTabValue("bezahlung"), style:{borderLeft: tabValue === "bezahlung" ? "10px solid #ac1c27" : "none"}});
        return returnValue;
    }

    const getTabAustragungsort = () => {
        return <div>
            <h3 style={{marginBottom:20}}>Austragungsort</h3>
            <Description
                message="Hier können Daten zum Austragungsort eingetragen werden. Diese werden in den Turnieren und Ligen als Standard vorbelegt. Natürlich können die Daten bei der Turnier-/Ligaanlage noch überschrieben werden."/>
            <div>
                <TextField id="standard-name" label="Bezeichnung Austragungsort" variant="standard"
                           value={austragungsortBezeichnung}
                           onChange={(e) => setAustragungsortBezeichnung(e.target.value)} style={{width: "100%"}}/>
            </div>
            <div className="grid" style={{marginTop: 10}}>
                <div className="col">
                    <TextField id="standard-name" label="Straße" variant="standard" value={austragungsortStrasse}
                               onChange={(e) => setAustragungsortStrasse(e.target.value)} style={{width: "100%"}}/>
                </div>
            </div>
            <div className="grid" style={{marginTop: 10}}>
                <div className="col-fixed" style={{width: 200}}>
                    <TextField id="standard-name" label="PLZ" variant="standard" value={austragungsortPLZ}
                               onChange={(e) => setAustragungsortPLZ(e.target.value)} style={{width: "100%"}}/>
                </div>
                <div className="col">
                    <TextField id="standard-name" label="Ort" variant="standard" value={austragungsortOrt}
                               onChange={(e) => setAustragungsortOrt(e.target.value)} style={{width: "100%"}}/>
                </div>
            </div>
        </div>
    }

    const getTabAdmins = () => {
        return <div>
            <h3 style={{marginBottom:20}}>Admins</h3>
            <Description
                message="Hier kannst du Administratoren verwalten. Gemeinsam könnt ihr dann an euren Veranstaltungen arbeiten und diese zusammen verwalten."/>
            <Description message="Für neue Admins benötigst du den Usernamen der Person hier auf Darthelfer. Bitte
                    suche nach dieser Person und speichere diese dann als Admin."/>
            <div>
            </div>
            {(id === null || id === undefined) &&
                <div style={{marginTop: 30, marginBottom: 20}}>
                    Um weitere Admins speichern zu können, musst du den Veranstalter initial gespeichert haben.
                </div>
            }
            {id !== null && id !== undefined &&
            <div style={{marginTop: 30, marginBottom: 20}}>
                <UserSearch
                callBackError={(message) => {
                    setErrorMessage(message);
                    setNewAdminUser(undefined);
                }}
                callBackClearMessages={() => setErrorMessage(undefined)}
                callBackUserFound={(user) => setNewAdminUser(user)}
                shortSearchButton={true}/>
                {newAdminUser !== undefined &&
                    <Button label="Speichern" icon="pi pi-times" onClick={() => saveAdmin()}
                            className="p-button-primary"/>}
            </div>
            }
            <Description message="Hinweis: Du wirst beim initialen Speichern automatisch als Admin angelegt!"/>
            {admins.map(admin => <AdminRow admin={admin} onDelete={(userId) => deleteAdmin(userId)}/>)}
        </div>
    }
    const getTabVeranstalterdaten = () => {
        return <div>
            <h3 style={{marginBottom:20}}>Veranstalterdaten</h3>
            <div style={{marginTop: 10}}>
                <TextField id="standard-name" label="Name des Veranstalters" variant="standard" value={name}
                           onChange={(e) => setName(e.target.value)} style={{width: "100%"}}/>
            </div>
            <div style={{marginTop: 10}}>
                <TextField id="standard-homepage" label="Homepagelink des Veranstalters" variant="standard"
                           value={homepage}
                           onChange={(e) => setHomepage(e.target.value)} style={{width: "100%"}}/>
            </div>
            <div style={{marginTop: 10}}>
                <TextField id="standard-socialmedia" label="Socialmedialink des Veranstalters" variant="standard"
                           value={socialmedia}
                           onChange={(e) => setSocialmedia(e.target.value)} style={{width: "100%"}}/>
            </div>
            <div style={{marginTop: 10}}>
                <TextField id="standard-name" label="Telefon" variant="standard" value={telefon}
                           onChange={(e) => setTelefon(e.target.value)} style={{width: "100%"}}/>
            </div>
            <div style={{marginTop: 10}}>
                <TextField id="standard-name" label="Email" variant="standard" value={email}
                           onChange={(e) => setEmail(e.target.value)} style={{width: "100%"}}/>
            </div>
        </div>
    }
    const getTabpanel = () => {
        if (tabValue === "veranstalterdaten") {
            return getTabVeranstalterdaten();
        } else if (tabValue === "austragungsort") {
            return getTabAustragungsort();
        } else if (tabValue === "admins") {
            return getTabAdmins();
        } else if (tabValue === "bezahlung") {
            return <div>
                <h3 style={{marginBottom:20}}>Bezahlung</h3>
                <VeranstalterBezahlung eventOperatorId={id}/>
            </div>
        }
        return "";
    }

    const ladeVeranstalter = () => {
        FetchUtil.fetchGet(ConfigUtil.getConfig().resourceUrls.eventoperator + "/byId/" + id,
            json => {
                setId(json.id);
                setProVeranstalter(json.proVeranstalter);
                setName(json.name);
                setHomepage(json.homepage);
                setSocialmedia(json.socialmedia);
                setAustragungsortBezeichnung(json.austragungsortBezeichnung);
                setAustragungsortStrasse(json.austragungsortStrasse);
                setAustragungsortPLZ(json.austragungsortPLZ);
                setAustragungsortOrt(json.austragungsortOrt);
                setEmail(json.email);
                setTelefon(json.telefon);
                setAdmins(json.admins);
                setEventOperatorToken(json.token !== null ? json.token : undefined);
            },
            responseNotOk => {
                setErrorMessage("Fehler: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler: " + error.message);
            }
        );
    }

    const deleteAdmin = (userId) => {
        setErrorMessage(undefined);
        let url = ConfigUtil.getConfig().resourceUrls.eventoperator + "/deleteadmin/" + id + "/" + userId;
        FetchUtil.fetchPost(url,
            {},
            json => {
                setInfoMessage("Admin wurde erfolgreich gelöscht");
                ladeVeranstalter();
            },
            responseNotOk => {
                setErrorMessage("Fehler beim Speichern: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler beim Speichern: " + error.message);
            }
        );

    }

    const saveAdmin = () => {
        setErrorMessage(undefined);
        let url = ConfigUtil.getConfig().resourceUrls.eventoperator + "/saveadmin/" + id + "/" + newAdminUser.id;
        FetchUtil.fetchPost(url,
            {},
            json => {
                setInfoMessage("Admin wurde erfolgreich hinzugefügt");
                ladeVeranstalter();
                setNewAdminUser(undefined);
            },
            responseNotOk => {
                setErrorMessage("Fehler beim Speichern: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler beim Speichern: " + error.message);
            }
        );

    }

    const save = () => {
        setErrorMessage(undefined);

        let data = {
            id: id,
            name: name,
            socialmedia: socialmedia,
            homepage: homepage,
            austragungsortBezeichnung: austragungsortBezeichnung,
            austragungsortStrasse: austragungsortStrasse,
            austragungsortPLZ: austragungsortPLZ,
            austragungsortOrt: austragungsortOrt,
            email: email,
            telefon: telefon
        };
        let url = ConfigUtil.getConfig().resourceUrls.eventoperator + "/save";
        FetchUtil.fetchPost(url,
            data,
            json => {
                setId(json.id);
                setInfoMessage("Es wurde erfolgreich gespeichert");
                onSave();
            },
            responseNotOk => {
                setErrorMessage("Fehler beim Speichern: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler beim Speichern: " + error.message);
            }
        );
    }

    const footerContent = () => {
        return <div>
            <Button label="Speichern" icon="pi pi-times" onClick={() => save()} className="p-button-primary"/>
            <Button label="Schliessen" icon="pi pi-times" onClick={() => onClose()} className="p-button-secondary"/>
        </div>
    }

    return <Dialog baseZIndex={2000} header="Veranstalter verwalten" visible={true}
                   style={{height: 700, width: "100%", maxWidth:800}}
                   onHide={() => onClose()}
                   footer={footerContent}>
        <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage} onClearInfoMessage={() => setInfoMessage(undefined)} onClearErrorMessage={() => setErrorMessage(undefined)} />
        <DHMobileDiv comp={
            <div>
                <i className="pi pi-bars" style={{margin:10, fontSize:40}} onClick={(e) => menu.current.toggle(e)} />
                <Menu model={getMenuItems()} popup ref={menu} style={{fontSize:30, width:300}}/>
            </div>
        }/>
        <DHDesktopDiv comp={
            <div style={{marginTop:10, marginBottom:20}}>
                <div style={{display:"flex"}}>
                    {getTabs()}
                </div>
            </div>
        }/>
        {getTabpanel()}
    </Dialog>
}
