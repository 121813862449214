import React from 'react'
import {Messages} from "primereact/messages";
import {Dialog} from "primereact/dialog";
import {Toast} from "primereact/toast";
import PropTypes from 'prop-types';
import {InputText} from "primereact/inputtext";
import {Password} from "primereact/password";
import {Button} from "primereact/button";
import FetchUtil from "../util/FetchUtil";
import ConfigUtil from "../util/ConfigUtil";


const initialState = {
    value: "",
    code: "",
    phase: 1,
};

class KennwortVergessenDialog extends React.Component {

    constructor(props) {
        super(props);

        this.state = initialState;

        this.showError = this.showError.bind(this);
        this.showInfo = this.showInfo.bind(this);
    }

    showInfo(message) {
        this.messages.show({
            sticky: true,
            severity: 'info',
            summary: 'Information...',
            detail: message
        });
    }

    showError(message) {
        this.messages.show({
            sticky: true,
            severity: 'error',
            summary: 'Fehler...',
            detail: message
        });
    }

    handelKeyDown(e) {
        if (e.keyCode === 13) {
            // enter
            if( this.state.phase === 1) {
                this.postPhase1();
            }
            else if( this.state.phase === 2) {
                this.postPhase2();
            } if( this.state.phase === 3) {
                this.postPhase3();
            }

        }
    }

    postPhase3() {
        this.messages.clear();
        let date = {password1: this.state.password1, password2: this.state.password2};
        let url = ConfigUtil.getConfig().resourceUrls.user + "/kennwortvergessenchangepassword?code=" + this.state.code;
        FetchUtil.fetchPost(url,
            date,
            json => {
                this.setState({phase: 4});
            },
            responseNotOk => {
                this.showError("Fehler: " + responseNotOk.message);
            },
            error => {
                this.showError("Fehler: " + error.message);
            }
        );
    }

    postPhase2() {
        this.messages.clear();
        let url = ConfigUtil.getConfig().resourceUrls.user + "/kennwortvergessencodeueberpruefen?code=" + this.state.code;
        FetchUtil.fetchPost(url,
            {},
            json => {
                this.setState({phase: 3});
            },
            responseNotOk => {
                this.showError("Fehler: " + responseNotOk.message);
            },
            error => {
                this.showError("Fehler: " + error.message);
            }
        );
    }

    postPhase1() {
        this.messages.clear();
        let url = ConfigUtil.getConfig().resourceUrls.user + "/kennwortvergessencodeanfordern?param=" + this.state.value;
        FetchUtil.fetchPost(url,
            {},
            json => {
                this.setState({phase: 2});
            },
            responseNotOk => {
                this.showError("Fehler: " + responseNotOk.message);
            },
            error => {
                this.showError("Fehler: " + error.message);
            }
        );
    }

    checkInput() {
        let alleFelderGefuellt = this.state.name !== "" && this.state.firstname !== "" && this.state.username !== "" &&
            this.state.email !== "" && this.state.password1 !== "" && this.state.password2 !== "";
        return alleFelderGefuellt;
    }

    getPhase2Area() {
        if (this.state.phase !== 2) {
            return "";
        }
        return <div>
            <h1>Überprüfung deiner Identität</h1>
            Aus Sicherheitsgründen müssen wir deine Identität überprüfen. Sofern wir zu deinen Daten ein Konto gefunden haben, haben wir dir einen Code an deine Emailadresse geschickt. Gib diesen bitte unten ein.
            <br/><br/>Code eingeben
            <div className="col-12 md:col-12 lg:col-8">
                <div className="grid">
                    <div className="col-12 md:col-6 lg:col-12" style={{marginTop: 10}}>
                        <InputText value={this.state.code} style={{width: "100%"}}
                                   placeholder="Code"
                                   onChange={(e) => this.setState({code: e.target.value})}
                                   onKeyDown={(e) => this.handelKeyDown(e)}/>
                    </div>
                    <div className="col-12 md:col-6 lg:col-12" style={{marginTop: 5}}>
                        <Button label="Weiter"
                                icon="pi pi-key"
                                disabled={!this.state.code}
                                type="button" onClick={() => this.postPhase2()} style={{marginRight: 5}}/>

                    </div>
                </div>
            </div>
            Sofern du keine Mail erhalten hast (prüfe bitte auch den SPAM Ordner), nimm bitte Kontakt mit unserem Support auf.
        </div>
    }

    getPhase4Area() {
        if (this.state.phase !== 4) {
            return "";
        }
        return <div>
            <h1>Super...</h1>
            Dein Kennwort wurde geändert, du kannst dich nun mit deinem Benutzernamen und deinem Kennwort anmelden.
            <br/>
            <br/>
            Sofern du weiter Probleme hast, nimm Kontakt mit unserem Support auf.
        </div>
    }

    getPhase3Area() {
        if (this.state.phase !== 3) {
            return "";
        }
        return <div>
            <h1>Neues Passwort erstellen</h1>
            Gib hier deine E-Mail-Adresse oder deinen Benutzernamen ein, die/das mit deinem darthelfer-Konto verbunden
            ist.
            <div className="col-12 md:col-12 lg:col-8">
                <div className="grid">
                    <div className="col-12 md:col-6 lg:col-12" style={{marginTop: 10}}>
                        <Password value={this.state.password1} style={{width: "100%"}}
                                  placeholder="Kennwort"
                                  onChange={(e) => this.setState({password1: e.target.value})}
                                  onKeyDown={(e) => this.handelKeyDown(e)}
                                  feedback={true}/>
                    </div>
                    <div className="col-12 md:col-6 lg:col-12" style={{marginTop: 10}}>
                        <Password value={this.state.password2} style={{width: "100%"}}
                                  placeholder="Kennwort wiederholen"
                                  onChange={(e) => this.setState({password2: e.target.value})}
                                  onKeyDown={(e) => this.handelKeyDown(e)}
                                  feedback={false}/>
                    </div>
                    <div className="col-12 md:col-6 lg:col-12" style={{marginTop: 5}}>
                        <Button label="Weiter"
                                icon="pi pi-key"
                                disabled={!this.state.password1 && !this.state.password2}
                                type="button" onClick={() => this.postPhase3()} style={{marginRight: 5}}/>

                    </div>
                </div>
            </div>
        </div>
    }

    getPhase1Area() {
        if( this.state.phase !== 1) {
            return "";
        }
        return <div>
            <h1>Passworthilfe</h1>
            Gib hier deine E-Mail-Adresse oder deinen Benutzernamen ein, die/das mit deinem darthelfer-Konto verbunden
            ist.
            <div className="col-12 md:col-12 lg:col-8">
                <div className="grid">
                    <div className="col-12 md:col-6 lg:col-12" style={{marginTop: 10}}>
                        <InputText value={this.state.username} style={{width: "100%"}}
                                   placeholder="Benutzername oder Emailadresse"
                                   onChange={(e) => this.setState({value: e.target.value})}
                                   onKeyDown={(e) => this.handelKeyDown(e)}/>
                    </div>
                    <div className="col-12 md:col-6 lg:col-12" style={{marginTop: 5}}>
                        <Button label="Weiter"
                                icon="pi pi-key"
                                disabled={!this.state.value}
                                type="button" onClick={() => this.postPhase1()} style={{marginRight: 5}}/>

                    </div>
                </div>
            </div>
        </div>
    }

    closeDialog() {
        this.setState(initialState);
        this.props.onClose();
    }

    render() {

        return <Dialog baseZIndex={2000}  header="Kennwort vergessen" visible={this.props.visible}
                       style={{width: '500px', textAlign: "left"}}
                       onHide={() => this.closeDialog()}>
            <Toast ref={(el) => this.toast = el}/>
            <Messages ref={(el) => this.messages = el}/>
            <div className="col-12 md:col-12 lg:col-12">
                <div className="grid">
                    <div className="col">
                        <Button label="Abbrechen/Schliessen"
                                type="button" onClick={() => this.props.onClose()}/>
                    </div>
                </div>
                {this.getPhase1Area()}
                {this.getPhase2Area()}
                {this.getPhase3Area()}
                {this.getPhase4Area()}
            </div>
        </Dialog>
    }
}

KennwortVergessenDialog.propTypes = {
    visible: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default KennwortVergessenDialog;