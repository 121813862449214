import React from 'react'
import PropTypes from "prop-types";
import InfoDialog from "../../general/InfoDialog";
import StatisticGameCricket from "./StatisticGameCricket";
import StatistikGameDialog from "../public/tournaments/StatistikGameDialog";

function StatisticGameDetailDialog({callBackDialogClose, visible, gameId, gameType}) {

    if( gameType === "x01") {
        return <StatistikGameDialog gameId={gameId} onClose={callBackDialogClose}/>
    } else if( gameType === "cricket") {
        return <StatisticGameCricket callBackDialogClose={callBackDialogClose} visible={visible} gameId={gameId}/>
    } else {
        return <InfoDialog header="Information"
                           textArray={["GameType " + gameType + " wird aktuell nicht unterstützt."]}
                           visible={visible} callBackOnClose={callBackDialogClose}/>
    }
}

StatisticGameDetailDialog.propTypes = {
    callBackDialogClose: PropTypes.func.isRequired,
    visible: PropTypes.bool.isRequired,
    gameId: PropTypes.string.isRequired,
    gameType: PropTypes.string.isRequired,
};

export default StatisticGameDetailDialog
