import React, {useState} from 'react';
import PropTypes from "prop-types";
import {Dialog} from "primereact/dialog";
import {Button} from "primereact/button";
import ErrorInfoComponent from "../../../general/ErrorInfoComponent";

function ScoringCricketChangeDialog({zahlen, scores, callBackOnClose, onSave}) {

    const [infoMessage, setInfoMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);

    let [aufnahmeQualifier, setAufnahmeQualifier] = useState(undefined);
    let [aufnahme, setAufnahme] = useState(undefined);
    let [selectedAufnahmeID, setSelectedAufnahmeID] = useState(undefined);

    const getQualifier = () => {
        let rows = [];
        let buttonSingle = <Button
            className={aufnahmeQualifier !== "S" ?"p-button-secondary" : ""}
            label="S" style={{width:150, margin:5}} type="button"
                                   onClick={() => setAufnahmeQualifier("S")}/>
        rows.push(buttonSingle);
        let buttonDouble = <Button
            className={aufnahmeQualifier !== "D" ?"p-button-secondary" : ""} label="D" style={{width:150, margin:5}} type="button" onClick={() => setAufnahmeQualifier("D")}/>
        rows.push(buttonDouble);
        let buttonTriple = <Button
            className={aufnahmeQualifier !== "T" ?"p-button-secondary" : ""} label="T" style={{width:150, margin:5}} type="button" onClick={() => setAufnahmeQualifier("T")}/>
        rows.push(buttonTriple);
        return rows;
    }

    const getZahlen = () => {
        let rows = [];
        let button0 = <Button
            className={aufnahme !== "0" ?"p-button-secondary" : ""} label="0" style={{width:150, margin:5}} type="button"
            onClick={() => {
                setAufnahme("0");
                setAufnahmeQualifier("S");
            }}/>
        rows.push(button0);
        for (const zahlenElement of zahlen) {
            let button = <Button
                className={aufnahme !== zahlenElement ?"p-button-secondary" : ""} label={zahlenElement} style={{width:150, margin:5}} type="button" onClick={() => setAufnahme(zahlenElement)}/>
            rows.push(button);
        }
        return rows;
    }

    const getRows = () => {
        let rows = [];
        let aufnahme = Math.max(...scores.map(o => o.aufnahme));
        while (aufnahme !== 0) {
            let tmpScores = scores.filter(value => value.aufnahme === aufnahme);
            if( tmpScores.length === 0) {
                break;
            }
            rows.push(<div className="grid" style={{fontSize:16, marginTop:10}}>
                <div className="col-fixed" style={{width:150}}>Aufnahme: {aufnahme}</div>
                <div className="col-fixed"
                     style={{width:50, cursor:"pointer", background: selectedAufnahmeID === tmpScores[0].id ? "yellow" : ""}}
                    onClick={() => setSelectedAufnahmeID(tmpScores[0].id)}>{tmpScores[0].zahl}</div>
                <div className="col-fixed"
                     style={{width:50, cursor:"pointer", background: selectedAufnahmeID === tmpScores[1].id ? "yellow" : ""}}
                     onClick={() => setSelectedAufnahmeID(tmpScores[1].id)}>{tmpScores[1].zahl}</div>
                <div className="col-fixed"
                     style={{width:50, cursor:"pointer", background: selectedAufnahmeID === tmpScores[2].id ? "yellow" : ""}}
                     onClick={() => setSelectedAufnahmeID(tmpScores[2].id)}>{tmpScores[2].zahl}</div>
            </div>);
            aufnahme = aufnahme - 1;
        }
        return rows;
    }

    return <Dialog baseZIndex={2000} header="Scores (ändern)" visible={true}
                   style={{width: '40vw'}} onHide={() => callBackOnClose()} maximizable>
        <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage} onClearInfoMessage={() => setInfoMessage(undefined)} onClearErrorMessage={() => setErrorMessage(undefined)} />
        <div style={{marginBottom:20}}>Bitte wähle in den Scores welchen du ändern willst, weiter wähle welchen Wert du dafür speichern möchtest.</div>
        <div style={{marginBottom:20}}>Gewählte Aufnahme zur Änderung: <span style={{fontSize:25}}>{aufnahmeQualifier}{aufnahme}</span>
            <Button label="Speichern"
                    disabled={selectedAufnahmeID === undefined || aufnahme === undefined || aufnahmeQualifier === undefined}
                    type="button" onClick={() => {
                        setErrorMessage(undefined);
                        if(aufnahme === "0" && (aufnahmeQualifier === "D" || aufnahmeQualifier === "T" )) {
                            setErrorMessage("Bei Aufnahme 0 ist D oder T nicht erlaubt.");
                            return;
                        }
                        if(aufnahme === "25" && aufnahmeQualifier === "T" ) {
                            setErrorMessage("Aufnahme T25 ist nicht erlaubt.");
                            return;
                        }
                        onSave(selectedAufnahmeID, aufnahmeQualifier + aufnahme);
                        callBackOnClose();
            } } style={{marginLeft:10}}/>
        </div>
        {getQualifier()}
        <br/>
        {getZahlen()}
        {getRows()}
    </Dialog>

}

ScoringCricketChangeDialog.propTypes = {
    scores: PropTypes.array.isRequired,
    zahlen: PropTypes.array.isRequired,
    callBackOnClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
};

export default ScoringCricketChangeDialog;
