import React from 'react'
import {Dialog} from "primereact/dialog";
import StatistikComponent from "./StatistikComponent";
import {Sidebar} from "primereact/sidebar";
import {Button} from "primereact/button";



export default function StatistikDialog({name, statistik, showPlayerGesamtStatistik, onClose}) {

    return <Sidebar visible={true} style={{minWidth: 380}} fullScreen onHide={() => onClose()}
                    position="left" showCloseIcon={false}>
        <div style={{position: "absolute", top: 5, left: 5, zIndex: 500}}><Button onClick={() => onClose()}
                                                                                  label="Bereich schliessen"/></div>
        <StatistikComponent name={name} showPlayerGesamtStatistik={showPlayerGesamtStatistik} statistik={statistik}/>
    </Sidebar>
}
