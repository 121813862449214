import React from 'react'
import {Messages} from "primereact/messages";
import {Dialog} from "primereact/dialog";
import {Toast} from "primereact/toast";
import PropTypes from 'prop-types';
import {InputText} from "primereact/inputtext";
import {Password} from "primereact/password";
import {Button} from "primereact/button";
import FetchUtil from "../util/FetchUtil";
import ConfigUtil from "../util/ConfigUtil";
import {Checkbox} from "primereact/checkbox";
import {NavLink} from "react-router-dom";
import {NAVIGATION_ITEM} from "../constants/navigationItems";


const initialState = {
    email: "",
    username: "",
    name: "",
    firstname: "",
    password1: "",
    password2: "",
    saving: false,
};

class RegistrierenDialog extends React.Component {

    constructor(props) {
        super(props);

        this.state = initialState;

        this.showError = this.showError.bind(this);
        this.showInfo = this.showInfo.bind(this);
    }

    showInfo(message) {
        this.messages.show({
            sticky: true,
            severity: 'info',
            summary: 'Information...',
            detail: message
        });
    }

    showError(message) {
        this.messages.show({
            sticky: true,
            severity: 'error',
            summary: 'Fehler...',
            detail: message
        });
    }

    handelKeyDown(e) {
        if (e.keyCode === 13) {
            // enter
            this.save();
        }
    }

    save() {
        this.messages.clear();

        let alleFelderGefuellt = this.checkInput();
        if( !alleFelderGefuellt) {
            return;
        }

        this.setState({saving:true});
        let url = ConfigUtil.getConfig().resourceUrls.user + "/registration";
        let data = this.state;
        FetchUtil.fetchPost(url,
            data,
            json => {
                this.showInfo("Bitte prüfe deine Mails (auch SPAM) und bestätige deine Registrierung innerhalb von 2 Stunden. Danach läuft deine Registrierung ab und du musst eventuell eine weitere Registrierung durchführen.");
            },
            responseNotOk => {
                this.showError("Fehler: " + responseNotOk.message);
                this.setState({saving:false});
            },
            error => {
                this.showError("Fehler: " + error.message);
                this.setState({saving:false});
            }
        );
    }

    checkInput() {
        let alleFelderGefuellt = this.state.name !== "" && this.state.firstname !== "" && this.state.username !== "" &&
            this.state.email !== "" && this.state.password1 !== "" && this.state.password2 !== "" && this.state.agb;
        return alleFelderGefuellt;
    }


    render() {
        let alleFelderGefuellt = this.checkInput();

        return <Dialog baseZIndex={2000} header="Registrierung" visible={this.props.visible}
                       style={{width: '500px', textAlign: "left"}}
                       onHide={() => this.props.onClose()}>
            <Toast ref={(el) => this.toast = el}/>
            <Messages ref={(el) => this.messages = el}/>
            <div className="col-12 md:col-12 lg:col-8">
                <div className="grid">
                    <div className="col-12 md:col-6 lg:col-12">
                        <InputText value={this.state.username} style={{width: "100%"}}
                                   placeholder="Benutzername"
                                   onChange={(e) => this.setState({username: e.target.value})}
                                   onKeyDown={(e) => this.handelKeyDown(e)}/>
                    </div>
                    <div className="col-12 md:col-6 lg:col-12">
                        <InputText value={this.state.firstname} style={{width: "100%"}}
                                   placeholder="Vorname"
                                   onChange={(e) => this.setState({firstname: e.target.value})}
                                   onKeyDown={(e) => this.handelKeyDown(e)}/>
                    </div>
                    <div className="col-12 md:col-6 lg:col-12">
                        <InputText value={this.state.name} style={{width: "100%"}}
                                   placeholder="Nachname"
                                   onChange={(e) => this.setState({name: e.target.value})}
                                   onKeyDown={(e) => this.handelKeyDown(e)}/>
                    </div>
                    <div className="col-12 md:col-6 lg:col-12">
                        <InputText value={this.state.email} style={{width: "100%"}}
                                   placeholder="Email"
                                   onChange={(e) => this.setState({email: e.target.value})}
                                   onKeyDown={(e) => this.handelKeyDown(e)}/>
                    </div>
                    <div className="col-12 md:col-6 lg:col-12">
                        <Password value={this.state.password1} style={{width: "100%"}}
                                  placeholder="Kennwort"
                                  onChange={(e) => this.setState({password1: e.target.value})}
                                  onKeyDown={(e) => this.handelKeyDown(e)}
                                  feedback={true}/>
                    </div>
                    <div className="col-12 md:col-6 lg:col-12">
                        <Password value={this.state.password2} style={{width: "100%"}}
                                  placeholder="Kennwort wiederholen"
                                  onChange={(e) => this.setState({password2: e.target.value})}
                                  onKeyDown={(e) => this.handelKeyDown(e)}
                                  feedback={false}/>
                    </div>
                </div>
            </div>
            <div className="col-12 md:col-12 lg:col-12">
                Mit dem Klick auf "Registrieren" willige ich in die Verarbeitung meiner personenbezogenen Daten gemäß der
                <NavLink to={NAVIGATION_ITEM.DATENSCHUTZ.route} style={{textDecoration: "none"}}>
                     <span style={{marginLeft:5, marginRight:5}}>Datenschutzerklärung</span>
                </NavLink>von darthelfer.de ein.
                <br/>
                <br/>
                <Checkbox checked={this.state.agb}
                          onChange={() => this.setState({agb: !this.state.agb})}/>
                <label className="p-checkbox-label">Ich stimme der
                    <NavLink to={NAVIGATION_ITEM.AGB.route} style={{textDecoration: "none"}}>
                        <span style={{marginLeft:5, marginRight:5}}>AGB</span>
                    </NavLink>
                    von darthelfer.de zu.</label>
            </div>
            <div className="col-12 md:col-12 lg:col-12" style={{marginTop:10}}>
                <div className="grid">
                    <div className="col">
                        <Button label="Registrieren"
                                icon="pi pi-user-plus"
                                disabled={!alleFelderGefuellt || this.state.saving}
                                type="button" onClick={() => this.save()} style={{marginRight:5}}/>
                        <Button label="Abbrechen/Schliessen"
                                type="button" onClick={() => this.props.onClose()}/>
                    </div>
                </div>
            </div>
        </Dialog>
    }
}

RegistrierenDialog.propTypes = {
    visible: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default RegistrierenDialog;
