import React, {useEffect, useState} from 'react'
import {Dialog} from "primereact/dialog";
import {Button} from "primereact/button";
import FetchUtil from "../../../util/FetchUtil";
import ConfigUtil from "../../../util/ConfigUtil";
import {ScrollPanel} from "primereact/scrollpanel";
import DateUtil from "../../../util/DateUtil";
import {InputText} from "primereact/inputtext";
import ErrorInfoComponent from "../../general/ErrorInfoComponent";

export default function TournamentPlayerEventOperatorSelectDialog({eventOperatorId, onlyEinzelturnier, onlyDoppelturnier, callBackOnSelect, callBackOnClose}) {

    const [infoMessage, setInfoMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);

    let [showAllRows, setShowAllRows] = useState(false);

    let [players, setPlayers] = useState([]);
    let [tournaments, setTournaments] = useState([]);
    let [search, setSearch] = useState("");

    useEffect(() => {
        FetchUtil.fetchGet(ConfigUtil.getConfig().resourceUrls.tournament + "/eventoperator/players/" + eventOperatorId,
            json => {
                if( onlyDoppelturnier) {
                    let t = json.tournaments.filter(tournament => tournament.doppelturnier && tournament.tournamentStatus.toUpperCase() !== "CLOSED")
                    setTournaments(t);
                } else if( onlyEinzelturnier) {
                    let t = json.tournaments.filter(tournament => !tournament.doppelturnier && tournament.tournamentStatus.toUpperCase() !== "CLOSED")
                    setTournaments(t);
                } else {
                    setTournaments(json.tournaments);
                }
                setPlayers(json.players);
            },
            responseNotOk => {
                setErrorMessage("Fehler beim Laden: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler beim Laden: " + error.message);
            }
        );
    }, [eventOperatorId]);

    function existSearchInPlayername(player) {
        for (const dto of player.eventOperatorPlayerNameTournamentDtos) {
            if( dto.name.toUpperCase().includes(search.toUpperCase()) ) {
                return true;
            }
        }
        return false;
    }

    function getTournamentName(tournamentId) {
        let array = tournaments.filter(tournament => tournament.id === tournamentId);
        if( array.length === 0) {
            return undefined;
        }
        return array[0].bezeichnung;
    }

    function getTournamentBeginn(tournamentId) {
        let array = tournaments.filter(tournament => tournament.id === tournamentId);
        if( array.length === 0) {
            return undefined;
        }
        return array[0].beginn;
    }

    const getRows = () => {
        if (eventOperatorId === null || eventOperatorId === undefined) {
            return "";
        }
        let rows = [];
        let counter = 0;
        for (let i = 0; i < players.length; i++) {
            let modulo = i % 2;
            let currentStyle = modulo === 0 ? {backgroundColor: "#f2f2f2"} : {};
            let player = players[i];
            let namenArray = [];
            let name = [];
            let count = 0;
            let eventOperatorPlayerNameTournamentDtos = player.eventOperatorPlayerNameTournamentDtos.reverse();
            for (const dto of eventOperatorPlayerNameTournamentDtos) {
                let tournamentName = getTournamentName(dto.tournamentId);
                let tournamentBeginn = getTournamentBeginn(dto.tournamentId);
                // sobald nicht vorhanden, handelt es sich um ein Folgeturnier, daher nicht anzeigen
                if( tournamentName === undefined || tournamentBeginn === undefined) {
                    continue;
                }
                count++;
                if( count > 3 && !showAllRows) {
                    break;
                }
                name.push(<div style={{marginBottom:5}}>{DateUtil.localDateTimeToString(tournamentBeginn)} - {dto.name}<br/>{tournamentName}</div>);
                if( !namenArray.includes(dto.name)) {
                    namenArray.push(dto.name);
                }
            }
            if( count > 3 && !showAllRows) {
                name.push(<div style={{color:"blue", cursor:"pointer"}} onClick={() => setShowAllRows(true)}>...mehr anzeigen...</div>);
            }
            if( namenArray.length === 0) {
                // keine Turniernamen. Entweder harter Fehler oder onlyDoppelturnier und kein Doppelturnier gefunden.
                continue;
            }
            if (search === "" || existSearchInPlayername(player) || player.idShort.toUpperCase().includes(search.toUpperCase())) {
                counter++;
                rows.push(<div className="grid" key={"key_player_" + player.idShort} style={{marginTop: "5px", ...currentStyle}}>
                    <div className="col-fixed" style={{width: 40}}>{counter}.</div>
                    <div className="col-fixed" style={{width: 100, cursor:"pointer"}} onClick={() => callBackOnSelect(player.idShort, namenArray)}>{player.idShort}</div>
                    <div className="col" style={{width: "100%"}}>{name}</div>
                </div>);
            }
        }
        return rows;
    }

    const dialogFooter = (
        <div>
            <Button label="Schliessen" icon="pi pi-times" onClick={() => callBackOnClose()}
                    className="p-button-secondary"/>
        </div>
    );

    return <div>
        <Dialog baseZIndex={2000} header="Spielerauswahl" visible={true}
                style={{width: '700px', textAlign: "left"}} footer={dialogFooter}
                onHide={() => callBackOnClose()}>
            <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage} onClearInfoMessage={() => setInfoMessage(undefined)} onClearErrorMessage={() => setErrorMessage(undefined)} />
            {onlyDoppelturnier &&
                <h4>Hinweis: Hier werden Spieler aus nicht abgeschlossenen Doppelturnieren, der letzten 12 Monate, angezeigt.<br/>
                    Bitte klicke auf die SpielerId, danach kannst du diesen Spieler übernehmen.</h4>
            }
            {onlyEinzelturnier &&
                <h4>Hinweis: Hier werden Spieler aus nicht abgeschlossenen Einzelturnieren, der letzten 12 Monate, angezeigt.<br/>
                    Bitte klicke auf die SpielerId, danach kannst du diesen Spieler übernehmen.</h4>
            }
            {!onlyEinzelturnier && !onlyDoppelturnier &&
                <h4>Hinweis: Hier werden die Spieler deiner Veranstaltungen aus den letzten 12 Monaten angezeigt.
                    Bitte klicke auf die SpielerId, danach kannst du diesen Spieler über die SpielerId speichern/übernehmen.</h4>
            }
            <div className="grid" style={{marginTop: 10}}>
                <div className="col-fixed" style={{width: 150}}>Filter:</div>
                <div className="col"><InputText style={{width: 330}} value={search}
                                                onChange={(e) => setSearch(e.target.value)}/></div>
            </div>
            <div className="grid" style={{marginTop: "10px"}}>
                <div className="col" style={{fontWeight: "bold"}}>Anzahl Spieler: {players.length}</div>
            </div>
            <div className="grid" style={{marginTop: "5px", fontWeight:"bold", borderBottom:"1px solid black"}}>
                <div className="col-fixed" style={{width: 40}}>#</div>
                <div className="col-fixed" style={{width: 100}}>SpielerId</div>
                <div className="col-fixed" style={{width: 140}}>Eventbeginn</div>
                <div className="col" style={{width: "100%"}}>Name</div>
            </div>
            <ScrollPanel style={{width: '100%', height: '400px'}}>
                {getRows()}
            </ScrollPanel>
        </Dialog>
    </div>
}
