import React from 'react'
import {Dialog} from "primereact/dialog";
import {Button} from "primereact/button";
import PropTypes from "prop-types";
import {Toast} from "primereact/toast";
import {Messages} from "primereact/messages";
import FetchUtil from "../../../util/FetchUtil";
import ConfigUtil from "../../../util/ConfigUtil";
import ConfirmDialog from "../../general/ConfirmDialog";

class ChangeLigaDialog extends React.Component {

    constructor(props) {
        super(props);

        this.state = {showChangeConfirmDialog: false};
        this.showInfo = this.showInfo.bind(this);
        this.showError = this.showError.bind(this);
        this.showWarn = this.showWarn.bind(this);
        this.ladeDaten = this.ladeDaten.bind(this);
    }

    componentDidMount() {
        this.ladeDaten();
    }

    showInfo(message) {
        this.toast.show({
            severity: 'success',
            summary: 'Erfolgreich...',
            detail: message
        })
    }

    showWarn(message) {
        this.messages.show({
            severity: 'info',
            summary: 'Hinweis...',
            detail: message
        });
    }

    showError(message) {
        this.messages.show({
            severity: 'error',
            sticky: 'true',
            summary: 'Fehler...',
            detail: message
        });
    }

    ladeDaten() {
        let url = ConfigUtil.getConfig().resourceUrls.tournament + "/turnierezumteilnehmerverschieben";
        FetchUtil.fetchGet(url,
            json => {
                this.setState({ligen: json});
            },
            responseNotOk => {
                this.showError("Fehler beim Laden der Daten: " + responseNotOk.message);
            },
            error => {
                this.showError("Fehler beim Laden der Daten: " + error.message);
            }
        );

        return undefined;
    }

    getLigaListe() {
        if( this.state.ligen === null || this.state.ligen === undefined) {
            return <div>Keine Ligen zur Auswahl vorhanden... Evtl. noch nicht geladen oder keine Adminrechte auf andere Ligen.</div>
        }
        let rows = [];
        for (const liga of this.state.ligen) {
            if( liga.id !== this.props.liga.id) {
                rows.push(<div style={{cursor:"pointer"}} onClick={() => this.setState({showChangeConfirmDialog: true, selectedLiga: liga})} key={"key_change_liga_" + liga.id}>
                    <img src="/images/change.png"
                         style={{width:30, marginRight:50, marginTop:7}}
                         alt="Liga wechseln"/>{liga.bezeichnung}
                         <hr/>
                </div>)
            }
        }
        return <div>
            {rows}
        </div>
    }

    change() {
        let url = ConfigUtil.getConfig().resourceUrls.tournament + "/changeteilnehmerliga?playerId=" +
            this.props.playerid + "&ligaAltId=" + this.props.liga.id + "&ligaNeuId=" + this.state.selectedLiga.id;
        FetchUtil.fetchPost(url,
            {},
            json => {
                this.props.callBackCloseDialog();
                this.props.callBackRefresh();
                this.setState({showChangeConfirmDialog: false});
            },
            responseNotOk => {
                this.showError("Fehler beim Umziehen eines Teilnehmers: " + responseNotOk.message);
                this.setState({showChangeConfirmDialog: false});
            },
            error => {
                this.showError("Fehler beim Umziehen eines Teilnehmers " + error.message);
                this.setState({showChangeConfirmDialog: false});
            });
    }

    render() {
        const dialogFooter = (
            <div>
                <Button label="Abbrechen" icon="pi pi-times" onClick={() => this.props.callBackCloseDialog()} className="p-button-secondary" />
            </div>
        );

        let selectedLigaBezeichnung = this.state.selectedLiga !== undefined ? this.state.selectedLiga.bezeichnung : "???";
        return <Dialog baseZIndex={2000} header="Teilnehmer umziehen" visible={this.props.visible}
                                   style={{width: 700, height:"100%", textAlign:"left"}} footer={dialogFooter} onHide={() => this.props.callBackCloseDialog()}>
            <Toast ref={(el) => this.toast = el}/>
            <Messages ref={(el) => this.messages = el}/>
            <div className="grid">
                <div className="col-fixed" style={{width:"120px"}}>Teilnehmer:</div>
                <div className="col">{this.props.teilnehmername}</div>
            </div>
            <h3>Mögliche Ligen:</h3>
            {this.getLigaListe()}
            <ConfirmDialog visible={this.state.showChangeConfirmDialog}
                           callBackOnNein={() => this.setState({
                               showChangeConfirmDialog: false, selectedLiga: undefined
                           })}
                           callBackOnJa={() => this.change()}
                           header="Teilnehmer verschieben"
                           textOben={"Teilnehmer soll vom aktuellen in das gewählte Turnier '" + selectedLigaBezeichnung + "' verschoben werden."}
                           textUnten="Möchtest du dies wirklich veranlassen?"/>

        </Dialog>
    }
}

ChangeLigaDialog.propTypes = {
    liga: PropTypes.object.isRequired,
    callBackRefresh: PropTypes.func.isRequired,
    callBackCloseDialog: PropTypes.func.isRequired,
    visible : PropTypes.bool.isRequired,
    playerid: PropTypes.string,
    teilnehmername: PropTypes.string,
};

export default ChangeLigaDialog
