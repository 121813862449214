import React, {useState} from 'react'
import {Button} from 'primereact/button';
import {InputSwitch} from 'primereact/inputswitch';

import PlusMinusComponent from "../../general/PlusMinusComponent";
import SelectPlayerComponent from "../../general/SelectPlayerComponent";
import ScoreWertComponent from "../../general/ScoreWertComponent";
import FetchUtil from "../../../util/FetchUtil";
import ConfigUtil from "../../../util/ConfigUtil";
import {TabPanel, TabView} from 'primereact/tabview';
import TrainingOnlineGameContainer from "../../../containers/views/training/TrainingOnlineGameContainer";

export default function TrainingGameEditComponent({trainingssession, callBackOnInfo, callBackOnError, clearMessages, callBackOnDataChanged, publicKey, counter}) {

    let [details, setDetails] = useState(false);
    let [spieler1, setSpieler1] = useState(undefined);
    let [spieler2, setSpieler2] = useState(undefined);
    let [spieler1Legs, setSpieler1Legs] = useState(undefined);
    let [spieler2Legs, setSpieler2Legs] = useState(undefined);
    let [spieler1Anzahl180, setSpieler1Anzahl180] = useState(0);
    let [spieler1Anzahl171, setSpieler1Anzahl171] = useState(0);
    let [spieler2Anzahl180, setSpieler2Anzahl180] = useState(0);
    let [spieler2Anzahl171, setSpieler2Anzahl171] = useState(0);
    let [spieler1Highfinishes, setSpieler1Highfinishes] = useState([]);
    let [spieler2Highfinishes, setSpieler2Highfinishes] = useState([]);
    let [spieler1Highscores, setSpieler1Highscores] = useState([]);
    let [spieler2Highscores, setSpieler2Highscores] = useState([]);
    let [spielerZumLoeschen, setSpielerZumLoeschen] = useState(undefined);

    const spielerBezahltMarkieren = (spieler) => {
        clearMessages();

        let data = {trainingsessionid: trainingssession.id, spielerid: spieler.id};
        let url = "";
        if (publicKey !== undefined) {
            url = ConfigUtil.getConfig().resourceUrls.public + "/saveplayerpaid/" + publicKey;
        } else {
            url = ConfigUtil.getConfig().resourceUrls.training + "/saveplayerpaid";
        }
        FetchUtil.fetchPost(url,
            data,
            id => {
                if (callBackOnDataChanged !== undefined) {
                    callBackOnDataChanged();
                }
                callBackOnInfo("Spieler wurde als bezahlt markiert.");
            },
            responseNotOk => {
                callBackOnError("Fehler beim Markieren eines Spielers als bezahlt: " + responseNotOk.message);
            },
            error => {
                callBackOnError("Fehler beim Markieren eines Spielers als bezahlt: " + error.message);
            }
        );
    }

    const spielerUnBezahltMarkieren = (spieler) => {
        clearMessages();

        let data = {trainingsessionid: trainingssession.id, spielerid: spieler.id};
        let url = "";
        if (publicKey !== undefined) {
            url = ConfigUtil.getConfig().resourceUrls.public + "/saveplayernotpaid/" + publicKey;
        } else {
            url = ConfigUtil.getConfig().resourceUrls.training + "/saveplayernotpaid";
        }
        FetchUtil.fetchPost(url,
            data,
            id => {
                if (callBackOnDataChanged !== undefined) {
                    callBackOnDataChanged();
                }
                callBackOnInfo("Spieler wurde als unbezahlt markiert.");
            },
            responseNotOk => {
                callBackOnError("Fehler beim Markieren eines Spielers als unbezahlt: " + responseNotOk.message);
            },
            error => {
                callBackOnError("Fehler beim Markieren eines Spielers als unbezahlt: " + error.message);
            }
        );
    }

    const merkeSpieler1 = (spieler) => {
        setSpieler1(spieler);
    }

    const merkeSpielerZumLoeschen = (spieler) => {
        setSpielerZumLoeschen(spieler);
    }

    const loescheSpielerZumLoeschen = () => {
        setSpielerZumLoeschen(undefined);
    }

    const merkeSpieler2 = (spieler) => {
        setSpieler2(spieler);
    }

    const merkeSpieler1Legs = (legs) => {
        setSpieler1Legs(legs);
    }

    const merkeSpieler2Legs = (legs) => {
        setSpieler2Legs(legs);
    }

    const removeHighfinishZuSpieler1 = (value) => {
        let newHighfinies = entferneErstenGefundenWertAusArray(spieler1Highfinishes, value);
        setSpieler1Highfinishes(newHighfinies);
    }

    const removeHighfinishZuSpieler2 = (value) => {
        let newHighfinies = entferneErstenGefundenWertAusArray(spieler2Highfinishes, value);
        setSpieler2Highfinishes(newHighfinies);
    }

    const removeHighscoreZuSpieler1 = (value) => {
        let newHighscores = entferneErstenGefundenWertAusArray(spieler1Highscores, value);
        setSpieler1Highscores(newHighscores);
    }

    const removeHighscoreZuSpieler2 = (value) => {
        let newHighscores = entferneErstenGefundenWertAusArray(spieler2Highscores, value);
        setSpieler2Highscores(newHighscores);
    }

    const entferneErstenGefundenWertAusArray = (myarray, value) => {
        let newMyArray = [];
        let found = false;
        for (var i = 0; i < myarray.length; i++) {
            if (myarray[i] !== value || found === true) {
                newMyArray.push(myarray[i]);
            } else {
                // hat sonst wenn z.b. 111 zwei mal drinnen war, beide gelöscht.
                found = true;
            }
        }
        return newMyArray;
    }

    const addHighfinishZuSpieler2 = (value) => {
        let highfinishes = spieler2Highfinishes;
        highfinishes.push(value);
        setSpieler2Highfinishes(highfinishes);
    }

    const addHighfinishZuSpieler1 = (value) => {
        let highfinishes = spieler1Highfinishes;
        highfinishes.push(value);
        setSpieler1Highfinishes(highfinishes);
    }

    const addHighscoreZuSpieler2 = (value) => {
        let highscores = spieler2Highscores;
        highscores.push(value);
        setSpieler2Highscores(highscores);
    }

    const addHighscoreZuSpieler1 = (value) => {
        let highscores = spieler1Highscores;
        highscores.push(value);
        setSpieler1Highscores(highscores);
    }

    const resetAlleFelder = () => {
        resetAlleErweitertenFelder();
        setSpieler1(undefined);
        setSpieler2(undefined);
        setSpieler1Legs(undefined);
        setSpieler2Legs(undefined);
        setDetails(false);
    }

    const resetAlleErweitertenFelder = () => {
        setSpieler1Anzahl180(0);
        setSpieler1Anzahl171(0);
        setSpieler2Anzahl180(0);
        setSpieler2Anzahl171(0);
        setSpieler1Highscores([]);
        setSpieler1Highfinishes([]);
        setSpieler2Highscores([]);
        setSpieler2Highfinishes([]);
        setDetails(!details);
    }

    const resetFelderSpieler2 = () => {
        setSpieler2(undefined);
        setSpieler2Legs(undefined);
        setSpieler2Anzahl180(0);
        setSpieler2Anzahl171(0);
        setSpieler2Highscores([]);
        setSpieler2Highfinishes([]);
    }

    const resetFelderSpieler1 = () => {
        setSpieler1(undefined);
        setSpieler1Legs(undefined);
        setSpieler1Anzahl180(0);
        setSpieler1Anzahl171(0);
        setSpieler1Highscores([]);
        setSpieler1Highfinishes([]);
    }

    const loescheSpielerAusSession = () => {
        clearMessages();
        let dataToPost = {
            trainingSessionModificationDto: trainingssession,
            playerModificationDto: spielerZumLoeschen
        };
        let url = "";
        if (publicKey !== undefined) {
            url = ConfigUtil.getConfig().resourceUrls.public + "/deleteplayerfromsession/" + publicKey + "/" + trainingssession.id + "/" + spielerZumLoeschen.id;
        } else {
            url = ConfigUtil.getConfig().resourceUrls.training + "/deleteplayerfromsession";
        }

        FetchUtil.fetchPost(url,
            dataToPost,
            () => {
                if (callBackOnDataChanged !== undefined) {
                    callBackOnDataChanged();
                }
                setSpielerZumLoeschen(undefined);
                callBackOnInfo("Spieler wurde gelöscht oder ist zumindest nicht in der gewählten Session vorhanden.");
            },
            responseNotOk => {
                callBackOnError("Fehler beim Löschen eines Spielers: " + responseNotOk.message);
            },
            error => {
                callBackOnError("Fehler bemi Löschen eines Spielers: " + error.message);
            }
        );
    }

    const save = () => {
        clearMessages();
        let data = {
            spieler1: spieler1,
            spieler1Legs: spieler1Legs,
            spieler1Anzahl171: spieler1Anzahl171,
            spieler1Anzahl180: spieler1Anzahl180,
            spieler1Highfinishes: spieler1Highfinishes,
            spieler1Highscores: spieler1Highscores,
            spieler2: spieler2,
            spieler2Legs: spieler2Legs,
            spieler2Anzahl171: spieler2Anzahl171,
            spieler2Anzahl180: spieler2Anzahl180,
            spieler2Highfinishes: spieler2Highfinishes,
            spieler2Highscores: spieler2Highscores,
            trainingssession: trainingssession};
        let url = "";
        if (publicKey !== undefined) {
            url = ConfigUtil.getConfig().resourceUrls.public + "/trainingsgameeintragen/" + publicKey;
        } else {
            url = ConfigUtil.getConfig().resourceUrls.training + "/trainingsgameeintragen";
        }
        FetchUtil.fetchPost(url,
            data,
            id => {
                if (callBackOnDataChanged !== undefined) {
                    callBackOnDataChanged();
                }
                callBackOnInfo("Spiel wurde erfolgreich gespeichert.");
            },
            responseNotOk => {
                callBackOnError("Fehler beim Speichern: " + responseNotOk.message);
            },
            error => {
                callBackOnError("Fehler beim Speichern: " + error.message);
            }
        );
    }

    const getScoreButtonsArea = (scoreButtons) => {
        return <div className="grid">
            <div className="col-fixed" style={{width: '100px', textAlign: "right"}}></div>
            <div className="col" style={{textAlign: "left"}}>
                {scoreButtons}
            </div>
        </div>
    }

    const getHighscoreArea = (values, callbackOk, callbackRemove) => {
        return <div className="grid">
            <div className="col-fixed"
                 style={{width: '100px', textAlign: "right"}}>Highscores:
            </div>
            <div className="col" style={{textAlign: "right"}}>
                <ScoreWertComponent values={values}
                                    readonly={false}
                                    callBackOk={callbackOk}
                                    removeValue={callbackRemove}/>
            </div>
        </div>
    }

    const getHighFinishArea = (values, callbackOk, callbackRemove) => {
        return <div className="grid">
            <div className="col-fixed"
                 style={{width: '100px', textAlign: "right"}}>Highfinishes:
            </div>
            <div className="col" style={{textAlign: "right"}}>
                <ScoreWertComponent values={values}
                                    readonly={false}
                                    callBackOk={callbackOk}
                                    removeValue={callbackRemove}/>
            </div>
        </div>
    }

    const get171Area = (value, callBackMinus, callBackPlus) => {
        return <div className="grid">
            < div className="col-fixed"
                  style={{width: '100px', textAlign: "right"}}>171er
            </div>
            <div className="col" style={{textAlign: "left"}}>
                <PlusMinusComponent value={value}
                                    readonly={false}
                                    callBackMinus={callBackMinus}
                                    callBackPlus={callBackPlus}/>
            </div>
        </div>
    }

    const get180Area = (value, callBackMinus, callBackPlus) => {
        return <div className="grid">
            <div className="col-fixed"
                 style={{width: '100px', textAlign: "right"}}>180er
            </div>
            <div className="col" style={{textAlign: "left"}}>
                <PlusMinusComponent value={value}
                                    readonly={false}
                                    callBackMinus={callBackMinus}
                                    callBackPlus={callBackPlus}/>
            </div>
        </div>
    }

    const getScoreButtons = (callBackFunc, currentLegValue) => {
        let buttons = [];
        if (trainingssession === undefined) {
            return buttons;
        }
        let classNameSelected = "p-button-success";
        let className = "p-button-secondary";
        for (let i = 0; i <= trainingssession.firstToLegs; i++) {
            let currentClassName = className;
            if (i === currentLegValue) {
                currentClassName = classNameSelected;
            }
            buttons.push(<Button key={i} label={"" + i}
                                 onClick={(e) => callBackFunc(i)}
                                 className={currentClassName}
                                 style={{height: "60px", width: "60px", marginRight: "10px", marginBottom: "10px"}}/>);
        }
        return buttons;
    }

    const render = () => {

        const showOnlineGameTab = publicKey === undefined || publicKey === null;
        const tabs = [];
        if( showOnlineGameTab ) {
            tabs.push(<TabPanel key={"tabpanel_onlinegame"} header="Onlinegame" leftIcon="pi pi-globe">
                <TrainingOnlineGameContainer trainingssession={trainingssession}/>
            </TabPanel>);
        }

        let scoreButtons1 = getScoreButtons(merkeSpieler1Legs, spieler1Legs);
        let scoreButtons2 = getScoreButtons(merkeSpieler2Legs, spieler2Legs);

        let gleicherSpieler = spieler1 !== undefined && spieler2 !== undefined && spieler1.id === spieler2.id;
        let spieler1NichtGesetzt = spieler1 === undefined || spieler1 === null;
        let spieler2NichtGesetzt = spieler2 === undefined || spieler2 === null;
        let spieler1LegsNichtGesetzt = spieler1Legs === undefined || spieler1Legs === null;
        let spieler2LegsNichtGesetzt = spieler2Legs === undefined || spieler2Legs === null;

        let legsEingabeGleich = spieler1Legs === spieler2Legs;
        let keineEingabeHatMaxLegsWert = true;
        if (trainingssession !== undefined) {
            keineEingabeHatMaxLegsWert = spieler1Legs !== trainingssession.firstToLegs &&
                spieler2Legs !== trainingssession.firstToLegs;
        }
        let saveButtonDisabled = gleicherSpieler || spieler1NichtGesetzt || spieler2NichtGesetzt || spieler1LegsNichtGesetzt || spieler2LegsNichtGesetzt || legsEingabeGleich || keineEingabeHatMaxLegsWert;

        return <div style={{textAlign: "center", align: "center", backgroundColor: "white", color: "black"}}>
            <TabView renderActiveOnly={false}>
                <TabPanel header="Spiel eintragen" leftIcon="pi pi-calendar">
                    <div className="grid" style={{marginBottom: "40px"}}>
                        <div className="col-fixed" style={{width: '100px', textAlign: "right"}}>Details:</div>
                        <div className="col" style={{textAlign: "left"}}>
                            <InputSwitch checked={details}
                                         onChange={(e) => resetAlleErweitertenFelder()}/>
                        </div>
                    </div>
                    <div className="grid">
                        <div className="col-12 md:col-6 lg:col-6" style={{borderTop: "2px solid #f4f4f4"}}>
                            <div className="grid">
                                <div className="col-fixed" style={{width: '100px', textAlign: "right"}}>Spieler 1:
                                </div>
                                <div className="col" style={{textAlign: "left"}}>
                                    <SelectPlayerComponent
                                        player={spieler1}
                                        selectButtonLabel="Spieler 1 auswählen"
                                        callBackSelectFunc={merkeSpieler1}
                                        callBackDeleteFunc={resetFelderSpieler1}
                                        callBackOnErrorFunc={callBackOnError}
                                        publicKey={publicKey}
                                        trainingssession={trainingssession}/>
                                </div>
                            </div>
                            {getScoreButtonsArea(scoreButtons1)}
                            <div>
                                {details === true ?
                                    <div>
                                        {get171Area(spieler1Anzahl171, () => setSpieler1Anzahl171(spieler1Anzahl171 - 1), () => setSpieler1Anzahl171(spieler1Anzahl171 + 1))}
                                        {get180Area(spieler1Anzahl180, () => setSpieler1Anzahl180(spieler1Anzahl180 - 1), () => setSpieler1Anzahl180(spieler1Anzahl180 + 1))}
                                        {getHighscoreArea(spieler1Highscores, addHighscoreZuSpieler1, removeHighscoreZuSpieler1)}
                                        {getHighFinishArea(spieler1Highfinishes, addHighfinishZuSpieler1, removeHighfinishZuSpieler1)}
                                    </div>
                                    : <div/>
                                }

                            </div>
                        </div>
                        <div className="col-12 md:col-6 lg:col-6" style={{borderTop: "2px solid #f4f4f4"}}>
                            <div className="grid">
                                <div className="col-fixed" style={{width: '100px', textAlign: "right"}}>Spieler 2:
                                </div>
                                <div className="col" style={{textAlign: "left"}}>
                                    <SelectPlayerComponent
                                        player={spieler2}
                                        selectButtonLabel="Spieler 2 auswählen"
                                        callBackSelectFunc={merkeSpieler2}
                                        callBackDeleteFunc={resetFelderSpieler2}
                                        callBackOnErrorFunc={callBackOnError}
                                        publicKey={publicKey}
                                        trainingssession={trainingssession}/>
                                </div>
                            </div>
                            {getScoreButtonsArea(scoreButtons2)}
                            <div>
                                {details === true ?
                                    <div>
                                        {get171Area(spieler2Anzahl171, () => setSpieler2Anzahl171(spieler2Anzahl171 - 1), () => setSpieler2Anzahl171(spieler2Anzahl171 + 1))}
                                        {get180Area(spieler2Anzahl180, () => setSpieler2Anzahl180(spieler2Anzahl180 - 1), () => setSpieler2Anzahl180(spieler2Anzahl180 + 1))}
                                        {getHighscoreArea(spieler2Highscores, addHighscoreZuSpieler2, removeHighscoreZuSpieler2)}
                                        {getHighFinishArea(spieler2Highfinishes, addHighfinishZuSpieler2, removeHighfinishZuSpieler2)}
                                    </div>
                                    : <div/>
                                }

                            </div>
                        </div>
                    </div>
                    <div className="grid" style={{marginTop: "20px"}}>
                        <div className="col" style={{textAlign: "left"}}>
                            <Button type="button" onClick={() => save()}
                                    label="Speichern"
                                    disabled={saveButtonDisabled}
                                    className="p-button-raised p-button-rounded"
                                    style={{width: "200px", marginLeft: "5px", marginTop: "5px"}}/>
                            <Button type="button" onClick={() => resetAlleFelder()}
                                    label="Reset / neues Spiel"
                                    className="p-button-raised p-button-rounded"
                                    style={{width: "200px", marginLeft: "5px", marginTop: "5px"}}/>
                        </div>
                    </div>
                </TabPanel>
                <TabPanel header="Spieler aus Session entfernen" leftIcon="pi pi-user">
                    <SelectPlayerComponent
                        player={spielerZumLoeschen}
                        selectButtonLabel="Spieler zum Entfernen auswählen"
                        callBackSelectFunc={merkeSpielerZumLoeschen}
                        callBackDeleteFunc={loescheSpielerZumLoeschen}
                        callBackOnErrorFunc={callBackOnError}
                        publicKey={publicKey}
                        trainingssession={trainingssession}/>
                    <Button label="Ausgewählten Spieler aus Trainingssession entfernen"
                            className="p-button-danger"
                            disabled={!spielerZumLoeschen || !trainingssession}
                            onClick={() => loescheSpielerAusSession()}
                            style={{height: "50px", marginTop: "30px"}}/>
                </TabPanel>
                <TabPanel header="Bezahlung" leftIcon="pi pi-dollar">
                    <div className="grid" style={{textAlign: "left", marginTop:10}}>
                        Über diesen Seite können Sie Spieler, die Ihren Trainingssessionbeitrag bezahlt haben markieren.<br/><br/>
                        Das Speichern wird über eine Popup-Meldung bestätigt.<br/><br/>
                        Die Anzeige erfolgt in der Ergebnistabelle zur Trainingssession.<br/>
                    </div>
                    <div className="grid" style={{marginTop: 20}}>
                        <div style={{marginRight:20, marginTop:5}}>
                            <SelectPlayerComponent
                                player={undefined}
                                selectButtonLabel="Spieler, der gezahlt hat, auswählen"
                                callBackSelectFunc={spielerBezahltMarkieren}
                                callBackDeleteFunc={() => console.log("hier muss nichts passieren")}
                                callBackOnErrorFunc={callBackOnError}
                                publicKey={publicKey}
                                trainingssession={trainingssession}/>
                        </div>
                        <div style={{marginTop:5}}>
                            <SelectPlayerComponent
                                player={undefined}
                                selectButtonLabel="Bereits als bezahlt eingetragenen Spieler wieder löschen (unbezahlt setzen)"
                                callBackSelectFunc={spielerUnBezahltMarkieren}
                                callBackDeleteFunc={() => console.log("hier muss nichts passieren")}
                                callBackOnErrorFunc={callBackOnError}
                                publicKey={publicKey}
                                trainingssession={trainingssession}/>
                        </div>
                    </div>
                </TabPanel>
                {tabs}
            </TabView>
        </div>
    }

    return <>{render()}</>;
}
