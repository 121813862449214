import React from 'react'
import {Messages} from "primereact/messages";
import {Toast} from 'primereact/toast';
import FetchUtil from "../../../util/FetchUtil";
import ConfigUtil from "../../../util/ConfigUtil";
import {getModusNameById} from "../../general/GameModusComponent";
import {TabPanel, TabView} from "primereact/tabview";
import {TabelleRangliste} from "./ligaComponents";
import {Button} from "primereact/button";
import AdminsVerwaltenDialog from "./AdminsVerwaltenDialog";
import LigaTeilnehmerArea from "./LigaTeilnehmerArea";
import PropTypes from "prop-types";
import TournamentAuslosungDialog from "../tournament/TournamentAuslosungDialog";
import TurnierVerwaltenDialog from "../tournament/TurnierVerwaltenDialog";
import RefreshCounterComp from "../../RefreshCounterComp";
import TournamentKratzerGames from "../tournament/TournamentKratzerGames";
import TurnierQRCode from "./TurnierQRCode";
import {NAVIGATION_ITEM} from "../../../constants/navigationItems";
import {ProgressSpinner} from "primereact/progressspinner";
import {PrimeIcons} from "primereact/api";
import LigaAktionenComponent from "../tournament/tabs/LigaAktionenComponent";

class TurnierKratzerDetail extends React.Component {

    constructor(props) {
        super(props);
        this.state = {readonly: false, zeigeBeschreibungVoll: false,
            showKennwortDialog:false,kennwort:"", showAuslosungDialog: false, showAktionen: false,
            showDeleteConfirmDialog: false, showSpielbarePhaseDialog: false, showEditDialog: false};

        this.showInfo = this.showInfo.bind(this);
        this.showError = this.showError.bind(this);
        this.ladeDaten = this.ladeDaten.bind(this);
        this.ladeTurnierDaten = this.ladeTurnierDaten.bind(this);
        this.getSpielerName = this.getSpielerName.bind(this);
        this.getUsernamesByUserId = this.getUsernamesByUserId.bind(this);
        this.isSpielerInaktiv = this.isSpielerInaktiv.bind(this);
        this.starteTurnier = this.starteTurnier.bind(this);
    }

    componentDidMount() {
        this.ladeDaten();
    }

    showInfo(message) {
        this.toast.show({
            severity: 'success',
            summary: 'Erfolgreich...',
            detail: message
        })
    }

    showError(message) {
        this.messages.show({
            severity: 'error',
            sticky: 'true',
            summary: 'Fehler...',
            detail: message
        });
    }


    ladeDaten() {
        this.messages.clear();
        this.ladeTurnierDaten();
    }


    ladeTurnierDaten() {
        if (this.props.turnierid === null || this.props.turnierid === undefined) {
            return;
        }
        let url = ConfigUtil.getConfig().resourceUrls.tournament + "/kratzerturnier/" + this.props.turnierid;
        FetchUtil.fetchGet(url,
            json => {
                let readonly = false;//json.tournamentDto.tournamentstatus === "abgeschlossen";
                this.setState({turnier: json.turnier,
                    readonly: readonly,
                    gewinnerPlayerId: json.gewinnerPlayerId,
                    aktuelleRunde: json.aktuelleRunde,
                    tournamentKratzerPointsEntities: json.tournamentKratzerPointsEntities,
                    tournamentKratzerGames: json.tournamentKratzerGameEntities,
                    tournamentKratzerGamePlayers: json.tournamentKratzerGamePlayerEntities,
                    restpunkteDtos: json.restpunkteDtos,
                    ranglisteHighfinishes: json.ranglisteWrapper.ranglisteHighfinishes,
                    ranglisteHighscores: json.ranglisteWrapper.ranglisteHighscores,
                    rangliste180er: json.ranglisteWrapper.rangliste180er,
                    rangliste171er: json.ranglisteWrapper.rangliste171er,
                    bestDarts: json.ranglisteWrapper.bestDarts,
                    worstDarts: json.ranglisteWrapper.worstDarts,
                    statistik: json.ranglisteWrapper.statistikDto,
                    playerStatistiks: json.ranglisteWrapper.playerStatistiks,
                    boardGames: json.boardGames});
            },
            responseNotOk => {
                this.showError("Fehler beim Laden des Turniers: " + responseNotOk.message);
            },
            error => {
                this.showError("Fehler beim Laden des Turniers: " + error.message);
            });
    }

    getUsernamesByUserId(userId) {
        for (const username of this.state.usernames) {
            if (username.userId === userId) {
                return username;
            }
        }
        return undefined;
    }

    isAktuellerUserAdministrator() {
        if( this.state.turnier.erstellerUserId === this.props.userId) {
            return true;
        }
        for (const admin of this.state.turnier.administratoren) {
            if (admin.userId === this.props.userId) {
                return true;
            }
        }
        return false;
    }

    getModusRow(key, bezeichner, modusElement) {
        let wert = "";
        if( bezeichner !== null && bezeichner !== undefined) {
            wert += "Modus: " + bezeichner;
        }
        wert += " Gametype: " + modusElement.gameType + " - ";
        if( modusElement.startpunkte !== null) {
            wert += " Startpunkte: " + modusElement.startpunkte;
        }
        if( modusElement.modusid !== null) {
            wert += " Modus: " + getModusNameById(modusElement.modusid);
        }
        if( modusElement.zufallszahlen !== null) {
            wert += " Zufallsz.: " + (modusElement.zufallszahlen ? "ja" : "nein") + " - Cutthroat: " + (modusElement.cutthroat ? "ja" : "nein")
        }

        return <div key={key}>{wert} {modusElement.sets > 1 ? " - sets: " + modusElement.sets : ""} - ft legs {modusElement.firstToLegs}</div>
    }

    isSetModus() {
        let modusKratzer = this.state.turnier.tournamentModusEntities.find(element => element.modusTyp === "kratzer");
        return modusKratzer.sets > 1;
    }

    getTurnierDetailArea() {
        if (this.state.turnier === null || this.state.turnier === undefined) {
            return <div>Es sind noch keine Daten geladen...</div>
        }

        let turnier = this.state.turnier;
        let modusArray = [];
        let modusKratzer = turnier.tournamentModusEntities.find(element => element.modusTyp === "kratzer");
        modusArray.push(this.getModusRow("modus_kratzer", "", modusKratzer));

        let fixedColumWidth = 140;
        return <div style={{textAlign: "left"}}>
            <div className="grid" style={{marginTop: 10}}>
                <div className="col-12 md:col-12 lg:col-6">
                    <div className="grid">
                        <div className="col-fixed" style={{width: fixedColumWidth}}>Bezeichnung:</div>
                        <div className="col">{turnier.bezeichnung}</div>
                    </div>
                    {turnier.anmeldeende !== null && turnier.anmeldeende !== undefined &&
                        <div className="grid">
                            <div className="col-fixed" style={{width: fixedColumWidth}}>Anmeldeende:</div>
                            <div className="col">{turnier.anmeldeendeMitUhrzeitString}</div>
                        </div>
                    }
                    <div className="grid">
                        <div className="col-fixed" style={{width: fixedColumWidth}}>Startzeitpunkt:</div>
                        <div className="col">{turnier.beginnMitUhrzeitString}</div>
                    </div>
                    {this.getBeschreibungArea(fixedColumWidth)}
                    <div className="grid">
                        <div className="col-fixed" style={{width: fixedColumWidth}}>Ersteller:</div>
                        <div className="col">{turnier.erstellerName}</div>
                    </div>
                    <div className="grid">
                        <div className="col-fixed" style={{width: fixedColumWidth}}>Turnierleitung:</div>
                        <div className="col">{this.getAdministratoren()}</div>
                    </div>
                </div>
                <div className="col-12 md:col-12 lg:col-6">
                    <div className="grid">
                        <div className="col-fixed" style={{width: fixedColumWidth}}>Turnierstatus:</div>
                        <div className="col">{turnier.tournamentstatus}</div>
                    </div>
                    <div className="grid">
                        <div className="col">
                            {modusArray}
                        </div>
                    </div>
                    {(turnier.averageObergrenze !== null && turnier.averageObergrenze !== undefined) &&
                        <div className="grid">
                            <div className="col-fixed" style={{width: fixedColumWidth}}>Avg. Obergrenze:</div>
                            <div
                                className="col">{turnier.averageObergrenze}</div>
                        </div>
                    }
                    { turnier.rangliste &&
                        <div className="grid">
                            <div className="col-fixed" style={{width: fixedColumWidth}}>Ranglistenturnier:</div>
                            <div className="col">{turnier.rangliste ? "Ja" : "Nein"}</div>
                        </div>
                    }
                    <div className="grid">
                        <div className="col-fixed" style={{width: fixedColumWidth}}>Anzahl Spieler:</div>
                        <div
                            className="col">{turnier.tournamentPlayerEntities !== null && turnier.tournamentPlayerEntities !== undefined ? turnier.tournamentPlayerEntities.length : "0"}</div>
                    </div>
                    {(turnier.maxAnzahlTeilnehmer !== null && turnier.maxAnzahlTeilnehmer !== undefined) &&
                    <div className="grid">
                        <div className="col-fixed" style={{width: fixedColumWidth}}>Max. Anzahl Teilnehmer:</div>
                        <div
                            className="col">{turnier.maxAnzahlTeilnehmer}</div>
                    </div>
                    }

                </div>
            </div>
            <div className="grid" style={{marginTop:10}}>
                {turnier.tournamentstatus === "neu" && this.isAktuellerUserAdministrator() &&
                    <Button label="Turnier starten" icon="pi pi-align-justify"
                            onClick={() => this.setState({showAuslosungDialog: true})}
                            style={{height: 40, width: 350, marginRight: 10, marginTop:5}}/>
                }
                {this.isAktuellerUserAdministrator() &&
                    <Button label="Bearbeiten" icon="pi pi-pencil"
                            onClick={() => this.setState({showEditDialog: true})}
                            style={{height: 40, width: 300, marginRight: 10, marginTop:5}}/>
                }
                {this.isAktuellerUserAdministrator() &&
                    <Button label="Aktionen" icon={PrimeIcons.BOLT}
                            onClick={() => this.setState({showAktionen: true})}
                            style={{height: 40, width: 150, marginTop:5, marginRight: 5}}/>
                }
                <RefreshCounterComp text="Auto-Refresh" onRefresh={() => this.ladeDaten()}/>
            </div>
        </div>
    }

    getSpielerName(id) {
        if (id === undefined || this.state.turnier === null || this.state.turnier === undefined) {
            return "";
        }

        for (let i = 0; i < this.state.turnier.tournamentPlayerEntities.length; i++) {
            let spieler = this.state.turnier.tournamentPlayerEntities[i];
            if (spieler.id === id || spieler.userId === id || spieler.doppelpartnerId === id) {
                return spieler.name;
            }
        }
        return "Fehler";
    }

    isSpielerInaktiv(spielerid) {
        for (const player of this.state.turnier.tournamentPlayerEntities) {
            if (player.id === spielerid && player.inaktiv) {
                return true;
            }
        }
        return false;
    }

    getRanglisteArea() {
        return <TabelleRangliste players={this.state.turnier.ranglistePlayerEntities}/>
    }

    render() {
        let turnier = this.state.turnier;
        if (turnier === null || turnier === undefined) {
            return <div>
                <Toast ref={(el) => this.toast = el}/>
                <Messages ref={(el) => this.messages = el}/>
                <ProgressSpinner style={{width:100, height:100}}/>
            </div>
        }

        let isTurnierRunning = this.state.turnier.tournamentstatus !== "neu";

        return <div
            style={{textAlign: "center", align: "center", backgroundColor: "white", color: "black"}}>
            <Toast ref={(el) => this.toast = el}/>
            <Messages ref={(el) => this.messages = el}/>
            <div className="grid pageHeader" style={{padding: 0, margin: 0, backgroundColor: "#b3ccff"}}>
                <div className="col" style={{marginTop: 10, padding: 0}}>
                    Kratzer - Details
                </div>
            </div>
            <div style={{paddingRight: 20, paddingLeft: 20}}>
                {this.getTurnierDetailArea()}
            </div>

            <TabView style={{width: "100%", paddingTop:10, paddingLeft:10, paddingRight:10, border:"none"}}>
                <TabPanel header="Teilnehmer">
                    {this.getTeilnehmerArea()}
                </TabPanel>
                <TabPanel header="Games" disabled={!isTurnierRunning}>
                    <TournamentKratzerGames tournament={this.state.turnier} readOnly={!this.isAktuellerUserAdministrator()} aktuelleRunde={this.state.aktuelleRunde} gewinnerPlayerId={this.state.ggewinnerPlayerId}
                                            tournamentPlayers={this.state.turnier.tournamentPlayerEntities}
                                            tournamentKratzerGames={this.state.tournamentKratzerGames} tournamentKratzerGamePlayers={this.state.tournamentKratzerGamePlayers}
                                            restpunkteDtos={this.state.restpunkteDtos}
                                            onCallbackReload={this.ladeDaten}/>
                </TabPanel>
                {turnier.eventOperatorId !== null && turnier.eventOperatorId !== undefined &&
                    <TabPanel header="Links">
                        <TurnierQRCode eventOperatorId={turnier.eventOperatorId} tournamentId={turnier.id} tournamentSystem={turnier.tournamentstatus} onlineTournament={turnier.online} bezeichnung={turnier.bezeichnung}/>
                    </TabPanel>
                }
            </TabView>
            {this.state.showEditDialog && <TurnierVerwaltenDialog tournamentId={turnier.id}
                                                                  callBackRefresh={this.ladeTurnierDaten}
                                                                  callBackOnDelete={() => window.open(ConfigUtil.getBasicClientUrl() + NAVIGATION_ITEM.DASHBOARD.route, '_self')}
                                                                  callBackOnClose={() => this.setState({showEditDialog:false})}/>
            }
            {this.state.showAuslosungDialog &&
                <TournamentAuslosungDialog callBackOnNein={() => this.setState({showAuslosungDialog: false})}
                                           callBackOnJa={this.starteTurnier}
                                           disableStarten={this.state.auslosungLaeuft}
                                           tournament={this.state.turnier} />
            }
            {this.state.showAktionen && <LigaAktionenComponent showAsSidebar={true} onError={(message) => this.showError(message)}
                                                               onInfo={(message) => this.showInfo(message)}
                                                               onClearMessages={() => this.messages.clear()}
                                                               callBackRefresh={this.ladeDaten}
                                                               callBackCloseDialog={() => console.log("callBackCloseDialog nicht unterstützt")}
                                                               proTournament={this.state.turnier}
                                                               onClose={() => this.setState({showAktionen: false})}
                                                               callBackOnDelete={() => window.open(ConfigUtil.getBasicClientUrl() + NAVIGATION_ITEM.DASHBOARD.route, '_self')}
                                                               callBackLoadNewTournament={() => window.open(ConfigUtil.getBasicClientUrl() + NAVIGATION_ITEM.DASHBOARD.route, '_self')}
            />}
        </div>
    }

    starteTurnier(anzahlGames) {
        this.messages.clear();
        this.setState({auslosungLaeuft:true});
        let id = this.state.turnier.id;
        let data = {id: id, anzahlGames: anzahlGames};
        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.tournament + "/starttournament",
            data,
            json => {
                this.setState({showAuslosungDialog: false, showTournament: undefined, auslosungLaeuft:false});
                this.showInfo("Turnier wurde gestartet, erste Runde wurde ausgelost..");
                this.ladeDaten();
            },
            responseNotOk => {
                this.showError("Fehler beim Starten des Turnieres: " + responseNotOk.message);
                this.setState({auslosungLaeuft:false, showAuslosungDialog:false});
            },
            error => {
                this.showError("Fehler beim Starten des Turnieres: " + error.message);
                this.setState({auslosungLaeuft:false, showAuslosungDialog:false});
            }
        );
    }

    getAdministratoren() {
        if (this.state.turnier === null || this.state.turnier === undefined || this.state.turnier.administratoren === null || this.state.turnier.administratoren === undefined) {
            return "";
        }
        let administratoren = "";
        for (const administrator of this.state.turnier.administratoren) {
            if( administratoren !== "") {
                administratoren += " - ";
            }
            administratoren += administrator.name;
        }
        return administratoren;
    }

    getTeilnehmerArea() {
        return <LigaTeilnehmerArea liga={this.state.turnier}
                                   players={this.state.turnier.tournamentPlayerEntities}
                                   showEditButton={true}
                                   readonly={this.state.readonly}
                                   getUsernamesByUserId={() => ""}
                                   callBackRefresh={this.ladeDaten}
                                   isVerschiebenErlaubt={false}
                                   showUsernames={false}
                                   tournamentKratzerPoints={this.state.tournamentKratzerPointsEntities}
                                   isAdmin={this.isAktuellerUserAdministrator()}/>
    }

    getBeschreibungArea(fixedColumWidth) {
        if (this.state.turnier.beschreibung === null || this.state.turnier.beschreibung === undefined) {
            return "";
        }
        let beschreibungGekuerzt = false;
        let beschreibung;
        if (this.state.zeigeBeschreibungVoll || this.state.turnier.beschreibung.length <= 50) {
            beschreibung = this.state.turnier.beschreibung;
        } else {
            beschreibung = this.state.turnier.beschreibung.substring(0, 50);
            beschreibungGekuerzt = true;
        }
        return <div className="grid">
            <div className="col-fixed" style={{width: fixedColumWidth}}>Beschreibung:</div>
            <div className="col" style={{whiteSpace: "pre-line", cursor: "pointer"}}
                 onClick={() => this.setState({zeigeBeschreibungVoll: !this.state.zeigeBeschreibungVoll})}>
                {beschreibung}{beschreibungGekuerzt ? <span style={{color:"blue"}}><br/>... mehr Anzeigen ...</span> : ""}
            </div>
        </div>
    }
}

TurnierKratzerDetail.propTypes = {
    userId: PropTypes.string.isRequired,
    turnierid: PropTypes.string.isRequired,
};

export default TurnierKratzerDetail
