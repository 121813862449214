import React, {useEffect, useRef, useState} from 'react'
import VeranstalterVerwaltenDialog from "./VeranstalterVerwaltenDialog";
import FetchUtil from "../../../util/FetchUtil";
import ConfigUtil from "../../../util/ConfigUtil";
import {Messages} from "primereact/messages";
import {Button} from "primereact/button";
import ConfirmDialog from "../../general/ConfirmDialog";
import PropTypes from "prop-types";
import {Redirect} from "react-router-dom";
import {NAVIGATION_ITEM} from "../../../constants/navigationItems";

function VeranstalterVerwalten({onSave}) {

    const messages = useRef(null);

    let [goToDashboard, setGoToDashboard] = useState(false);
    let [showDeleteDialog, setShowDeleteDialog] = useState(false);
    let [showDialog, setShowDialog] = useState(false);
    let [veranstalterListe, setVeranstalterListe] = useState([]);
    let [selectedVeranstalter, setSelectedVeranstalter] = useState(false);

    useEffect(() => {
        load();
    }, []);

    const showError = (message) => {
        messages.current.show({severity: 'error', sticky: true, summary: 'Fehler...', detail: message});
    }

    const loescheVeranstalter = () => {
        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.eventoperator + "/loesche/" + selectedVeranstalter.id,
            {},
            () => {
                setShowDeleteDialog(false);
                load();
            },
            responseNotOk => {
                showError("Fehler beim Löschen: " + responseNotOk.message);
                setShowDeleteDialog(false);
            },
            error => {
                showError("Fehler beim Löschen: " + error.message);
                setShowDeleteDialog(false);
            });
    }

    const load = () => {
        FetchUtil.fetchGet(ConfigUtil.getConfig().resourceUrls.eventoperator + "/byCurrentUser",
            json => {
                setVeranstalterListe(json);
            },
            responseNotOk => {
                showError("Fehler beim Laden: " + responseNotOk.message);
            },
            error => {
                showError("Fehler beim Laden: " + error.message);
            });
    }

    const rows = () => {
        let tmp = [];
        for (const veranstalter of veranstalterListe) {
            tmp.push(<div key={"key_veranstalter_" + veranstalter.id}>
                    <hr/>
                    <div className="grid" style={{
                        alignItems: "center",
                        height: 80,
                        margin: 20
                    }}>
                        <div className="col"
                             style={{justifyContent: "left", alignItems: "center", display: "flex", fontSize: 30}}>
                            <Button icon="pi pi-pencil" tooltip="Bearbeiten" style={{marginRight: 5}}
                                    onClick={() => {
                                        setSelectedVeranstalter(veranstalter);
                                        setShowDialog(true);
                                    }}/>
                            <Button icon="pi pi-trash" tooltip="Löschen" style={{marginRight: 20}}
                                    onClick={() => {
                                        setSelectedVeranstalter(veranstalter);
                                        setShowDeleteDialog(true);
                                    }}/>
                            {veranstalter.name}
                        </div>
                    </div>
                </div>
            );
        }
        return tmp;
    }

    if (goToDashboard) {
        return <Redirect to={NAVIGATION_ITEM.DASHBOARD.route}/>;
    }

    return <div>
        <Messages ref={messages}></Messages>
        <div>
            <Button icon="pi pi-plus" label="Neuen Veranstalter anlegen"
                    onClick={() => {
                        setShowDialog(true);
                        setSelectedVeranstalter(undefined);
                    }}/>
        </div>
        <div style={{marginTop:20, marginBottom:10, fontSize:20}}>
            Hinweis: Mehrere Veranstalter sind nicht notwendig, wenn du nur unterschiedliche Turniere spielen willst. Lege mehrere Veranstalter nur an, wenn du für mehrere Vereine oder Veranstalter Turniere oder Ligen durchführen möchtest.
        </div>
        {rows()}
        {showDialog && <VeranstalterVerwaltenDialog eventOperatorID={selectedVeranstalter !== undefined ? selectedVeranstalter.id : undefined} onClose={() => setShowDialog(false)} onSave={() => {
            load();
            onSave();
        }}/>}
        {showDeleteDialog &&<ConfirmDialog visible={true}
                                    callBackOnNein={() => setShowDeleteDialog(false)}
                                    callBackOnJa={() => loescheVeranstalter()}
                                    header="Veranstalter Löschen"
                                    textOben="Du verlierst evtl. deinen Pro-Veranstalter Account und hast keinen Zugriff mehr auf Turniere und Ligen dieses Veranstalters."
                                    textUnten="Möchtest du den Veranstalter wirklich löschen?"/>}
    </div>
}

VeranstalterVerwalten.propTypes = {
    onSave: PropTypes.func.isRequired,
};

export default VeranstalterVerwalten;
