import React, {useEffect, useState} from "react";

import {Dialog} from 'primereact/dialog';
import {TableContainer} from "@mui/material/";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import {Button} from "primereact/button";
import {PrimeIcons} from "primereact/api";
import RanglisteDetailArea from "./RanglisteDetailArea";
import FetchUtil from "../../../util/FetchUtil";
import ConfigUtil from "../../../util/ConfigUtil";
import ConfirmDialog from "../../general/ConfirmDialog";
import ErrorInfoComponent from "../../general/ErrorInfoComponent";

export default function RanglistenDialog({eventOperatorId, onClose}) {

    const [infoMessage, setInfoMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);

    let [ranglisten, setRanglisten] = useState([]);
    let [selectedRangliste, setSelectedRangliste] = useState(undefined);
    let [selectedRanglisteId, setSelectedRanglisteId] = useState(undefined);

    useEffect(() => {
        ladeRanglisten();
    }, []);

    const loescheRangliste = () => {
        FetchUtil.fetchDelete(ConfigUtil.getConfig().resourceUrls.ranglisteNG + "/rangliste/" + selectedRanglisteId,
            {},
            id => {
                setSelectedRanglisteId(undefined);
                ladeRanglisten()
                setInfoMessage("Rangliste wurde gelöscht.");
            },
            responseNotOk => {
                setErrorMessage("Fehler: " + responseNotOk.message)
            },
            error => {
                setErrorMessage("Fehler: " + error.message)
            })
    }

    const ladeRanglisten = () => {
        FetchUtil.fetchGet(ConfigUtil.getConfig().resourceUrls.ranglisteNG + "/ranglisten/" + eventOperatorId,
            json => {
                setRanglisten(json);
            },
            responseNotOk => {
                setErrorMessage("Fehler: " + responseNotOk.message)
            },
            error => {
                setErrorMessage("Fehler: " + error.message)
            })
    }

    const Ranglistetabelle = () => {
        let rows = [];
        for (const rangliste of ranglisten) {
            rows.push(<TableRow key={"key_rangliste" + rangliste.id}>
                <TableCell style={{fontSize: 18, cursor:"pointer"}} onClick={() => setSelectedRangliste(rangliste)}>{rangliste.bezeichnung}</TableCell>
                <TableCell style={{fontSize: 18}}>
                    <Button icon="pi pi-trash" tooltip="Löschen" style={{marginRight: 5}} onClick={() => setSelectedRanglisteId(rangliste.id)}/>
                </TableCell>
            </TableRow>);
        }
        return <TableContainer style={{maxHeight: "100%", paddingBottom: 0}}>
            <Table style={{color: "white"}}>
                <TableHead>
                    <TableRow>
                        <TableCell style={{backgroundColor: "#ac1c27", color: "white", fontSize: 18}}>Bezeichnung</TableCell>
                        <TableCell style={{backgroundColor: "#ac1c27", color: "white", fontSize: 18, width:50}}>#</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows}
                </TableBody>
            </Table>
        </TableContainer>

    }

    const newRangliste = () => {
        setSelectedRangliste({});
    }

    const footer = (
        <div>
            <Button label="Abbrechen" icon="pi pi-times" onClick={() => onClose()} />
        </div>
    );

    return <Dialog baseZIndex={2000} header="Ranglisten" visible={true}
                   style={{width: 600, textAlign: "left"}}
                   footer={footer}
                   onHide={() => onClose()}>
        <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage} onClearInfoMessage={() => setInfoMessage(undefined)} onClearErrorMessage={() => setErrorMessage(undefined)} />
        {selectedRanglisteId !== undefined &&
            <ConfirmDialog visible={true}
                           callBackOnNein={() => setSelectedRanglisteId(undefined)}
                           callBackOnJa={() => loescheRangliste()}
                           header="Rangliste Löschen"
                           textOben="Nach dem Löschen ist die Rangliste nicht mehr sichtbar. Sofern diese Rangliste Turnieren zuordnet wurde, wird diese Verbindung gelöscht. Die Turniere sind danach keiner Rangliste mehr zugeordnet."
                           textUnten="Möchtest du die Rangliste wirklich löschen?"/>
        }
        {selectedRangliste !== undefined && <RanglisteDetailArea eventOperatorId={eventOperatorId} rangliste={selectedRangliste} onRefresh={() => ladeRanglisten()} onClose={() => setSelectedRangliste(undefined)} />}
        {selectedRangliste === undefined && <>
            <div style={{marginBottom:20}}>
                <Button label="Neu" icon={PrimeIcons.PLUS} onClick={() => newRangliste()} />
            </div>
            <Ranglistetabelle />
        </>
        }
    </Dialog>
}
