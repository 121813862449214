import React, {useEffect, useState} from "react";

import {Dialog} from 'primereact/dialog';
import {TableContainer} from "@mui/material/";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import {Button} from "primereact/button";
import {PrimeIcons} from "primereact/api";
import RanglisteProfilDetailArea from "./RanglisteProfileDetailArea";
import FetchUtil from "../../../util/FetchUtil";
import ConfigUtil from "../../../util/ConfigUtil";
import ConfirmDialog from "../../general/ConfirmDialog";
import ErrorInfoComponent from "../../general/ErrorInfoComponent";

export default function RanglistenProfileDialog({eventOperatorId, onClose}) {

    const [infoMessage, setInfoMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);

    let [profileList, setProfileList] = useState([]);
    let [selectedProfile, setSelectedProfile] = useState(undefined);
    let [selectedProfileId, setSelectedProfileId] = useState(undefined);

    useEffect(() => {
        ladeRanglistenProfiles();
    }, []);

    const loescheProfil = () => {
        FetchUtil.fetchDelete(ConfigUtil.getConfig().resourceUrls.ranglisteNG + "/profile/" + selectedProfileId,
            {},
            id => {
                setSelectedProfileId(undefined);
                ladeRanglistenProfiles()
                setInfoMessage("Ranglistenprofil wurde gelöscht.");
            },
            responseNotOk => {
                setErrorMessage("Fehler: " + responseNotOk.message)
            },
            error => {
                setErrorMessage("Fehler: " + error.message)
            })
    }

    const ladeRanglistenProfiles = () => {
        FetchUtil.fetchGet(ConfigUtil.getConfig().resourceUrls.ranglisteNG + "/ranglistenprofiles/" + eventOperatorId,
            json => {
                setProfileList(json);
            },
            responseNotOk => {
                setErrorMessage("Fehler: " + responseNotOk.message)
            },
            error => {
                setErrorMessage("Fehler: " + error.message)
            })
    }

    const Ranglistenprofiltabelle = () => {
        let rows = [];
        for (const profile of profileList) {
            rows.push(<TableRow key={"key_profil" + profile.id}>
                <TableCell style={{fontSize: 18, cursor:"pointer"}} onClick={() => setSelectedProfile(profile)}>{profile.bezeichnung}</TableCell>
                <TableCell style={{fontSize: 18}}>
                    <Button icon="pi pi-trash" tooltip="Löschen" style={{marginRight: 5}} onClick={() => setSelectedProfileId(profile.id)}/>
                </TableCell>
            </TableRow>);
        }
        return <TableContainer style={{maxHeight: "100%", paddingBottom: 0}}>
            <Table style={{color: "white"}}>
                <TableHead>
                    <TableRow>
                        <TableCell style={{backgroundColor: "#ac1c27", color: "white", fontSize: 18}}>Bezeichnung</TableCell>
                        <TableCell style={{backgroundColor: "#ac1c27", color: "white", fontSize: 18, width:50}}>#</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows}
                </TableBody>
            </Table>
        </TableContainer>

    }

    const newProfil = () => {
        setSelectedProfile({});
    }

    const footer = (
        <div>
            <Button label="Abbrechen" icon="pi pi-times" onClick={() => onClose()} />
        </div>
    );

    return <Dialog baseZIndex={2000} header="Ranglistenprofile" visible={true}
                   style={{width: 800, textAlign: "left"}}
                   footer={footer}
                   onHide={() => onClose()}>
        <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage} onClearInfoMessage={() => setInfoMessage(undefined)} onClearErrorMessage={() => setErrorMessage(undefined)} />
        {selectedProfileId !== undefined &&
            <ConfirmDialog visible={true}
                           callBackOnNein={() => setSelectedProfileId(undefined)}
                           callBackOnJa={() => loescheProfil()}
                           header="Ranglisteprofil löschen"
                           textOben="Das Ranglistenprofil ist evtl. Turnieren oder Turniergruppen zugewiesen. Diese Zuordnungen werden beim Löschen des Profils automatisch gelöscht. Sofern die Berechnung der Turniere bereits erfolgt ist, bleibt diese erhalten."
                           textUnten="Möchtest du das Ranglistenprofil wirklich löschen?"/>
        }
        {selectedProfile !== undefined && <RanglisteProfilDetailArea profile={selectedProfile} eventOperatorId={eventOperatorId}  onRefresh={() => ladeRanglistenProfiles()} onClose={() => setSelectedProfile(undefined)} />}
        {selectedProfile === undefined && <>
            <div style={{marginBottom:20}}>
                <Button label="Neu" icon={PrimeIcons.PLUS} onClick={() => newProfil()} />
            </div>
            <Ranglistenprofiltabelle />
        </>
        }
    </Dialog>
}
