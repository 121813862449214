import React, {useEffect, useState} from "react";

import {Dialog} from 'primereact/dialog';
import {Button} from "primereact/button";
import FetchUtil from "../../../util/FetchUtil";
import ConfigUtil from "../../../util/ConfigUtil";
import {showError} from "../../../actions/modal";
import {InputText} from "primereact/inputtext";
import {PrimeIcons} from "primereact/api";
import ConfirmDialog from "../../general/ConfirmDialog";

export default function WerbungenDialog({eventOperatorId, onClose}) {

    let [ werbungen, setWerbungen] = useState([]);

    let [ selectedDeleteId, setSelectedDeleteId] = useState(undefined);
    let [ imageLink, setImageLink] = useState("");
    let [ url, setUrl] = useState("");

    useEffect(() => {
        ladeWerbungen();
    }, [eventOperatorId]);


    const ladeWerbungen = () => {
        if( eventOperatorId === null || eventOperatorId === undefined) {
            return;
        }
        let url = ConfigUtil.getConfig().resourceUrls.werbung + "/" + eventOperatorId;
        FetchUtil.fetchGet(url,
            json => {
                setWerbungen(json)
            },
            responseNotOk => {
                showError("Fehler beim Laden" + responseNotOk.message);
            },
            error => {
                showError("Fehler beim Laden" + error.message);
        });
    }

    const save = () => {
        let data = {eventOperatorId: eventOperatorId, imageUrl: imageLink, url: url};
        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.werbung + "/save",
            data,
            json => {
                ladeWerbungen();
                setImageLink("");
                setUrl("");
            },
            responseNotOk => {
                showError("Fehler: " + responseNotOk.message);
            },
            error => {
                showError("Fehler: " + error.message);
            }
        );
    }

    const deleteWerbung = (id) => {
        let url = ConfigUtil.getConfig().resourceUrls.werbung + "/delete/" + eventOperatorId + "/" + id;
        FetchUtil.fetchDelete(url,
            {},
            json => {
                setSelectedDeleteId(undefined);
                ladeWerbungen();
            },
            responseNotOk => {
                showError("Fehler: " + responseNotOk.message);
            },
            error => {
                showError("Fehler: " + error.message);
            }
        );
    }

    const footer = (
        <div>
            <Button label="Abbrechen" icon="pi pi-times" onClick={() => onClose()} className="p-button-secondary" />
        </div>
    );

    const getRows = () => {
        let rows = [];
        for (const werbung of werbungen) {
            rows.push(<div key={"key_werbung_" + werbung.id} style={{marginBottom:10}}>
                <div>
                    <Button icon="pi pi-trash" tooltip="Löschen" style={{marginRight: 20}}
                                                         onClick={() => {
                                                             setSelectedDeleteId(werbung.id);
                                                         }}/>
                    ImageUrl: {werbung.imageUrl}<br/>
                    Ziel beim Klick: {werbung.url}<br/>
                </div>
                <div><img src={werbung.imageUrl} alt="Werbung" style={{width:310, height:50}}/>
                </div>
            </div>)
        }
        return rows;
    }

    return <Dialog baseZIndex={2000} header="Werbung" visible={true}
                   style={{width: 600, textAlign: "left"}}
                   footer={footer}
                   onHide={() => onClose()}>
        <div>Im Live-View kann Werbung angezeigt werden. Du kannst hier beliebig viele Werbepartner einfügen.</div>
        <div style={{marginTop:10}}>Aufgrund von möglichen Virenangriffen haben wir uns aktuell für eine sichere Lösung entschieden.
            Bitte lade dein Bild mit der Werbung auf deinen eigenen Webspace, deiner Cloud oder ähnliches hoch und gebe hier nur den Link dahin an.</div>
        <div>Sofern du keine Möglichkeit hast deine Werbedatei abzulegen, sende uns diese bitte per Mail an darthelfer@nl-soft.com. Wir prüfen die Datei und legen diese bei uns ab.</div>
        <div>Bitte vergesse nicht, uns deinen Veranstalternamen in der Mail mitzuteilen.</div>
        <div style={{fontWeight:"bold"}}>Achtung!!! Das Werbebild wird in der Größe 310x80 Pixel angezeigt und muss genau dieser Größe entsprechen.</div>
        <div style={{marginTop:10}}>
            Das Ändern eines Links ist aktuell nicht vorgesehen. Sofern dies notwendig wird, bitte Link löschen und neu anlegen.
        </div>
        <div style={{marginTop:20}}>
            Beispiel: https://darthelfer.de/images/darthelfer.svg<br/>
            <InputText value={imageLink} style={{width: "100%"}} placeholder="URL zum Bild" onChange={(e) => setImageLink(e.target.value)}/>
        </div>
        <div style={{marginTop:5}}>
            Beispiel: https://darthelfer.de<br/>
            <InputText value={url} style={{width: "100%"}} placeholder="URL - sobald auf das Bild geklickt wird" onChange={(e) => setUrl(e.target.value)}/>
        </div>
        <div style={{marginTop:5}}>
            <Button onClick={() => save()} icon={PrimeIcons.SAVE} label="Speichern" disabled={imageLink === ""}/>
        </div>
        <div style={{marginTop:20}}>
            {getRows()}
        </div>
        <ConfirmDialog visible={selectedDeleteId !== undefined}
                       callBackOnNein={() => setSelectedDeleteId(undefined)}
                       callBackOnJa={() => deleteWerbung(selectedDeleteId)}
                       header="Werbung löschen?"
                       textUnten="Möchtest du die Werbung wirklich löschen?"/>

    </Dialog>
}
