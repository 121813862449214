import React from "react";
import PropTypes from "prop-types";

import {Button} from 'primereact/button';
import {InputText} from "primereact/inputtext";
import {TOURNAMENTSYSTEMS} from "../../../constants/tournamentSystems";
import {InputNumber} from "primereact/inputnumber";
import {ToggleButton} from "primereact/togglebutton";
import ProgressButton from "../../general/ProgressButton";
import DHDialog from "../../general/DHDialog";
import DHSelectionComponent from "../../general/DHSeclectionCompontent";

const GRUPPE_FOLGE_MODUS_ZUR_AUSWAHL = [
    {name: 'Round Robin', code: 'round_robin'},
    {name: 'DKO', code: 'dko'},
];

class TournamentAuslosungDialog extends React.Component {

    constructor(props) {
        super(props);

        let anzSpieler = "";
        if( props.tournament.anzahlSpielerProGruppeFuerKOPhase ) {
            anzSpieler = props.tournament.anzahlSpielerProGruppeFuerKOPhase;
        }
        this.state = {anzahlGames: "", anzahlgruppen: "", anzahlspielerprogruppefuerkophase: anzSpieler,
            bestplatzierteNaechsteKommenWeiter: props.tournament.bestplatzierteNaechsteKommenWeiter,
            gruppeFolgeModus: undefined, freilose: []};
    }

    getBereichFuerLiga(anzahlSpieler, turniername) {
        if (this.props.tournament.turniersystem !== TOURNAMENTSYSTEMS.LIGA.id) {
            return "";
        }
        return <div style={{textAlign:"left", height:400}}>
            <div className="grid">
                <p style={{verticalAlign:"bottom"}}>Es sind <span style={{fontWeight:"bold", fontSize:25, marginRight:5, marginLeft:5}}>{anzahlSpieler}</span> Spieler für die Liga '{turniername}' angelegt.</p>
            </div>
            <div className="grid" style={{marginTop:"20px"}}>
                Bitte geben Sie die gewünschte Anzahl der zu erzeugenden Ligen an. Hierüber können Sie die Anzahl der Spieler in einer Liga beeinflussen.
                <br/>
                Beispiel: 28 Spieler sind vorhanden, Sie geben hier Anzahl 3 ein. In diesem Fall wird eine Liga mit 10 Spielern und zwei Ligen mit jeweils 9 Spieler gestartet.
                <br/>
                Die Ligen erhalten hinter Ihrer Ligabezeichnung eine Nummerierung - 1, -2 u. -3.
            </div>
            <div className="grid" style={{marginTop:"20px"}}>
                <div style={{float:"left", width:350}}>
                    Anzahl Ligen:
                </div>
                <div style={{float:"left"}}>
                    <InputText style={{width: "80px", marginRight: "5px"}} value={this.state.anzahlgruppen}
                               onChange={(e) => this.valueChange(e, "anzahlgruppen")}/>

                </div>
            </div>
        </div>;

    }

    getBereichDYP(anzahlSpieler, dyp) {
        if( dyp && anzahlSpieler % 2 !== 0) {
            return <div>Es sind {anzahlSpieler} Teilnehmer im Turnier vorhanden. Bei DYP muss die Anzahl der Spieler durch 2 teilbar sein. z.B. 8, 10, 12...</div>
        }
        return undefined;
    }

    getBereichFuerSchweizerSystem(anzahlSpieler) {
        if( this.props.tournament.turniersystem !== TOURNAMENTSYSTEMS.SCHWEIZERSYSTEM.id ) {
            return "";
        }
        if( anzahlSpieler === 0) {
            return <div>Turnier mit 0 Teilnehmern kann nicht gestartet werden.</div>
        }
        return <div style={{textAlign:"left", padding:20}}>
            <div style={{fontWeight:"bold"}}>
                Im Turnier sind {anzahlSpieler} Teilnehmer erfasst.
            </div>
            <div style={{marginTop: 20}}>
                Die Anzahl der Runden im Schweizer System kann in Darthelfer flexibel sein.<br/><br/>
                Es ist sinnvoll, maximal die Hälfte der Spieler als Runden zu haben.<br/><br/>
                z.B. bei 8 Spielern, 4 Runden.<br/><br/>
                Irgendwann wiederholen sich die Spiele bzgl. der Teilnehmer und ein Spieler trifft wiederholt auf einen anderen.<br/>
                Dies ist nicht Sinn des Schweizer Systems, du hast es jedoch selbst in der Hand dies zu verhindern.<br/><br/>
                Sobald du die Gruppenanzahl auf '1' stellst, kannst du nach deinen Runden eine KO Phase starten, wird eine Gruppenzahl Größer 1 gewählt, ist keine KO Phase möglich.<br/>
                In diesem Fall ist es möglich, dass du du eine Entschiedungsrunde startest.<br/>
                In diesem Fall wird der 1. aus Gruppe 1 gegen den 1. aus Gruppe 2 spielen. Gibt es noch mehr Gruppen, geht es so weiter.<br/>
            </div>
            <div style={{marginTop:20}}>
                Anzahl Gruppen:<br/>
                <InputNumber min={1} style={{width: "80px", marginRight: "5px"}} value={this.state.anzahlgruppen}
                             onChange={(e) => this.setState({anzahlgruppen: e.value})}/>
            </div>
        </div>;
    }

    getBereichFuerRoundRobin(anzahlSpieler, dyp) {
        if( this.props.tournament.turniersystem !== TOURNAMENTSYSTEMS.ROUND_ROBIN.id ) {
            return "";
        }
        if( this.props.tournament.turniersystem === TOURNAMENTSYSTEMS.ROUND_ROBIN.id && this.props.tournament.auslosungVonHand) {
            return "";
        }
        if( anzahlSpieler === 0) {
            return <div>Turnier mit 0 Teilnehmern kann nicht gestartet werden.</div>
        }
        let bereichDYP = this.getBereichDYP(anzahlSpieler, dyp);
        if( bereichDYP !== undefined) {
            return bereichDYP;
        }
        return <div style={{textAlign:"left", height:450}}>
            <div className="grid" style={{marginTop:"20px"}}>
                Beachte bitte, in Darthelfer können in einer Gruppe maximal 20 Spieler sein!
            </div>
            <div className="grid" style={{marginTop:"20px"}}>
                Bitte geben Sie die gewünschte Anzahl an Gruppen ein.
            </div>
            <div className="grid" style={{marginTop:"20px"}}>
                Anzahl Spieler: {anzahlSpieler} (ohne Warteliste) {dyp && " - nach Auslosung DYP " + (anzahlSpieler / 2) + " Doppelpaarungen"}
            </div>
            <div className="grid" style={{marginTop:"20px"}}>
                <div style={{fontWeight:"bold"}}>
                    Hinweis: Anzahl muss größer 0 und kleiner 33 sein.
                </div>
            </div>
            <div className="grid" style={{marginTop:10}}>
                <div style={{float:"left", width:350}}>
                    Anzahl Gruppen:
                </div>
                <div style={{float:"left"}}>
                    <InputText style={{width: "80px", marginRight: "5px"}} value={this.state.anzahlgruppen}
                               onChange={(e) => this.valueChange(e, "anzahlgruppen")}/>

                </div>
            </div>
            {this.getFreilosArea(anzahlSpieler, dyp)}
        </div>;
    }

    getFreilosArea(anzahlSpieler, dyp) {
        if( this.getAnzahlFreiloseDieVergebenWerdenMuessen(anzahlSpieler, dyp) === 0) {
            return "";
        }

        let buttons = [];
        for(let group = 1; group <= this.state.anzahlgruppen; group++) {
            buttons.push(<ToggleButton onLabel={""+group} offLabel={""+group} checked={this.isFreilos(group)}
                                    style={{marginRight:2}}
                                       onChange={() => this.isFreilos(group) ? this.removeFreilos(group) : this.addFreilos(group, anzahlSpieler, dyp)}/> )
        }
        return <div>
            <div className="grid" style={{marginTop:20}}>
                <div>Du musst {this.getAnzahlFreiloseDieVergebenWerdenMuessen(anzahlSpieler, dyp)} Freilos(e) für Gruppe(n) vergeben. Bitte markiere die Gruppe(n) die ein Freilos erhalten sollen. Die Gruppe(n) mit Freilos haben dann einen Spieler weniger.</div>
            </div>
            <div className="grid" style={{marginTop:10}}>
                <div>{buttons}</div>
            </div>
            <div className="grid" style={{marginTop:20}}>
                <div style={{color: this.state.freilose.length !== this.getAnzahlFreiloseDieVergebenWerdenMuessen(anzahlSpieler, dyp) ? "red": ""}}>Vergebene Freilose: {this.state.freilose.length}</div>
            </div>
        </div>
    }

    getAnzahlFreiloseDieVergebenWerdenMuessen(anzahlSpieler, dyp) {
        if( this.state.anzahlgruppen === "") {
            return 0;
        }
        let tmpAnzahlSpieler = dyp ? anzahlSpieler / 2 : anzahlSpieler;
        if( tmpAnzahlSpieler % this.state.anzahlgruppen === 0 ) {
            return 0
        } else {
            let wert = tmpAnzahlSpieler % this.state.anzahlgruppen;
            return this.state.anzahlgruppen - wert;
        }
    }

    addFreilos(group, anzahlSpieler, dyp) {
        if( this.state.freilose.length === this.getAnzahlFreiloseDieVergebenWerdenMuessen(anzahlSpieler, dyp)) {
            return;
        }
        let freilose = this.state.freilose;
        freilose.push(group);
        this.setState(freilose)
    }

    removeFreilos(group) {
        let freilose = this.state.freilose;
        let newFreilose = [];
        for (const freiloseElement of freilose) {
            if( freiloseElement !== group) {
                newFreilose.push(freiloseElement);
            }
        }
        this.setState({freilose:newFreilose});
    }

    isFreilos(group) {
        let freilose = this.state.freilose;
        return freilose.includes(group);
    }

    getHinweisGruppeAlsDKO() {
        if( this.state.gruppeFolgeModus !== undefined &&  this.state.gruppeFolgeModus === "dko") {
            return <div className="grid" style={{marginTop: 10, color: "red"}}>
                Hinweis: Sobald die Gruppenphase als DKO gespielt wird, können maximal 2 Teilnehmer in die KO Phase kommen.<br/><br/>
                Sobald du auswählst, dass eine Person weiterkommt, wird das Finale auf der Gewinnerseite gespielt und der 'Gruppengewinner' kommt weiter.<br/><br/>
                Sofern du auswählst, dass zwei Personen weiterkommen, wird das Finale der Gewinnerseite NICHT gespielt und damit nicht von der automatischen Boardvergabe aufgerufen.<br/>
                Der Gewinner der Verliererseite und der Gewinnerseite sind automatisch weiter.<br/>
                Natürlich kannst du das Spiel spielen lassen, allerdings ist es nicht relevant für das Weiterkommen.
            </div>
        }
        return "";
    }
    getBereichFuerGruppeDannFolgeturnier(anzahlSpieler, turniername, anzahlSpielerInDKOKOPhase, dyp) {
        if( this.props.tournament.turniersystem !== TOURNAMENTSYSTEMS.GRUPPE_DANN_KO.id && this.props.tournament.turniersystem !== TOURNAMENTSYSTEMS.GRUPPE_DANN_DKO.id ) {
            return "";
        }
        if( anzahlSpieler === 0) {
            return <div>Turnier mit 0 Teilnehmern kann nicht gestartet werden.</div>
        }
        let bereichDYP = this.getBereichDYP(anzahlSpieler, dyp);
        if( bereichDYP !== undefined) {
            return bereichDYP;
        }
        if( this.props.tournament.auslosungVonHand) {
            return <>
                <div className="grid" style={{marginTop:"20px"}}>
                    <div style={{float:"left", width:350}}>
                        Gruppe soll als Round Robin o. DKO gespielt werden:
                    </div>
                    <div style={{float:"left"}}>
                        <DHSelectionComponent value={this.state.gruppeFolgeModus} onChange={(code) => this.setState({gruppeFolgeModus: code})} values={GRUPPE_FOLGE_MODUS_ZUR_AUSWAHL} title="Folgemodus"/>
                    </div>
                </div>
                <div className="grid" style={{marginTop:"20px"}}>
                    <div style={{float:"left", width:350}}>
                        Anzahl Spieler pro Gruppe für KO/DKO Phase:
                    </div>
                    <div style={{float:"left"}}>
                        <InputText style={{width: "80px", marginRight: "5px"}} value={this.state.anzahlspielerprogruppefuerkophase}
                                   disabled={this.state.gruppeFolgeModus === undefined || this.state.gruppeFolgeModus === "" || this.state.gruppeFolgeModus === "dko" }
                                   onChange={(e) => this.valueChange(e, "anzahlspielerprogruppefuerkophase")}/>
                    </div>
                </div>
                {this.getHinweisGruppeAlsDKO()}
            </>
        }
        return <div style={{textAlign: "left"}}>
            <div className="grid">
                <p style={{verticalAlign: "bottom"}}>
                    Es sind <span style={{fontWeight: "bold", fontSize: 25, marginRight: 5, marginLeft: 5}}>{anzahlSpieler}</span>
                    Spieler (ohne Warteliste) für das Turnier '{turniername}' angelegt.
                    {dyp && " Nach Auslosung DYP " + (anzahlSpieler / 2) + " Doppelpaarungen"}
                </p>
            </div>
            <div className="grid" style={{marginTop: "20px"}}>
                Beachte bitte, in Darthelfer können in einer Gruppe maximal 20 Spieler sein!
            </div>
            <div className="grid" style={{marginTop: "20px"}}>
                Bitte geben Sie die gewünschte Anzahl an Gruppen und die Anzahl der Spieler die von der Gruppenphase in die KO/DKO Phase übernommen werden sollen ein.
            </div>
            <div className="grid" style={{marginTop: "20px"}}>
                <div style={{fontWeight: "bold"}}>
                    Hinweis: Anzahl muss größer 0 und kleiner 33 sein. Wir empfehlen im Zusammenspiel mit den Spielern die weiter kommen, eine Gruppenanzahl zu wählen die sinnvoll ist und keine
                    Freilose verursacht.
                </div>
            </div>
            <div className="grid" style={{marginTop: "20px"}}>
                <div style={{fontWeight: "bold"}}>
                    Hinweis: Wir empfehlen eine gerade Gruppenanzahl (z.B. 2,4,6,8 usw.). In diesem Fall wird ein KO/DKO abhängig von der Gruppentabelle über Kreuz zusammengestellt.
                </div>
            </div>
            <div className="grid" style={{marginTop: "20px"}}>
                <div style={{float: "left", width: 350}}>
                    Gruppe soll als Round Robin o. DKO gespielt werden:
                </div>
                <div style={{float: "left"}}>
                    <DHSelectionComponent value={this.state.gruppeFolgeModus} onChange={(code) => this.setState({gruppeFolgeModus: code})} values={GRUPPE_FOLGE_MODUS_ZUR_AUSWAHL} title="Folgemodus"/>
                </div>
            </div>
            <div className="grid" style={{marginTop: 10}}>
                <div style={{float: "left", width: 350}}>
                    Anzahl Gruppen:
                </div>
                <div style={{float: "left"}}>
                    <InputText style={{width: "80px", marginRight: "5px"}} value={this.state.anzahlgruppen}
                               onChange={(e) => this.valueChange(e, "anzahlgruppen")}/>

                </div>
            </div>
            <div className="grid" style={{marginTop: "20px"}}>
                <div style={{float: "left", width: 350}}>
                    Anzahl Spieler pro Gruppe für KO/DKO Phase:
                </div>
                <div style={{float: "left"}}>
                    <InputText style={{width: "80px", marginRight: "5px"}} value={this.state.anzahlspielerprogruppefuerkophase}
                               onChange={(e) => this.valueChange(e, "anzahlspielerprogruppefuerkophase")}/>
                </div>
            </div>
            {this.state.gruppeFolgeModus === "round_robin" &&
                <div className="grid" style={{marginTop: "20px"}}>
                    <div style={{float: "left", width: 350}}>
                        Bestplatzierte Nächste kommen weiter:
                    </div>
                    <div style={{float: "left"}}>
                        <ToggleButton
                            onLabel="Ja" offLabel="Nein"
                            checked={this.state.bestplatzierteNaechsteKommenWeiter}
                            onChange={() => this.setState({bestplatzierteNaechsteKommenWeiter: !this.state.bestplatzierteNaechsteKommenWeiter})}/>
                    </div>
                </div>
            }
            {this.getHinweisGruppeAlsDKO()}
            {this.getFreilosArea(anzahlSpieler, dyp)}
            <hr/>
            <div className="grid" style={{marginTop: "20px"}}>
                <div style={{float: "left"}}>
                    Nach der Turnierphase werden <span style={{fontWeight: "bold", fontSize: 25}}>{anzahlSpielerInDKOKOPhase}</span> Spieler in die KO/DKO Phase gehen.<br/>
                    (evtl. je nach Auswahl noch die nächsten Bestplatzierten).
                </div>
            </div>

            <div className="grid" style={{marginTop: "20px"}}>
                <div style={{float: "left", color: "red"}}>
                    - Nach der Gruppenphase sollten 4, 8, 16, 32, 64, 128... Spieler in die KO/DKO Phase übergehen.<br/>Sofern dies nicht der Fall ist, werden die Gruppenersten Freilose erhalten.
                </div>
            </div>
        </div>;
    }

    getBereichFuerKratzer(anzahlSpieler, turniername, dyp) {
        if (this.props.tournament.turniersystem !== TOURNAMENTSYSTEMS.KRATZER.id) {
            return "";
        }
        if (anzahlSpieler === 0) {
            return <div>Turnier mit 0 Teilnehmern kann nicht gestartet werden.</div>
        }
        let bereichDYP = this.getBereichDYP(anzahlSpieler, dyp);
        if (bereichDYP !== undefined) {
            return bereichDYP;
        }
        return <div style={{textAlign: "left", height: 400}}>
            <div className="grid">
                <p style={{verticalAlign: "bottom"}}>Es sind <span style={{fontWeight:"bold", fontSize:25, marginRight:5, marginLeft:5}}>{anzahlSpieler}</span> Spieler (ohne Warteliste) für das Turnier '{turniername}' angelegt.</p>
            </div>
            <div className="grid" style={{marginTop:"20px"}}>
                Bitte gebe die Anzahl der Spiele ein. Beispiel: du hast 40 Spieler und gibst Anzahl Games 10 ein, dann sind in jedem Game 4 Spieler.<br/>
                Sofern dir eine Auslosung bzgl. Aufteilung nicht gefällt, kannst du einfach eine neue Spielrunde auslosen.
            </div>
            <div className="grid" style={{marginTop:"20px"}}>
                <div style={{float:"left", width:350}}>
                    Anzahl Games:
                </div>
                <div style={{float:"left"}}>
                    <InputNumber min={1} style={{width: "80px", marginRight: "5px"}} value={this.state.anzahlGames}
                               onChange={(e) => this.setState({anzahlGames: e.value})}/>

                </div>
            </div>
        </div>;
    }

    render() {
        if( this.props.tournament === undefined) {
            return "";
        }
        let anzahlSpieler = "0";

        let players = this.props.tournament.tournamentPlayerEntities;
        if( players !== undefined && players !== null) {
            players = players.filter(player => !player.warteliste);
            anzahlSpieler = players.length;
        }

        let ausloesenDisabled;
        let richtigerWertBeiGruppen;
        if (this.props.tournament.turniersystem === TOURNAMENTSYSTEMS.SCHWEIZERSYSTEM.id) {
            const anzahlGruppen = this.state.anzahlgruppen;
            const intAnzahlGruppen = parseInt(anzahlGruppen);
            richtigerWertBeiGruppen = intAnzahlGruppen > 0 && intAnzahlGruppen < 33;
            ausloesenDisabled = !richtigerWertBeiGruppen;
        } else if (this.props.tournament.turniersystem === TOURNAMENTSYSTEMS.LIGA.id) {
            ausloesenDisabled = this.state.anzahlgruppen === "";
            richtigerWertBeiGruppen = true;
        } else if (this.props.tournament.turniersystem === TOURNAMENTSYSTEMS.KRATZER.id) {
            ausloesenDisabled = this.state.anzahlGames === null || this.state.anzahlGames === undefined || this.state.anzahlGames === "";
        } else if (this.props.tournament.turniersystem === TOURNAMENTSYSTEMS.ROUND_ROBIN.id && this.props.tournament.auslosungVonHand) {
            ausloesenDisabled = false;
            richtigerWertBeiGruppen = true;
        } else if (this.props.tournament.turniersystem === TOURNAMENTSYSTEMS.ROUND_ROBIN.id && !this.props.tournament.auslosungVonHand) {
            ausloesenDisabled = this.state.anzahlgruppen === "";
            const anzahlGruppen = this.state.anzahlgruppen;
            const intAnzahlGruppen = parseInt(anzahlGruppen);
            richtigerWertBeiGruppen = intAnzahlGruppen > 0 && intAnzahlGruppen < 33;
        } else if ((this.props.tournament.turniersystem === TOURNAMENTSYSTEMS.GRUPPE_DANN_DKO.id || this.props.tournament.turniersystem === TOURNAMENTSYSTEMS.GRUPPE_DANN_KO.id ) && this.props.tournament.auslosungVonHand) {
            ausloesenDisabled = this.state.anzahlspielerprogruppefuerkophase === "";
            richtigerWertBeiGruppen = this.state.gruppeFolgeModus !== undefined && this.state.gruppeFolgeModus !== "";
        } else {
            ausloesenDisabled = this.state.anzahlspielerprogruppefuerkophase === "" || this.state.anzahlgruppen === "";
            const anzahlGruppen = this.state.anzahlgruppen;
            const intAnzahlGruppen = parseInt(anzahlGruppen);
            richtigerWertBeiGruppen = intAnzahlGruppen > 0 && intAnzahlGruppen < 33
                && this.state.gruppeFolgeModus !== undefined && this.state.gruppeFolgeModus !== ""
                && this.state.freilose.length === this.getAnzahlFreiloseDieVergebenWerdenMuessen(anzahlSpieler, this.props.tournament.dyp);
        }

        if( this.props.disableStarten) {
            ausloesenDisabled = true;
        }

        let turniername = this.props.tournament.bezeichnung;
        let anzahlSpielerInDKOKOPhase = !ausloesenDisabled ? this.state.anzahlspielerprogruppefuerkophase * this.state.anzahlgruppen : "-";

        const footer = (
            <div>
                {this.props.tournament.turniersystem === TOURNAMENTSYSTEMS.KRATZER.id &&
                    <ProgressButton text="Auslosung starten" icon="pi pi-check"
                                    working={this.props.disableStarten}
                            disabled={ausloesenDisabled} onClick={() => this.props.callBackOnJa(this.state.anzahlGames)} />
                }
                {this.props.tournament.turniersystem === TOURNAMENTSYSTEMS.SCHWEIZERSYSTEM.id &&
                    <ProgressButton text="Auslosung starten" icon="pi pi-check"
                                    working={this.props.disableStarten}
                                    disabled={ausloesenDisabled}
                                    onClick={() => this.props.callBackOnJa(this.state.anzahlgruppen)} />
                }
                {(this.props.tournament.turniersystem !== TOURNAMENTSYSTEMS.KRATZER.id && this.props.tournament.turniersystem !== TOURNAMENTSYSTEMS.SCHWEIZERSYSTEM.id) &&
                    <ProgressButton text="Auslosung starten"
                                    icon="pi pi-check"
                                    disabled={ausloesenDisabled || !richtigerWertBeiGruppen}
                                    working={this.props.disableStarten}
                                    onClick={() => this.props.callBackOnJa(this.state.anzahlgruppen,
                                        this.state.anzahlspielerprogruppefuerkophase,
                                        this.state.bestplatzierteNaechsteKommenWeiter,
                                        this.state.gruppeFolgeModus !== undefined ? this.state.gruppeFolgeModus : undefined, this.state.freilose)}/>
                }
                <Button label="Nein" icon="pi pi-times" onClick={() => this.props.callBackOnNein()} className="p-button-secondary" />
            </div>
        );

        return <DHDialog title={"Auslosung starten"} onClose={() => this.props.callBackOnNein()}
                         buttons={footer} comp={<>
            {this.props.disableStarten && <div style={{color: "red"}}>Die Auslosung wird durchgeführt, dies kann etwas dauern!!!</div>}
            {this.getBereichFuerSchweizerSystem(anzahlSpieler)}
            {this.getBereichFuerKratzer(anzahlSpieler, turniername, this.props.tournament.dyp)}
            {this.getBereichFuerRoundRobin(anzahlSpieler, this.props.tournament.dyp)}
            {this.getBereichFuerGruppeDannFolgeturnier(anzahlSpieler, turniername, anzahlSpielerInDKOKOPhase, this.props.tournament.dyp)}
            {this.getBereichFuerLiga(anzahlSpieler, turniername)}
        </>}/>
    }

    valueChange(e, fieldName) {
        if (isNaN(e.target.value)) {
            return;
        }
        this.setState({[fieldName]: e.target.value, freilose: []})
    }
}


TournamentAuslosungDialog.propTypes = {
    visible: PropTypes.bool.isRequired,
    callBackOnNein: PropTypes.func.isRequired,
    callBackOnJa: PropTypes.func.isRequired,
    tournament: PropTypes.object.isRequired,
};

export default TournamentAuslosungDialog;
