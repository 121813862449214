import React from 'react'
import {Dialog} from "primereact/dialog";
import {Button} from "primereact/button";
import PropTypes from "prop-types";
import {Toast} from "primereact/toast";
import {Messages} from "primereact/messages";
import FetchUtil from "../../../util/FetchUtil";
import ConfigUtil from "../../../util/ConfigUtil";
import DateUtil from "../../../util/DateUtil";

class NotificationsDialog extends React.Component {

    constructor(props) {
        super(props);

        this.showError = this.showError.bind(this);
        this.alleGelesenMarkieren = this.alleGelesenMarkieren.bind(this);
        this.gelesenMarkieren = this.gelesenMarkieren.bind(this);
    }

    showError(message) {
        this.messages.show({
            severity: 'error',
            sticky: 'true',
            summary: 'Fehler...',
            detail: message
        });
    }

    getListe() {
        if( this.props.notifications === null || this.props.notifications === undefined) {
            return <div>Keine Benachrichtigungen vorhanden.</div>
        }
        let rows = [];
        for (const notification of this.props.notifications) {
            rows.push(<div style={{cursor:"pointer"}}
                           onClick={() => this.gelesenMarkieren(notification.id, notification.gelesen)}
            key={"key_notification_" + notification.id}>
                <span style={!notification.gelesen ? {fontWeight:"bold"} : {}}>{DateUtil.localDateTimeToString(notification.time)}<br/>{notification.text}</span>
                     <hr/>
            </div>)
        }
        return <div>
            {rows}
        </div>
    }

    alleGelesenMarkieren() {
        let url = ConfigUtil.getConfig().resourceUrls.notification + "/allegelesen";
        FetchUtil.fetchPost(url,
            {},
            json => {
                this.props.callBackCloseDialog();
            },
            responseNotOk => {
                this.showError("Fehler beim Lesen der Mitteilung: " + responseNotOk.message);
            },
            error => {
                this.showError("Fehler beim Lesen der Mitteilung " + error.message);
            });
    }

    gelesenMarkieren(notificationId, gelesen) {
        if( gelesen) {
            return;
        }
        let url = ConfigUtil.getConfig().resourceUrls.notification + "/gelesen?notificationId=" + notificationId;
        FetchUtil.fetchPost(url,
            {},
            json => {
            },
            responseNotOk => {
                this.showError("Fehler beim Lesen der Mitteilung: " + responseNotOk.message);
            },
            error => {
                this.showError("Fehler beim Lesen der Mitteilung " + error.message);
            });
    }

    render() {
        const dialogFooter = (
            <div>
                <Button label="Abbrechen" icon="pi pi-times" onClick={() => this.props.callBackCloseDialog()} className="p-button-secondary" />
            </div>
        );

        return <Dialog baseZIndex={2000} header="Benachrichtigungen" visible={this.props.visible}
                                   style={{width: 700, height:"100%", textAlign:"left"}} footer={dialogFooter} onHide={() => this.props.callBackCloseDialog()}>
            <Toast ref={(el) => this.toast = el}/>
            <Messages ref={(el) => this.messages = el}/>
            <div style={{marginTop:10, marginBottom:30, cursor:"pointer"}} onClick={() => this.alleGelesenMarkieren()}>Alle Benachrichtigungen als gelesen markieren...</div>
            {this.getListe()}
        </Dialog>
    }
}

NotificationsDialog.propTypes = {
    visible : PropTypes.bool.isRequired,
    notifications: PropTypes.array.isRequired,
    callBackCloseDialog: PropTypes.func.isRequired,
};

export default NotificationsDialog