import React from "react";
import PropTypes from "prop-types";

import {Dialog} from 'primereact/dialog';
import {Button} from 'primereact/button';
import {InputText} from "primereact/inputtext";

class ConfirmWithQuestionDialog extends React.Component {

    constructor() {
        super();
        this.state = {};
    }

    componentDidMount() {
        this.refresh();
    }

    refresh() {
        this.setState({confirmation: "", answer: "" + Math.floor(Math.random() * 100) + 1});
    }

    sendNein() {
        this.props.callBackOnNein()
        this.refresh();
    }

    sendJa() {
        this.props.callBackOnJa();
        this.refresh();
    }

    render() {
        let jadisabeld = this.state.confirmation !== this.state.answer;
        const footer = (
            <div>
                <Button label="Ja" icon="pi pi-check" disabled={jadisabeld} onClick={() => this.sendJa()} />
                <Button label="Nein" icon="pi pi-times" onClick={() => this.sendNein()} className="p-button-secondary" />
            </div>
        );

        return <Dialog baseZIndex={2000} header={this.props.header} visible={this.props.visible}
                       style={{width: '50vw'}} footer={footer} onHide={() => this.props.callBackOnNein()} maximizable>
            <div>
                {this.props.textOben}
            </div>
            <div style={{marginTop:"20px"}}>
                {this.props.textUnten}
            </div>
            <div style={{marginTop:"20px"}}>
                Bitte gebe zur Sicherheit folgende Zahl {this.state.answer} ein: <InputText value={this.state.confirmation} style={{width:50, marginLeft:10}} onChange={(e) => this.setState({confirmation: e.target.value})}/>
            </div>
        </Dialog>
    }
}


ConfirmWithQuestionDialog.propTypes = {
    header: PropTypes.string.isRequired,
    textOben: PropTypes.string.isRequired,
    textUnten: PropTypes.string.isRequired,
    visible: PropTypes.bool.isRequired,
    callBackOnNein: PropTypes.func.isRequired,
    callBackOnJa: PropTypes.func.isRequired,
};

export default ConfirmWithQuestionDialog;
