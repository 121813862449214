import React, {useEffect, useState} from 'react'
import ErrorInfoComponent from "../../../general/ErrorInfoComponent";
import FetchUtil from "../../../../util/FetchUtil";
import ConfigUtil from "../../../../util/ConfigUtil";
import {Button} from "primereact/button";
import {PrimeIcons} from "primereact/api";
import DHDialog from "../../../general/DHDialog";

export default function PublicLigaspielttagGamesTab({tournamentid, qrcode, counter}) {

    const [infoMessage, setInfoMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);

    let [selectedType, setSelectedType] = useState(undefined);
    let [selectedGameId, setSelectedGameId] = useState(undefined);
    let [selectedBereich, setSelectedBereich] = useState(1);
    let [aktuellerBereich, setAktuellerBereich] = useState(1);
    let [hoechsterBereich, setHoechsterBereich] = useState(1);
    let [games, setGames] = useState([]);
    let [players, setPlayers] = useState([]);
    let [showPlayers, setShowPlayers] = useState(false);

    useEffect(() => {
        if (tournamentid === null || tournamentid === undefined) {
            return;
        }
        ladeGames();
    }, [counter]);

    const savePlayer = (playerId, gameId, type) => {
        setErrorMessage(undefined);
        let data = {playerId: playerId, gameId: gameId, type: type};
        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.public + "/ligaspieltag/player/save/" + tournamentid + "/" + qrcode,
            data,
            (json) => {
                setInfoMessage("Spieler wurde gespeichert.");
                setGames(json.games);
                setAktuellerBereich(json.aktuellerBereich);
                setPlayers(json.players);
                setShowPlayers(false);
                setSelectedGameId(undefined);
            },
            responseNotOk => {
                setErrorMessage("Fehler: " + responseNotOk.message);
                setShowPlayers(false);
                setSelectedGameId(undefined);
            },
            error => {
                setErrorMessage("Fehler: " + error.message);
                setShowPlayers(false);
                setSelectedGameId(undefined);
            }
        );
    }

    const ladeGames = () => {
        setErrorMessage(undefined);
        FetchUtil.fetchGet(ConfigUtil.getConfig().resourceUrls.public + "/ligaspieltag/games/" + tournamentid + "/" + qrcode,
            (json) => {
                setGames(json.games);
                setAktuellerBereich(json.aktuellerBereich);
                setPlayers(json.players);
                setSelectedBereich(json.aktuellerBereich);
                setHoechsterBereich(json.hoechsterBereich);
            },
            responseNotOk => {
                setErrorMessage("Fehler: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler: " + error.message);
            }
        );
    }

    const getPlayerName = (playerId) => {
        if( playerId === null || playerId === undefined) {
            return "";
        }
        if( players.length === 0) {
            return "";
        }
        return players.filter(player => player.id === playerId)[0].name;
    }

    const playerAuswahlSidebar = () => {
        let rows = [];
        let color = true;
        for (const player of players) {
            if( player.idShortSinglePlayer1 !== null && player.idShortSinglePlayer1 !== undefined) {
                continue;
            }
            rows.push(<div key={"key_player_" + player.id}
                           style={{textAlign:"left", border:"1px solid #ddd", fontSize:20, padding:10, cursor:"pointer", backgroundColor: color ? "#f2f2f2" : "white"}}
                           onClick={() => savePlayer(player.id, selectedGameId, selectedType)}>
                {player.name}
            </div>);
            color = !color;
        }
        return <DHDialog onClose={() => setShowPlayers(false)} show={true} title={"Spielerauswahl"}
                         comp={<div style={{widht:350}}>
                             {rows}
        </div>}/>
    }


    const getGameEZArea = (game) => {
        let bereich;
        if( game.bereich < aktuellerBereich) {
            bereich = <span style={{marginLeft:20}}>{getPlayerName(game.player2Id)}</span>;
        } else {
            bereich = <>
                <Button icon={PrimeIcons.PENCIL} style={{marginRight:3}} onClick={() => {
                    setShowPlayers(true);
                    setSelectedGameId(game.id);
                    setSelectedType("einzel");
                }}/>
                {game.player2Id !== null && game.player2Id !== undefined &&
                    <Button icon={PrimeIcons.TRASH} onClick={() => savePlayer(undefined, game.id, "einzel")} style={{marginRight: 5}}/>
                }
                {game.player2Id !== null && game.player2Id !== undefined &&
                    <span>{getPlayerName(game.player2Id)}</span>
                }
            </>
        }

        return <div style={{marginTop: 5, marginBottom: 10}}>
            <div style={{fontWeight: "bold", marginBottom:10}}>Spiel: {game.counter}</div>
            <div style={{display:"flex", margin:"auto", verticalAlign:"middle", alignItems:"center"}}>
                {bereich}
            </div>
        </div>
    }

    const getGameDPArea = (game) => {
        let bereich;
        if( game.bereich < aktuellerBereich) {
            bereich = <span style={{marginLeft:20}}>{getPlayerName(game.doppelpartner2Player1Id)} / {getPlayerName(game.doppelpartner2Player2Id)}</span>;
        } else {
            bereich = <>
                <Button icon={PrimeIcons.PENCIL} style={{marginRight:3}} onClick={() => {
                    setShowPlayers(true);
                    setSelectedGameId(game.id);
                    setSelectedType("doppel1");
                }}/>
                {game.doppelpartner2Player1Id !== null && game.doppelpartner2Player1Id !== undefined &&
                    <Button icon={PrimeIcons.TRASH} onClick={() => savePlayer(undefined, game.id, "doppel1")} style={{marginRight: 5}}/>
                }
                {game.doppelpartner2Player1Id !== null && game.doppelpartner2Player1Id !== undefined &&
                    <span>{getPlayerName(game.doppelpartner2Player1Id)}</span>
                }
                <span style={{marginRight:8, marginLeft:5}}>/</span>
                <Button icon={PrimeIcons.PENCIL} style={{marginRight:3}} onClick={() => {
                    setShowPlayers(true);
                    setSelectedGameId(game.id);
                    setSelectedType("doppel2");
                }}/>
                {game.doppelpartner2Player2Id !== null && game.doppelpartner2Player2Id !== undefined &&
                    <Button icon={PrimeIcons.TRASH}
                            onClick={() => savePlayer(undefined, game.id, "doppel2")}
                            style={{marginRight: 5}}/>
                }
                {game.doppelpartner2Player2Id !== null && game.doppelpartner2Player2Id !== undefined &&
                    <span>{getPlayerName(game.doppelpartner2Player2Id)}</span>
                }

            </>
        }

        return <div style={{marginTop: 5, marginBottom: 10}}>
            <div style={{fontWeight: "bold", marginBottom:10}}>Spiel: {game.counter}</div>
            <div style={{display:"flex", margin:"auto", verticalAlign:"middle", alignItems:"center"}}>
                {bereich}
            </div>
        </div>
    }


    const getBereichArea = () => {
        let tmpGames = games.filter(game => game.bereich === selectedBereich);
        let rows = [];
        tmpGames.forEach(game => {
            if( game.type === "EZ") {
                let row = getGameEZArea(game);
                if( row !== undefined) {
                    rows.push(row);
                }
            } else if( game.type === "DP") {
                let row = getGameDPArea(game);
                if( row !== undefined) {
                    rows.push(row);
                }
            } else {
                rows.push(<div>Fehlerhafter Gametype, bitte Admin verständigen. LeagueGameId: {game.id}</div>);
            }
        });
        if( rows.length === 0) {
            return <div style={{marginTop:20}}>Keine Games vorhanden. Evtl. keine Phase gewählt oder Spieltag ist noch nicht gestartet.</div>
        }
        return rows;
    }

    const getBereichAuswahl = () => {
        let buttons = [];
        if( games.length === 0) {
            return buttons;
        }
        for( let bereich = 1; bereich <= hoechsterBereich ; bereich++) {
            buttons.push(<Button className={bereich === aktuellerBereich ? "p-button-danger" : ""}
                                 label={bereich + ""} onClick={() => setSelectedBereich(bereich)}
                                 style={{width:40, marginRight:5, border: selectedBereich === bereich ? "3px solid yellow" : "none"}}/> );
        }
        return buttons;
    }

    return <div className="darthelfer-background-blue">
        <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage}
                            onClearInfoMessage={() => setInfoMessage(undefined)}
                            onClearErrorMessage={() => setErrorMessage(undefined)}/>
        <div style={{display:"flex", margin:"auto", verticalAlign:"middle", alignItems:"center"}}>
            <span style={{marginRight:10}}>Phase:</span>{getBereichAuswahl()}
        </div>
        <div>
            {getBereichArea()}
        </div>
        {showPlayers && playerAuswahlSidebar()}
    </div>;
}
