import React from 'react'
import {Button} from 'primereact/button';
import {InputText} from 'primereact/inputtext';
import FetchUtil from "../../../util/FetchUtil";
import ConfigUtil from "../../../util/ConfigUtil";
import {Toast} from 'primereact/toast';
import {Messages} from 'primereact/messages';
import PropTypes from "prop-types";
import {Dialog} from "primereact/dialog";

const INITIAL_STATE = {
    selectedId: undefined,
    namegegenspieler: "",
    gegenspieler: [],
};

class GegenspielerVerwaltenDialog extends React.Component {

    constructor(props) {
        super(props);

        this.state = {...INITIAL_STATE};

        this.showError = this.showError.bind(this);
        this.showInfo = this.showInfo.bind(this);
        this.selectGegenspieler = this.selectGegenspieler.bind(this);
    }

    componentDidMount() {
        this.ladeDaten();
    }

    ladeDaten() {
        FetchUtil.fetchGet(ConfigUtil.getConfig().resourceUrls.gameday + "/" + this.props.spieltagid +"/ladegegenspieler",
            json => {
                this.setState({gegenspieler:json})
            },
            responseNotOk => {
                this.showError("Fehler beim Laden: " + responseNotOk.message);
            },
            error => {
                this.showError("Fehler beim Laden: " + error.message);
            });
    }

    showInfo(message) {
        this.toast.show({
            severity: 'success',
            summary: 'Erfolgreich...',
            detail: message
        })
    }

    showError(message) {
        this.messages.show({
            severity: 'error',
            sticky: 'true',
            summary: 'Fehler...',
            detail: message
        });
    }

    selectGegenspieler(id) {
        const aktuellerspieler = this.state.gegenspieler.find(spieler => spieler.id === id);
        this.setState({selectedId: aktuellerspieler.id, namegegenspieler: aktuellerspieler.name });
    }

    delete(id) {
        this.messages.clear();

        FetchUtil.fetchDelete(ConfigUtil.getConfig().resourceUrls.gameday + "/" + id +"/deletegegenspieler",
            {},
                gegenspielerliste => {
                let newState = {...INITIAL_STATE, gegenspieler: gegenspielerliste};
                this.setState(newState);
                this.props.callBackRefresh();
            },
            responseNotOk => {
                this.showError("Fehler beim Laden der Daten: " + responseNotOk.message);
            },
            error => {
                this.showError("Fehler beim Laden der Daten: " + error.message);
            }
        );
    }

    save() {
        this.messages.clear();

        let data = {id: this.state.selectedId, name: this.state.namegegenspieler};
        FetchUtil.fetchPut(ConfigUtil.getConfig().resourceUrls.gameday + "/" + this.props.spieltagid + "/savegegenspieler",
            data,
            gegenspielerliste => {
                let newState = {...INITIAL_STATE, gegenspieler: gegenspielerliste};
                this.setState(newState);
                this.showInfo("Gegenspieler wurde gespeichert");
                this.props.callBackRefresh();
            },
            responseNotOk => {
                this.showError("Fehler beim Speichern: " + responseNotOk.message);
            },
            error => {
                this.showError("Fehler beim Speichern: " + error.message);
            }
        );
    }

    handleChange(e) {
        this.setState({[e.target.name]: e.target.value});
    }

    hideDialog() {
        this.setState(INITIAL_STATE);
        this.props.callBackEditDialogClose();
    }

    render() {
        let gegenspielerRows = [];
        for (const tmp of this.state.gegenspieler) {
            gegenspielerRows.push(<div key={tmp.id}>
                <Button icon="pi pi-pencil" style={{marginLeft: 10, marginRight: 0, marginTop:10}} onClick={() => this.selectGegenspieler(tmp.id)}/>
                <Button icon="pi pi-times" style={{marginLeft: 10, marginRight: 10, marginTop:10}} onClick={() => this.delete(tmp.id)}/>
                {tmp.name}
            </div>);
        }
        let saveButtonDisabled = this.state.namegegenspieler === "";
        const dialogFooter = (
            <div>
                <Button label="Abbrechen" icon="pi pi-times" onClick={() => this.hideDialog()} className="p-button-secondary" />
            </div>
        );

        return <Dialog baseZIndex={2000} header="Gegenspieler verwalten" visible={this.props.visible}
                       style={{textAlign:"left", height:500, width:700}} footer={dialogFooter} onHide={() => this.hideDialog()} maximizable={true} maximized={true}>
            <Toast ref={(el) => this.toast = el}/>
            <Messages ref={(el) => this.messages = el}/>
            Sofern du dich bei einem Namen vertippt hast, lösche den Gegenspieler und lege ihn erneut an.
            <div style={{marginTop:20, marginBottom:20}}>
                <div className="grid">
                    <div className="col-fixed" style={{width: 230, textAlign: "left"}}>Name Gegenspieler:</div>
                    <div className="col" style={{textAlign: "left"}}>
                        <InputText name="namegegenspieler" style={{width: 250, marginRight:10}}
                                   value={this.state.namegegenspieler}
                                   onChange={(e) => this.handleChange(e)}/>
                        <Button label="Speichern" icon="pi pi-save" disabled={saveButtonDisabled} onClick={() => this.save()} />
                        <Button icon="pi pi-file-o" onClick={() => this.setState({selectedId: undefined, namegegenspieler:""})} style={{marginLeft:5, width: 30}}/>
                    </div>
                </div>
            </div>
            Vorhandene Gegenspieler
            {gegenspielerRows}
        </Dialog>
    }
}

GegenspielerVerwaltenDialog.propTypes = {
    visible: PropTypes.bool.isRequired,
    spieltagid: PropTypes.string.isRequired,
    callBackEditDialogClose: PropTypes.func.isRequired,
    callBackRefresh: PropTypes.func.isRequired,
};

export default GegenspielerVerwaltenDialog;