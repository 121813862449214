import React, {useRef, useState} from 'react'
import LigaTeilnehmerTable from "./ligaTeilnehmerTable";
import PropTypes from "prop-types";
import ConfirmDialog from "../../general/ConfirmDialog";
import FetchUtil from "../../../util/FetchUtil";
import ConfigUtil from "../../../util/ConfigUtil";
import ChangeLigaDialog from "./ChangeLigaDialog";
import VerwarnungDialog from "./VerwarnungDialog";
import {OverlayPanel} from "primereact/overlaypanel";
import {InputText} from "primereact/inputtext";
import TurnierPlayerDialog from "../tournament/TurnierPlayerDialog";
import {Button} from "primereact/button";
import ErrorInfoComponent from "../../general/ErrorInfoComponent";
import LigaTeilnehmerLegendeArea from "./LigaTeilnehmerLegendeArea";

function LigaTeilnehmerArea({
                                liga,
                                players,
                                showEditButton,
                                extendedLabel,
                                readonly,
                                showExternPersonId,
                                getUsernamesByUserId,
                                callBackRefresh,
                                isAdmin,
                                isVerschiebenErlaubt,
                                showUsernames,
                                tournamentKratzerPoints
                            }) {

    const [infoMessage, setInfoMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);

    const [showDeleteConfirmDialog, setShowDeleteConfirmDialog] = useState(false);
    const [selectedTournamentPlayerId, setSelectedTournamentPlayerId] = useState(undefined);
    const [selectedTournamentPlayerName, setSelectedTournamentPlayerName] = useState(undefined);
    const [showPlayerDialog, setShowPlayerDialog] = useState(false);
    const [showChangeLigaDialog, setShowChangeLigaDialog] = useState(false);
    const [selectedSpielerId, setSelectedSpielerId] = useState(undefined);
    const [verwarnungPlayerId, setVerwarnungPlayerId] = useState(undefined);
    const [verwarnungName, setVerwarnungName] = useState(undefined);
    const [filterText, setFilterText] = React.useState("");
    const [filterBezahlt, setFilterBezahlt] = React.useState(undefined);
    const [filterWarteliste, setFilterWarteliste] = React.useState(false);
    const [filterAnwesend, setFilterAnwesend] = React.useState(undefined);

    const legendeOP = useRef(null);
    const filterParticipantsOP = useRef(null);

    const filterTeilnehmer = () => {
        if( filterText === "" && filterBezahlt === undefined && filterAnwesend === undefined && !filterWarteliste) {
            return players;
        }
        let returnList = [];
        for (const teilnehmer of players) {
            let zufuegenAufgrundVonName = true;
            let zufuegenAufgrundVonIdShort = true;
            let zufuegenAufgrundVonBezahlt = true;
            let zufuegenAufgrundVonAnwesend = true;
            let zufuegenAufgrundVonWarteliste = true;
            if( filterText !== "") {
                if( liga.doppelturnier && !teilnehmer.name.toUpperCase().includes(filterText.toUpperCase())) {
                    zufuegenAufgrundVonName = false;
                } else if(!liga.doppelturnier) {
                    let nachname = teilnehmer.nachname !== null && teilnehmer.nachname !== undefined ? " , Name: " + teilnehmer.nachname : "";
                    let vorname = teilnehmer.vorname !== null && teilnehmer.vorname !== undefined ? " , Vorname: " + teilnehmer.vorname : "";
                    let name = teilnehmer.name + nachname + vorname;
                    if( !name.toUpperCase().includes(filterText.toUpperCase()) ) {
                        zufuegenAufgrundVonName = false;
                    }
                }
                if( !teilnehmer.idShort.toUpperCase().includes(filterText.toUpperCase())) {
                    zufuegenAufgrundVonIdShort = false;
                }
            }
            if( filterBezahlt !== undefined) {
                if( filterBezahlt === "ja" && !teilnehmer.bezahlt) {
                    zufuegenAufgrundVonBezahlt = false;
                } else if( filterBezahlt === "nein" && teilnehmer.bezahlt) {
                    zufuegenAufgrundVonBezahlt = false;
                }
            }
            if( filterAnwesend !== undefined) {
                if( filterAnwesend === "ja" && !teilnehmer.anwesend) {
                    zufuegenAufgrundVonAnwesend = false;
                } else if( filterAnwesend === "nein" && teilnehmer.anwesend) {
                    zufuegenAufgrundVonAnwesend = false;
                }
            }
            if( filterWarteliste && !teilnehmer.warteliste ) {
                zufuegenAufgrundVonWarteliste = false;
            }
            if( (zufuegenAufgrundVonName || zufuegenAufgrundVonIdShort ) && zufuegenAufgrundVonBezahlt && zufuegenAufgrundVonAnwesend && zufuegenAufgrundVonWarteliste ) {
                returnList.push(teilnehmer);
            }
        }
        return returnList;
    }

    const entferneTeilnehmer = () => {
        setErrorMessage(undefined);
        let tournamentid = liga.id;
        let url = ConfigUtil.getConfig().resourceUrls.tournament + "/teilnehmerEntfernen?tournamentid=" + tournamentid + "&tournamentPlayerId=" + selectedTournamentPlayerId;
        FetchUtil.fetchPost(url,
            {},
            json => {
                setInfoMessage("Teilnehmer wurde erfolgreich entfernt");
                setShowDeleteConfirmDialog(false);
                setSelectedTournamentPlayerId(undefined);
                setSelectedTournamentPlayerName(undefined);
                callBackRefresh();
            },
            responseNotOk => {
                setErrorMessage("Fehler beim Entfernen eines Teilnehmers: " + responseNotOk.message);
                setShowDeleteConfirmDialog(false);
                setSelectedTournamentPlayerId(undefined);
                setSelectedTournamentPlayerName(undefined);
            },
            error => {
                setErrorMessage("Fehler beim Entfernen eines Teilnehmers: " + error.message);
                setShowDeleteConfirmDialog(false);
                setSelectedTournamentPlayerId(undefined);
                setSelectedTournamentPlayerName(undefined);
            }
        );
    }

    const showVerwarnungDialog = (playerId, name) => {
        setVerwarnungPlayerId(playerId);
        setVerwarnungName(name);
    }

    const closeVerwarnungDialog = () => {
        setVerwarnungPlayerId(undefined);
        setVerwarnungName(undefined);
    }

    const changeAnwesend = (playerid) => {
        setErrorMessage(undefined);
        let url = ConfigUtil.getConfig().resourceUrls.tournament + "/changeanwesend/" + playerid
        FetchUtil.fetchPost(url,
            {},
            json => {
                setInfoMessage("Anwesendheit wurde geändert");
                callBackRefresh();
            },
            responseNotOk => {
                setErrorMessage("Fehler: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler: " + error.message);
            }
        );
    }

    const bezahltMarkieren = (playerid) => {
        setErrorMessage(undefined);
        let url = ConfigUtil.getConfig().resourceUrls.tournament + "/changeplayerbezahlt/" + playerid
        FetchUtil.fetchPost(url,
            {},
            json => {
                setInfoMessage("Teilnehmer bezahlt wurde geändert");
                callBackRefresh();
            },
            responseNotOk => {
                setErrorMessage("Fehler: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler: " + error.message);
            }
        );
    }

    return <div>
        <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage} onClearInfoMessage={() => setInfoMessage(undefined)} onClearErrorMessage={() => setErrorMessage(undefined)} />
        {!liga.online && liga.tournamentstatus !== "abgeschlossen" && isAdmin && showEditButton &&
            <div style={{marginTop: 10, textAlign: "left"}}>
                <Button icon="pi pi-user-edit" label="Teilnehmer verwalten/bearbeiten"
                        onClick={() => setShowPlayerDialog(true)}/>
            </div>
        }
        {extendedLabel !== undefined &&
            <div><h2>{extendedLabel}</h2></div>
        }
        <div style={{display: "flex", marginTop: 20, marginLeft: 15, marginBottom: 10}}>
            <OverlayPanel ref={filterParticipantsOP}
                          showCloseIcon={false} dismissable={true}
                          style={{height: 250, width: 300}}>
                <div style={{marginTop: 20}}>
                        <span className="p-float-label">
                            <InputText id="filterText" value={filterText}
                                       onChange={(e) => setFilterText(e.target.value)}/>
                            <label htmlFor="filterText">Name</label>
                        </span>
                </div>
                {isAdmin && <>
                    <div style={{
                        display: "flex",
                        paddingTop: 20,
                        margin: "auto",
                        verticalAlign: "middle",
                        alignItems: "center"
                    }}>
                        {filterBezahlt === undefined && <>
                            <img src="/images/euro_durchgestrichen.png"
                                 style={{width: 40, height: 40, cursor: "pointer", marginRight: 20}} alt="Filter"
                                 onClick={() => setFilterBezahlt("ja")}/>
                            <span onClick={() => setFilterBezahlt("ja")}>Kein Filter</span>
                        </>
                        }
                        {filterBezahlt === "ja" && <>
                            <img src="/images/euro_green.png"
                                 style={{width: 40, height: 40, cursor: "pointer", marginRight: 20}} alt="Filter"
                                 onClick={() => setFilterBezahlt("nein")}/>
                            <span onClick={() => setFilterBezahlt("nein")}>Nur bezahlte</span>
                        </>
                        }
                        {filterBezahlt === "nein" && <>
                            <img src="/images/euro_black.png"
                                 style={{width: 40, height: 40, cursor: "pointer", marginRight: 20}} alt="Filter"
                                 onClick={() => setFilterBezahlt(undefined)}/>
                            <span onClick={() => setFilterBezahlt(undefined)}>nur nicht bezahlte</span>
                        </>
                        }
                    </div>
                    <div style={{
                        display: "flex",
                        paddingTop: 20,
                        margin: "auto",
                        verticalAlign: "middle",
                        alignItems: "center"
                    }}>
                        {filterAnwesend === undefined && <>
                            <img src="/images/anwesend_filter.png"
                                 style={{width: 40, height: 40, cursor: "pointer", marginRight: 20}} alt="Filter"
                                 onClick={() => setFilterAnwesend("ja")}/>
                            <span onClick={() => setFilterAnwesend("ja")}>Kein Filter</span>
                        </>
                        }
                        {filterAnwesend === "ja" && <>
                            <img src="/images/anwesend_color.png"
                                 style={{width: 40, height: 40, cursor: "pointer", marginRight: 20}} alt="Filter"
                                 onClick={() => setFilterAnwesend("nein")}/>
                            <span onClick={() => setFilterAnwesend("nein")}>Nur anwesend</span>
                        </>
                        }
                        {filterAnwesend === "nein" && <>
                            <img src="/images/anwesend_black.png"
                                 style={{width: 40, height: 40, cursor: "pointer", marginRight: 20}} alt="Filter"
                                 onClick={() => setFilterAnwesend(undefined)}/>
                            <span onClick={() => setFilterAnwesend(undefined)}>nur nicht anwesend</span>
                        </>
                        }
                    </div>
                    <div style={{
                        display: "flex",
                        paddingTop: 20,
                        margin: "auto",
                        verticalAlign: "middle",
                        alignItems: "center"
                    }}>
                        <img src={filterWarteliste ? "/images/warteliste.png" : "/images/warteliste_nein.png"}
                             style={{width: 40, height: 40, cursor: "pointer", marginRight: 20}} alt="Filter"
                             onClick={() => setFilterWarteliste(!filterWarteliste)}/>
                        <span onClick={() => setFilterWarteliste(!filterWarteliste)}>{filterWarteliste ? "Filter Warteliste aktiv" : "Filter Warteliste NICHT aktiv"}</span>
                    </div>
                </>
                }
                <div style={{marginTop: 20}}>
                        <span className="p-float-label">
                            <Button label="Filter zurücksetzen" className="p-button-link" onClick={() => {
                                setFilterText("");
                                setFilterBezahlt(undefined);
                            }
                            }/>
                        </span>
                </div>
            </OverlayPanel>
            <div style={{marginRight: 10, fontSize: 20}}>Anzahl der
                Teilnehmer: {players.length}</div>
            <i className="pi pi-filter" style={{width: 40, height: 30, fontSize: 30}}
               onClick={(e) => filterParticipantsOP.current.toggle(e)}/>
            {filterText !== "" &&
                <div style={{
                    marginRight: 10,
                    fontSize: 20
                }}>Gefiltert: {filterTeilnehmer().length}</div>
            }
            <OverlayPanel ref={legendeOP}
                          showCloseIcon={false} dismissable={true}>
                <LigaTeilnehmerLegendeArea/>
            </OverlayPanel>
            {isAdmin && !liga.online &&
                <i className="pi pi-info-circle"
                   style={{width: 40, height: 30, fontSize: 30, marginLeft: 40, cursor: "pointer"}}
                   onClick={(e) => legendeOP.current.toggle(e)}/>
            }
        </div>
        <LigaTeilnehmerTable liga={liga}
                             rows={filterTeilnehmer()}
                             readonly={readonly}
                             getUsernamesByUserId={getUsernamesByUserId}
                             isAdmin={isAdmin}
                             showExternPersonId={showExternPersonId}
                             bezahltMarkieren={bezahltMarkieren}
                             onChangeAnwesend={changeAnwesend}
                             verwarnungErteilen={showVerwarnungDialog}
                             showUsernames={showUsernames}
                             tournamentKratzerPoints={tournamentKratzerPoints}
                             isVerschiebenErlaubt={isVerschiebenErlaubt}
                             showDeleteConfirmDialog={(tournamentPlayerId) => {
                                 setShowDeleteConfirmDialog(true);
                                 setSelectedTournamentPlayerId(tournamentPlayerId);
                             }}
                             showChangeLigaDialog={(tournamentPlayerId, tournamentPlayerName) => {
                                setShowChangeLigaDialog(true);
                                setSelectedTournamentPlayerId(tournamentPlayerId);
                                setSelectedTournamentPlayerName(tournamentPlayerName);
                             }}/>
        {showDeleteConfirmDialog &&
            <ConfirmDialog visible={true}
                           callBackOnNein={() => {
                               setShowDeleteConfirmDialog(false);
                               setSelectedTournamentPlayerId(undefined);
                               setSelectedTournamentPlayerName(undefined);
                           }}
                           callBackOnJa={entferneTeilnehmer}
                           header="Teilnehmer entfernen"
                           textOben="Auswirkung auf das Entfernen eines Teilnehmers: Ist die Liga / das Turnier noch nicht gestartet, wird der Teilnehmer entfernt. Sofern die Liga / das Turnier bereits gestartet war, werden seine Ergebnisse gelöscht und er wird auf für die Liga / das Turnier auf inaktiv geschaltet."
                           textUnten="Möchtest du den Teilnehmer wirklich entfernen?"/>
        }
        {showChangeLigaDialog &&
            <ChangeLigaDialog liga={liga} callBackRefresh={callBackRefresh}
                              callBackCloseDialog={() => setShowChangeLigaDialog(false)}
                              visible={true}
                              teilnehmername={selectedTournamentPlayerName}
                              playerid={selectedTournamentPlayerId}/>
        }
        {verwarnungPlayerId !== undefined &&
            <VerwarnungDialog name={verwarnungName} playerId={verwarnungPlayerId}
                              onClose={() => closeVerwarnungDialog()} onSave={() => callBackRefresh()}/>
        }
        {showPlayerDialog && <TurnierPlayerDialog tournament={liga}
                                                  visible={true}
                                                  callBackRefresh={() => callBackRefresh()}
                                                  callBackPlayerEditDialogClose={() => setShowPlayerDialog(false)}/>
        }

    </div>
}


LigaTeilnehmerArea.propTypes = {
    liga: PropTypes.object.isRequired,
    readonly: PropTypes.bool.isRequired,
    getUsernamesByUserId: PropTypes.func.isRequired,
    callBackRefresh: PropTypes.func.isRequired,
    isAdmin: PropTypes.bool.isRequired,
    isVerschiebenErlaubt: PropTypes.bool.isRequired,
    showUsernames: PropTypes.bool.isRequired,
    tournamentKratzerPoints: PropTypes.array
};

export default LigaTeilnehmerArea;
