import React from 'react'
import {Button} from 'primereact/button';
import {InputText} from 'primereact/inputtext';
import FetchUtil from "../../../util/FetchUtil";
import ConfigUtil from "../../../util/ConfigUtil";
import {Toast} from 'primereact/toast';
import {Messages} from 'primereact/messages';
import PropTypes from "prop-types";
import {Dialog} from "primereact/dialog";
import SelectPlayerComponent from "../../general/SelectPlayerComponent";

import {ToggleButton} from 'primereact/togglebutton';

const INITIAL_STATE = {
    id: undefined,
    spieler: undefined,
    gegenspieler: "",
    active: true,
};

class SpieltagGameEditDialog extends React.Component {

    constructor(props) {
        super(props);

        let spieltagid = this.props.spieltag !== undefined ? this.props.spieltag.id : undefined;
        this.state = {...INITIAL_STATE, spieltagid: spieltagid};

        this.showError = this.showError.bind(this);
        this.showInfo = this.showInfo.bind(this);
    }

    showInfo(message) {
        this.toast.show({
            severity: 'success',
            summary: 'Erfolgreich...',
            detail: message
        })
    }

    showError(message) {
        this.messages.show({
            severity: 'error',
            sticky: 'true',
            summary: 'Fehler...',
            detail: message
        });
    }

    save() {
        this.messages.clear();
        let id = this.state.id;

        let data = {id: id, spieler1Id: this.state.spieler.id, spieler2Name: this.state.gegenspieler, active: this.state.active};
        FetchUtil.fetchPut(ConfigUtil.getConfig().resourceUrls.gameday + "/" + this.props.spieltagid + "/game",
            data,
            id => {
                this.setState({id: id});
                this.showInfo("Spiel gespeichert.");
                this.props.callBackRefresh();
            },
            responseNotOk => {
                this.showError("Fehler beim Speichern: " + responseNotOk.message);
            },
            error => {
                this.showError("Fehler beim Speichern: " + error.message);
            }
        );
    }

    handleChange(e) {
        this.setState({[e.target.name]: e.target.value});
    }

    hideDialog() {
        this.setState(INITIAL_STATE);
        this.props.callBackEditDialogClose();
    }

    render() {
        let gegenspielerArray = [];
        for (const gegenspielerElement of this.props.gegenspieler) {
            gegenspielerArray.push(<Button label={gegenspielerElement.name}
                    key={gegenspielerElement.id}
                    onClick={(e) => this.setState({gegenspieler: gegenspielerElement.name})}
                    className="p-button-secondary"
                    style={{height: "40px", marginLeft: 10, marginBottom: 10}}/>
            );
        }
        let saveButtonDisabled = !this.state.spieler;
        const dialogFooter = (
            <div>
                <Button label="Speichern" icon="pi pi-save" disabled={saveButtonDisabled} onClick={() => this.save()} />
                <Button label="Neues Spiel anlegen" disabled={!this.state.id} onClick={() => this.setState(INITIAL_STATE)} style={{width: "300px", marginTop:"5px"}}/>
                <Button label="Abbrechen" icon="pi pi-times" onClick={() => this.hideDialog()} className="p-button-secondary" />
            </div>
        );

        return <Dialog baseZIndex={2000} header="Spiel anlegen / bearbeiten" visible={this.props.visible}
                       style={{textAlign:"left", height:500, width:700}} footer={dialogFooter} onHide={() => this.hideDialog()} maximizable={true} maximized={true}>
            <Toast ref={(el) => this.toast = el}/>
            <Messages ref={(el) => this.messages = el}/>
            <div>
                <div className="grid" style={{marginTop:10}}>
                    <div className="col" style={{textAlign: "left"}}>
                        <SelectPlayerComponent
                            player={this.state.spieler}
                            selectButtonLabel="Spieler auswählen"
                            callBackSelectFunc={(spieler) => {
                                this.setState({spieler: spieler})
                            }}
                            callBackDeleteFunc={() => this.setState({spieler: undefined})}
                            callBackOnErrorFunc={this.showError}/>
                    </div>
                </div>
                <div className="grid">
                    <div className="col-fixed" style={{width: 230, textAlign: "left"}}>Name Gegenspieler (optional):</div>
                    <div className="col" style={{textAlign: "left"}}>
                        <InputText name="gegenspieler" style={{width: 300}}
                                   value={this.state.gegenspieler}
                                   onChange={(e) => this.handleChange(e)}/>
                    </div>
                </div>
                <div className="grid" style={{marginTop:10}}>
                    <div className="col" style={{textAlign: "left"}}>
                        Auswahl Gegenspieler:{gegenspielerArray}
                    </div>
                </div>
                <div className="grid">
                    <div className="col-fixed" style={{width: 230, textAlign: "left"}}>Aktiv:</div>
                    <div className="col" style={{textAlign: "left"}}>
                        <ToggleButton checked={this.state.active} onLabel="Ja" offLabel="Nein" onChange={(e) => this.setState({active: e.value})} />
                    </div>
                </div>
            </div>
        </Dialog>
    }
}

SpieltagGameEditDialog.propTypes = {
    visible: PropTypes.bool.isRequired,
    spieltagid: PropTypes.string.isRequired,
    gegenspieler: PropTypes.array,
    callBackEditDialogClose: PropTypes.func.isRequired,
    callBackRefresh: PropTypes.func.isRequired,
};

export default SpieltagGameEditDialog;