import React from 'react'
import {Toast} from 'primereact/toast';
import {Messages} from 'primereact/messages';

class WasKannDarthelfer extends React.Component {

    render() {
        return <div className="col-12 md:col-12 lg:col-6" style={{padding:10}}>
            <div className="kachelStartseite" style={{height:"100%"}}>
                <Toast ref={(el) => this.toast = el}/>
                <Messages ref={(el) => this.messages = el}/>
                <h3>Was kann darthelfer.de:</h3>
                <ul>
                    <div style={{fontWeight:"bold", marginBottom:5}}>Veranstalter</div>
                    <li>Verwalte deine Turniere. Offline (Kneipe/Zuhause) oder Online mit deinen Freunden in der ganzen Welt.</li>
                    <li>Kostenlose Turnier-/Ligaverwaltung. Erweiterte Verwaltung mit Bezahlung.</li>
                    <li>Kostenloser Scorer für deine Kneipe oder für dich zuhause.</li>
                    <li>Automatische Ergebnissübernahme für einen vollautomatischen Turnier-/Ligaablauf.</li>
                    <li>Liveanzeige deiner aktuellen Turnier-/Ligaspiele.</li>
                    <li>Statistiken zu deinen Turnieren/Ligen.</li>
                    <li>Einfache Kostenübersicht, keine versteckten Kosten oder nachträglichen Rechnungen.</li>
                    <li>u.v.m.</li>
                    <div style={{fontWeight:"bold", marginBottom:5, marginTop:10}}>Ligabetreiber</div>
                    <li>Onlinegaming auf Laptop/PC/Handy/Tablet.</li>
                    <li>Webcamunterstützung für interaktive Onlinegames.</li>
                    <li>Ausrichter für Onlineligen/-turnieren (KO, DKO, Gruppenphase dann KO/DKO u. Weekendleague).</li>
                    <li>Statistiken/Auswertungen über alle Games.</li>
                    <li>Teilnahmegebühren können über DH per PayPal gezahlt werden. Alternativ per Rechnung.</li>
                    <li>u.v.m.</li>
                    <div style={{fontWeight:"bold", marginBottom:5, marginTop:10}}>Onlinegamer</div>
                    <li>Du kannst kostenlos Onlinegames (Laptop/PC/Handy/Tablet) spielen.</li>
                    <li>Spielen mit einem Gast (muss nicht angemeldet sein).</li>
                    <li>u.v.m.</li>
                    <div style={{fontWeight:"bold", marginTop:10}}>Du hast Fragen? Melde dich bei uns: darthelfer(a)nl-soft.com</div>
                </ul>
            </div>
        </div>
    }
}

export default WasKannDarthelfer;
