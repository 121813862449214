import React from "react";
import {Button} from "primereact/button";
import PropTypes from "prop-types";

class PlusMinusComponent extends React.Component {

    render() {
        return <div>
            {this.props.beschreibung &&
                <span style={{marginRight:10}}>{this.props.beschreibung}</span>}

            <Button icon="pi pi-minus"
                    onClick={() => this.props.callBackMinus()}
                    style={{height: "40px", width: "40px", marginRight: "10px"}}
                    disabled={this.props.value === 0 || this.props.readonly}/>
            {this.props.value + ""}
            <Button icon="pi pi-plus"
                    disabled={this.props.readonly}
                    onClick={() => this.props.callBackPlus()}
                    style={{height: "40px", width: "40px", marginLeft: "10px", marginRight: "10px"}}/>
        </div>
    }
}


PlusMinusComponent.propTypes = {
    beschreibung: PropTypes.string,
    value: PropTypes.number.isRequired,
    callBackMinus: PropTypes.func.isRequired,
    callBackPlus: PropTypes.func.isRequired,
    readonly: PropTypes.bool.isRequired,
};

export default PlusMinusComponent;
