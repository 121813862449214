import {Dialog} from "primereact/dialog";
import React from "react";
import LoginContainer from "../../../../containers/LoginContainer";
import {Button} from "primereact/button";


export default function LoginDialog({onClose}) {
    const footer = (
        <div>
            <Button label="Abbrechen" icon="pi pi-times" onClick={() => onClose()} className="p-button-secondary" />
        </div>
    );

    return <Dialog baseZIndex={2000} header="Login" visible={true} footer={footer}
                   style={{width: 400, height:450}} onHide={() => onClose()}>
        <LoginContainer/>
        <div style={{marginTop:10}}>Um Onlineligen oder Onlineturniere anschauen zu können oder sofern du dich bei einer Liga bzw. Turnier anmelden möchtest, musst du angemeldet sein.</div>
        <div style={{marginTop:10}}>Sofern du noch keinen Zugang zu darthelfer hast, kannst du dir gerne einen kostenlosen Zugang anlegen.</div>
    </Dialog>
}
