import React, {useEffect, useState} from 'react'
import {Button} from "primereact/button";
import {Password} from "primereact/password";
import FetchUtil from "../../../util/FetchUtil";
import ConfigUtil from "../../../util/ConfigUtil";
import ConfirmDialog from "../../general/ConfirmDialog";
import {InputText} from "primereact/inputtext";
import ErrorInfoComponent from "../../general/ErrorInfoComponent";
import {PrimeIcons} from "primereact/api";
import ConfirmWithQuestionDialog from "../../general/ConfirmWithQuestionDialog";

export default function KennwortAendernArea({onLogout}) {

    const [infoMessage, setInfoMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);

    const [externPersonMatch, setExternPersonMatch] = useState(undefined);

    const [id, setId] = useState("");
    const [currentPassword, setCurrentPassword] = useState("");
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [password2, setPassword2] = useState("");
    const [showLoeschenDialog, setShowLoeschenDialog] = useState(false);
    const [showExternPersonConfirmDialog, setShowExternPersonConfirmDialog] = useState(false);
    const [showExternPersonDeleteDialog, setShowExternPersonDeleteDialog] = useState(false);

    useEffect(() => {
        ladeDaten();
    }, []);

    const ladeDaten = () => {
        setErrorMessage(undefined);
        FetchUtil.fetchGet(ConfigUtil.getConfig().resourceUrls.nu + "/player/match",
            json => {
                setExternPersonMatch(json);
            },
            responseNotOk => {
                setErrorMessage("Fehler beim Laden: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler beim Laden: " + error.message);
            }
        );
    }

    const deleteVerknuepfung = () => {
        FetchUtil.fetchDelete(ConfigUtil.getConfig().resourceUrls.nu + "/player/match/delete/" + externPersonMatch.id,
            {},
            () => {
                setInfoMessage("Verknüpfung wurde gelöscht.");
                setShowExternPersonDeleteDialog(false);
                ladeDaten()
            },
            responseNotOk => {
                setErrorMessage("Fehler: " + responseNotOk.message);
                setShowExternPersonDeleteDialog(false);
            },
            error => {
                setErrorMessage("Fehler: " + error.message);
                setShowExternPersonDeleteDialog(false);
            }
        );
    }
    const confirmVerknuepfung = () => {
        setErrorMessage(undefined);

        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.nu + "/player/match/confirm/" + externPersonMatch.id,
            {},
            json => {
                setInfoMessage("Die Verknüpfung wurde bestätigt.");
                ladeDaten();
                setShowExternPersonConfirmDialog(false);
            },
            responseNotOk => {
                setErrorMessage("Fehler: " + responseNotOk.message);
                setShowExternPersonConfirmDialog(false);
            },
            error => {
                setErrorMessage("Fehler: " + error.message);
                setShowExternPersonConfirmDialog(false);
            }
        );
    }

    const changeUsername = () => {
        setErrorMessage(undefined);

        if (username === "" || currentPassword === "") {
            setErrorMessage("Benutzername u. Kennwort müssen gefüllt sein.");
            return;
        }
        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.user + "/changeusername",
            {username: username, password: currentPassword},
            json => {
                onLogout();
            },
            responseNotOk => {
                setErrorMessage("Fehler: " + responseNotOk.message);
                setShowLoeschenDialog(false);
            },
            error => {
                setErrorMessage("Fehler: " + error.message);
                setShowLoeschenDialog(false);
            }
        );
    }

    const loeschen = () => {
        setErrorMessage(undefined);

        if (currentPassword === "") {
            setErrorMessage("Du musst dein aktuelles Kennwort eingeben.");
            setShowLoeschenDialog(false);
            return;
        }
        FetchUtil.fetchDelete(ConfigUtil.getConfig().resourceUrls.user,
            {currentPassword: currentPassword},
            json => {
                onLogout();
            },
            responseNotOk => {
                setErrorMessage("Fehler: " + responseNotOk.message);
                setShowLoeschenDialog(false);
            },
            error => {
                setErrorMessage("Fehler: " + error.message);
                setShowLoeschenDialog(false);
            }
        );
    }

    const save = () => {
        setErrorMessage(undefined);
        if (password !== password2) {
            setErrorMessage("Die beiden neuen Passwörter stimmen nicht überein.");
            return;
        }
        let data = {currentPassword: currentPassword, newPassword: password};
        FetchUtil.fetchPut(ConfigUtil.getConfig().resourceUrls.user,
            data,
            json => {
                setInfoMessage("Speichern der neuen Zugangsdaten war erfolgreich!");
                setCurrentPassword("");
                setPassword("");
                setPassword2("");
            },
            responseNotOk => {
                setErrorMessage("Fehler: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler: " + error.message);
            }
        );
    }

    return <div className="ligashortoverview"
                style={{height: "100%", borderLeft: "10px solid green", textAlign: "left"}}>
        <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage} onClearInfoMessage={() => setInfoMessage(undefined)} onClearErrorMessage={() => setErrorMessage(undefined)}/>

        <h3>Kennwort ändern</h3>
        <div className="grid" style={{marginTop: "20px"}}>
            <div className="col-fixed" style={{width: '180px', textAlign: "right"}}>Aktuelles Passwort:</div>
            <div className="col"><Password name="currentPassword" value={currentPassword}
                                           onChange={(e) => setCurrentPassword(e.target.value)} feedback={false}/></div>
        </div>
        <div className="grid">
            <div className="col-fixed" style={{width: '180px', textAlign: "right"}}>Kennwort:</div>
            <div className="col"><Password name="password" value={password}
                                           onChange={(e) => setPassword(e.target.value)}/></div>
        </div>
        <div className="grid">
            <div className="col-fixed" style={{width: '180px', textAlign: "right"}}>Kennwort bestätigen:</div>
            <div className="col"><Password name="password2" value={password2}
                                           onChange={(e) => setPassword2(e.target.value)} feedback={false}/></div>
        </div>
        <div className="grid">
            <div className="col-fixed" style={{width: '180px', textAlign: "right"}}></div>
            <div className="col"><Button label="Speichern" onClick={() => save()}/></div>
        </div>
        <h3>Benutzernamen ändern</h3>
        <div className="grid">
            <div className="col" style={{fontSize: 12}}>Nach einer Änderung deines Benutzernamens wirst du
                automatisch abgemeldet.
            </div>
        </div>
        <div className="grid" style={{marginTop: "20px"}}>
            <div className="col-fixed" style={{width: '180px', textAlign: "right"}}>Neuer Benutzername:</div>
            <div className="col"><InputText style={{width: 200}} value={username}
                                            onChange={(e) => setUsername(e.target.value)}/>
            </div>
        </div>
        <div className="grid">
            <div className="col-fixed" style={{width: '180px', textAlign: "right"}}>Aktuelles Passwort:</div>
            <div className="col"><Password name="currentPassword" value={currentPassword}
                                           onChange={(e) => setCurrentPassword(e.target.value)} feedback={false}/></div>
        </div>
        <div className="grid">
            <div className="col"><Button label="Benutzernamen ändern" onClick={() => changeUsername()}/></div>
        </div>
        <h3>Account löschen</h3>
        <div className="grid">
            <div className="col" style={{fontSize: 12}}>Sobald du deinen Account löscht, werden alle persönlichen
                Daten von dir gelöscht.
            </div>
        </div>
        <div className="grid">
            <div className="col" style={{fontSize: 12}}>Diese Aktion kann nicht rückgängig gemacht werde.</div>
        </div>
        <div className="grid" style={{marginTop: "20px"}}>
            <div className="col-fixed" style={{width: '180px', textAlign: "right"}}>Aktuelles Passwort:</div>
            <div className="col"><Password name="currentPassword" value={currentPassword}
                                           onChange={(e) => setCurrentPassword(e.target.value)} feedback={false}/></div>
        </div>
        <div className="grid">
            <div className="col"><Button label="Account löschen"
                                         onClick={() => setShowLoeschenDialog(true)}/></div>
        </div>
        {externPersonMatch && <>
            <div className="grid" style={{fontWeight: "bold", marginTop: 20}}>
                <div className="col">Verknüpfung mit externen Personen (z.B. aus dem nu-System)
                </div>
            </div>
            <div style={{marginBottom: 5}}>
                <div className="grid" style={{marginTop: 20}}>
                    <div className="col-fixed" style={{width: externPersonMatch.confirmed ? 50 : 100}}>
                        {!externPersonMatch.confirmed &&
                            <Button icon={PrimeIcons.CHECK} onClick={() => setShowExternPersonConfirmDialog(true)} style={{marginRight:5}}/>
                        }
                        <Button icon={PrimeIcons.TRASH} onClick={() => setShowExternPersonDeleteDialog(true)} style={{marginRight:5}}/>
                    </div>
                    <div className="col">
                        <img src={externPersonMatch.confirmed ? "/images/dot_green.png" : "/images/dot_red.png"} style={{width: 15, marginRight: 5}}/>
                        {externPersonMatch.externPersonName}<br/>(PersondId: {externPersonMatch.externPersonId}/DH-SpielerId:{externPersonMatch.idShort})
                    </div>
                    <div style={{fontSize:11, marginTop:10}}>
                        <strong>Wichtig!!!</strong> Die Verknüpfung zu einer externen Person hat den Hintergrund, dass die Spiele/Statistiken von dieser externen Person
                        in Darthelfer bei dir angezeigt werden.<br/>
                        Die Spiele werden erst verknüpft, sobald die nächsten Spiele für diese externe Person angelegt werden. Rückwirkend wird diese Verknüpfung nicht aufgebaut.
                    </div>
                </div>
            </div>
        </>
        }

        {showExternPersonConfirmDialog &&
            <ConfirmDialog visible={true}
                           callBackOnNein={() => setShowExternPersonConfirmDialog(false)}
                           callBackOnJa={() => confirmVerknuepfung()}
                           header="Verknüpfung bestätigen"
                           textOben="Es besteht eine unbestätigte Verknüpfung zu einer externen Person."
                           textUnten="Möchtest du die Verknüpfung bestätigen?"/>
        }
        {showExternPersonDeleteDialog &&
            <ConfirmWithQuestionDialog visible={true}
                           callBackOnNein={() => setShowExternPersonDeleteDialog(false)}
                           callBackOnJa={() => deleteVerknuepfung()}
                           header="Verknüpfung löschen"
                           textOben=""
                           textUnten="Möchtest du die Verknüpfung wirklich löschen?"/>
        }
        <ConfirmDialog visible={showLoeschenDialog}
                       callBackOnNein={() => setShowLoeschenDialog(false)}
                       callBackOnJa={() => loeschen()}
                       header="Account löschen"
                       textOben="Das Löschen deines Accounts kann nicht rückgängig gemacht werden, bitte überlege noch mal..."
                       textUnten="Möchtest du deinen Account wirklich löschen?"/>
    </div>
}
