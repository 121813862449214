import React, {useState} from 'react'
import {Dialog} from "primereact/dialog";
import PropTypes from "prop-types";
import {Button} from "primereact/button";
import FetchUtil from "../../../util/FetchUtil";
import ConfigUtil from "../../../util/ConfigUtil";
import ErrorInfoComponent from "../../general/ErrorInfoComponent";

function LigaSpielbareSpieltageDialog({ligaId, spielbareSpieltage, anzahlSpieltage, onClose}) {

    const [infoMessage, setInfoMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);

    let tmpSpieltage = [];
    for (const tmp of spielbareSpieltage) {
        tmpSpieltage.push(tmp.spieltag);
    }
    const [selectedSpieltage, setSelectedSpieltage] = useState(tmpSpieltage);

    const save = () => {
        setErrorMessage(undefined);
        let tmp = [];
        for (const spieltag of selectedSpieltage) {
            tmp.push({spieltag: spieltag});
        }
        let url = ConfigUtil.getConfig().resourceUrls.tournament + "/saveAktiveSpieltage/" + ligaId;
        FetchUtil.fetchPost(url,
            tmp,
            () => {
                setInfoMessage("Es wurde erfolgreich gespeichert.");
            },
            responseNotOk => {
                setErrorMessage("Fehler beim Speichern: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler beim Speichern: " + error.message);
            })
    }

    const changeSelectedSpieltag = (spieltag) => {
          if( selectedSpieltage.find(tmpSpieltag => tmpSpieltag === spieltag) !== undefined ) {
              // vorhanden, entfernen
              let tmp = [];
              tmp = selectedSpieltage.filter(tmpSpieltag => tmpSpieltag !== spieltag);
              setSelectedSpieltage(tmp);
          }  else {
              let tmp = [];
              for (const tmpSpieltag of selectedSpieltage) {
                   tmp.push(tmpSpieltag);
              }
              tmp.push(spieltag);
              setSelectedSpieltage(tmp);
        }
    }

    const getButtons = () => {
        let buttons = [];
        for(let i = 1; i <= anzahlSpieltage; i++) {
            buttons.push(<Button key={"key_button_" + i} label={i + ""}
                                 onClick={() => changeSelectedSpieltag(i)}
                                 style={{margin:5, width:50, height:50, backgroundColor: selectedSpieltage.find(tmpSpieltag => tmpSpieltag === i) !== undefined ? "green" : ""}} />
            );
        }
        return buttons;
    }

    return <Dialog baseZIndex={2000} header="Spielbare Spieltage verwalten" visible={true}
                                       style={{height: 500, width: 400}}
                                       onHide={() => onClose()}>
        <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage} onClearInfoMessage={() => setInfoMessage(undefined)} onClearErrorMessage={() => setErrorMessage(undefined)} />
        <div style={{textAlign:"left"}}>
            <div style={{marginBottom:20, fontWeight:"bold", color:"red"}}>ACHTUNG!!!</div>
            <div style={{marginBottom:10}}>Sobald kein Spieltag ausgewählt wurde, sind alle Spieltage in der Liga spielbar.</div>
            <div style={{marginBottom:10}}>Sofern du einschränken möchtest welche Spieltage gespielt werden können, wähle bitte die Spieltage aus die gerade bespielbar sind.</div>
            <div style={{marginBottom:10, color:"green"}}>Grün markierte Spieltage sind ausgewählt.</div>
            <div style={{marginTop:20}}>
                {getButtons()}
            </div>
            <div style={{marginTop:20}}>
                <Button label="Speichern"
                        type="button" onClick={() => save()} style={{marginRight:5}}/>
                <Button label="Schliessen"
                        type="button" onClick={() => onClose()}/>

            </div>
        </div>
    </Dialog>
}

LigaSpielbareSpieltageDialog.propTypes = {
    ligaId : PropTypes.string.isRequired,
    spielbareSpieltage : PropTypes.array.isRequired,
    anzahlSpieltage : PropTypes.number.isRequired,
    onClose : PropTypes.func.isRequired,
}

export default LigaSpielbareSpieltageDialog;
