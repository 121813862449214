import React from "react";
import {Button} from "primereact/button";
import PropTypes from "prop-types";
import {Dialog} from "primereact/dialog";

import PlayerUtil from "../../util/PlayerUtil";
import ConfigUtil from "../../util/ConfigUtil";
import FetchUtil from "../../util/FetchUtil";
import {InputSwitch} from "primereact/inputswitch";

class SelectPlayerComponent extends React.Component {

    constructor(props) {
        super(props);

        this.state = {showOnlyPlayersFromSession:false, showDialog:false};
        this.uebermittleGewaehltenSpieler = this.uebermittleGewaehltenSpieler.bind(this);
        this.ladeDaten = this.ladeDaten.bind(this);
    }

    componentDidMount() {
        this.ladeDaten(false);
    }

    switchShowOnlyPlayersFromSession() {
        this.setState({showOnlyPlayersFromSession: !this.state.showOnlyPlayersFromSession});
        this.ladeDaten(!this.state.showOnlyPlayersFromSession);
    }

    ladeDaten(showOnlyPlayersFromSession) {
        let url;
        if( this.props.publicKey !== undefined) {
            if( showOnlyPlayersFromSession) {
                url = ConfigUtil.getConfig().resourceUrls.public + "/allactiveusersfilteredbytrainingssession/" + this.props.publicKey + "/" + this.props.trainingssession.id;
            } else {
                url = ConfigUtil.getConfig().resourceUrls.public + "/allactiveusers/" + this.props.publicKey;
            }
        } else {
            if( showOnlyPlayersFromSession) {
                url = ConfigUtil.getConfig().resourceUrls.player + "/allactivefilteredbytrainingssession/" + this.props.trainingssession.id;

            } else {
                url = ConfigUtil.getConfig().resourceUrls.player + "/allactive";
            }
        }

        FetchUtil.fetchGet(url,
            json => {
                this.setState({...this.state, spieler: json});
            },
            responseNotOk => {
                this.props.callBackOnErrorFunc("Fehler beim Laden der Spieler: " + responseNotOk.message)
            },
            error => {
                this.props.callBackOnErrorFunc("Fehler beim Laden der Spieler: " + error.message)
            })
    }

    render() {
        if( this.state.spieler === undefined || this.state.spieler === null ) {
            return <div/>
        }
        let playerButtons = this.getPlayerButtons(this.props.callBackSelectFunc);

        return <div>
            {this.props.player === undefined || this.props.player === null ?
                <div >
                    <Button type="button" label={this.props.selectButtonLabel}
                            onClick={(e) => this.setState({showDialog:true})}/>
                    <Dialog baseZIndex={2000} header="Spieler auswählen" visible={this.state.showDialog}
                                  style={{height: "100%", width: "100%"}}
                            onHide={() => this.setState({showDialog:false})}>
                        {this.props.trainingssession !== undefined ?
                            <div style={{marginBottom:"20px"}}>
                                zeige nur Spieler die bereits gespielt haben: <InputSwitch checked={this.state.showOnlyPlayersFromSession}
                                             onChange={(e) => this.switchShowOnlyPlayersFromSession()}/>

                            </div>
                            :
                            <div/>
                        }
                        <div>
                            {playerButtons}
                            <div>
                                <Button label="Auswahl abbrechen"
                                        className="p-button-danger"
                                        onClick={(e) => this.setState({showDialog:false})}
                                        style={{height: "50px"}}/>
                            </div>
                        </div>
                    </Dialog>
                </div>
                :
                <div>
                    <Button icon="pi pi-times" onClick={(e) => this.props.callBackDeleteFunc()} style={{marginRight:"10px"}}/>
                    {PlayerUtil.anzeigeNameZumSpieler(this.props.player)}
                </div>
            }
        </div>
    }

    getPlayerButtons(callBackFunc) {
        let buttons = [];
        for (let i = 0; i < this.state.spieler.length; i++) {
            let einzelnerSpieler = this.state.spieler[i];
            let label = PlayerUtil.anzeigeNameZumSpieler(einzelnerSpieler);
            buttons.push(<Button label={label}
                                 key={einzelnerSpieler.id}
                                 onClick={(e) => this.uebermittleGewaehltenSpieler(einzelnerSpieler)}
                                 className="p-button-secondary"
                                 style={{height: "40px", marginRight: "10px", marginBottom: "10px"}}/>);
        }
        return buttons;
    }

    uebermittleGewaehltenSpieler(spieler) {
        this.props.callBackSelectFunc(spieler);
        this.setState({showDialog:false});
    }
}


SelectPlayerComponent.propTypes = {
    player: PropTypes.object,
    trainingssession: PropTypes.object,
    selectButtonLabel: PropTypes.string.isRequired,
    callBackSelectFunc: PropTypes.func.isRequired,
    callBackDeleteFunc: PropTypes.func.isRequired,
    callBackOnErrorFunc: PropTypes.func.isRequired,
    publicKey: PropTypes.string,
};

export default SelectPlayerComponent;
